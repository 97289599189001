import { useEffect, useState, useContext } from 'react';
import { toast } from 'react-hot-toast';
import { humanizeAddress, setAuthToken } from '../helpers';
import { GlobalContext } from '../context';
import { config } from '../config';

export const useAuth = () => {
  localStorage.getItem('token') && setAuthToken(localStorage.getItem('token')!);

  const { setIsLoading, setUserName, setAddress } = useContext(GlobalContext);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const token = localStorage.getItem('token');
    token && loadUser(token);

    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const register = async (nickname: string, address: string) => {
    setIsLoading(true);

    try {
      let { data } = await config.axios({
        url: `/api/users/register`,
        method: 'POST',
        data: JSON.stringify({
          nickname,
          address,
        }),
      });
      let { token, success, msg } = data;
      if (success && token) {
        localStorage.setItem('token', token);
        setAuthToken(token);
        await loadUser(token);
      } else {
        toast.error(String(msg));
      }
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
  };

  const joinWaitList = async (email: string) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          setIsLoading(true);
          const { data } = await config.axios({
            method: 'POST',
            url: `/api/users/joinWaitList`,
            data: JSON.stringify({ email }),
          });
          let { success, msg } = data;
          if (success) {
            resolve('You have been added to the wait list');
          } else {
            reject(msg);
          }
          setIsLoading(false);
        } catch (error: any) {
          reject(
            error?.data?.message ||
              error.response.data.msg ||
              error?.message ||
              'Fail to join wait list'
          );
          setIsLoading(false);
        }
      }),
      {
        loading: 'Joining...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const login = async (address: string) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const { data } = await config.axios({
            method: 'post',
            url: `/api/auth/login`,
            data: JSON.stringify({ address }),
          });

          const { token, success, msg } = data;
          if (success && token) {
            localStorage.setItem('token', token);
            setAuthToken(token);
            await loadUser(token);
            resolve('Log in successful!');
          } else {
            reject(msg.msg);
          }
          setIsLoading(false);
        } catch (error: any) {
          reject(error);
          setIsLoading(false);
        }
      }),
      {
        loading: 'Logging in...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };
  const getWaitlist = async () => {
    setIsLoading(true);
    try {
      const { data } = await config.axios({
        method: 'get',
        url: `/api/users/waitList`,
        headers: {
          Authorization: `x-auth-token ${localStorage.getItem('token')}`,
        },
      });
      const { success, msg, data: waitList } = data;
      setIsLoading(false);
      if (success) {
        return waitList;
      } else {
        toast.error(msg.msg);
      }
      return [];
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.data?.message || error?.message);
    }
  };

  const loadUser = async (token: string) => {
    try {
      const { data } = await config.axios({
        method: 'get',
        url: `/api/auth`,
        headers: {
          Authorization: `x-auth-token ${token}`,
        },
      });

      const { success, msg, user } = data;
      if (success && user) {
        setIsLoggedIn(true);
        setUserName(humanizeAddress(user.address));
        setAddress(user.address);
        setIsLoading(false);
      } else {
        toast.error(String(msg));
      }
    } catch (error: any) {
      localStorage.removeItem('token');
      toast.error(
        `Error: ${
          error.response.data.error || error?.data?.message || error?.message
        }`
      );
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUserName(null);
    setAddress(null);
  };

  return {
    isLoggedIn,
    register,
    login,
    logout,
    loadUser,
    joinWaitList,
    getWaitlist,
  };
};
