import styled from 'styled-components';

export default function GameGuide() {
  return <StyledGuide>Game Guide</StyledGuide>;
}

const StyledGuide = styled.div`
  padding: 2rem;
  text-align: center;
`;
