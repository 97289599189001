import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Benefits from '../components/Home/Benefits';
import Hero from '../components/Home/Hero';
import Board from '../components/Home/Board';
import { ContractContext } from '../context';
import Animation from '../components/Home/Animation';
import Info from '../components/Home/Info';
import Faqs from '../components/Home/Faqs';
import Navigation from '../components/Home/Navigationt';
import Roadmap from '../components/Home/Roadmap';
import Team from '../components/Home/Team';
import wholeBg from '../assets/wholeBg.png';
import Footer from '../components/FooterN';

function Home() {
  const { fetchNFTSWithMetadata, setMetadatas } = useContext(ContractContext);
  useEffect(() => {
    fetchNFTSWithMetadata()
      .then((_metadatas) => {
        setMetadatas(_metadatas);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <Helmet>
        <title>Capped Range</title>
      </Helmet>
      <StyledHome>
        <Hero />
        <Info />
        <Board />
        <Animation />
        <Benefits />
        <Roadmap />
        <Team />
        <Faqs />
        <Navigation />
        <Footer />
      </StyledHome>
    </>
  );
}

const StyledHome = styled.main`
  background-image: url(${wholeBg});
  background-repeat: repeat;
  background-size: 100%;
  background-position: center top;
  position: absolute;
  width: 100%;
  z-index: -8;

  @media (max-width: 576px) {
    background-size: 1360px;
  }
`;

export default Home;
