import styled from 'styled-components';
import pieChart from '../../assets/teamInfo/pie.png';
import payout from '../../assets/teamInfo/payout.png';

export default function Team() {
  return (
    <StyledTeam>
      <div className="mintContainer">
        <section className="mintBonus">
          <h2>
            Mint <span>Expectations</span>
          </h2>
          <p className="mintPara">
            Capped Range holders can expect the following at mint:
          </p>
          <div className="mintBonusFlex">
            <div className="tagWrap">
              <p className="place">8,192 Mints</p>
              <div className="item-1">
                <p>
                  8,000 Mints allocated towards Whitelist and General Public
                </p>
                <p>
                  192 Mints will be held by the team to be distributed for
                  partnerships and future giveaways
                </p>
              </div>
            </div>
            <div className="tagWrap">
              <p className="place">Funds raised</p>
              <div className="item-2">
                <p>8,000 Mints X .05E = 400E</p>
                <p>200E Allocated towards Weekly Tournaments</p>
              </div>
            </div>
            <div className="tagWrap">
              <p className="place">Player Pool - 200E</p>
              <div className="item-3">
                <p>
                  Half of all funds allocated towards player pool and spread out
                  across 52 weekly
                </p>
                <p>Tournaments with each containing a ~3.8E Prizepool</p>
              </div>
            </div>
            <div className="tagWrap">
              <p className="place">Capped Range - 200E</p>
              <div className="item-4">
                <p>
                  Remaining half of raised funds allocated towards Capped range
                </p>
                <p>distribution of funds listed below</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="fundsInfo">
        <h1>
          Capped Range <span>Funds Distribution</span>
        </h1>
        <div className="info">
          <img className="pieChart" src={pieChart} alt="" />
          <img className="payout" src={payout} alt="" />
        </div>
      </div>
    </StyledTeam>
  );
}

const StyledTeam = styled.div`
  @font-face {
    font-family: 'Northead';
    src: url('/fonts/northead.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.otf') format('truetype');
  }

  padding: 6rem 0;
  margin-bottom: 8rem;
  .fundsInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      font-family: 'Northead' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 5rem;
      line-height: 61px;
      text-align: center;
      letter-spacing: -0.02em;

      color: #ffffff;
      span {
        font-family: 'Northead' !important;
        color: #ffc728;
      }
      @media (max-width: 768px) {
        font-size: 4.5rem;
        width: 100%;
        height: 100%;
      }
    }
    .info {
      margin-top: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      width: 100%;
      .pieChart {
        width: 35%;
      }
      .payout {
        width: 20%;
      }
      @media (max-width: 1024px) {
        .pieChart {
          width: 50%;
        }
        .payout {
          width: 30%;
        }
        flex-direction: column;
      }
      @media (max-width: 576px) {
        .pieChart {
          width: 90%;
        }
        .payout {
          width: 80%;
        }
      }
    }
  }
  .container {
    .heading {
      margin-top: 150px;
      h2 {
        font-family: 'Northead' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 88.7888px;
        line-height: 61px;
        letter-spacing: -0.02em;
        text-align: center;
        margin-bottom: 1.5rem;
      }
      p {
        font-family: 'Inter' !important;
        margin: 0 auto;
        width: 689px;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .cardBody {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .member {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 375px;
      height: 627px;
      background-color: rgba(51, 51, 51, 0.2);
      background-blend-mode: overlay, normal;
      border: 2px solid #383838;
      backdrop-filter: blur(33.193px);
      border-radius: 3px;
      padding: 1rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(33.193px);

      img {
        width: 100%;
        height: 225px;
        border-radius: 1rem;
      }
      h3 {
        margin: 1rem 0 1rem;
        font-family: 'Northead' !important;
        font-weight: 400;
        font-size: 51.26px;
        line-height: 42px;
        text-transform: uppercase;
        color: #ffc728;
      }
      p {
        font-family: 'Inter' !important;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 22px;
      }
      a {
        font-family: 'Inter' !important;
        display: block;
        text-decoration: underline;
        margin-top: 0.5rem;
        color: ${({ theme }) => theme.primary};
      }
      @media (max-width: 473px) {
        width: 100%;
      }
    }

    .membersGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 2rem;
      place-items: center;
      grid-gap: 1rem;
      .item-2 {
        margin-left: -4rem;
      }
      .item-3 {
        margin-left: -8rem;
      }
      .item-5 {
        margin-left: -4rem;
      }
      .item-6 {
        margin-left: -8rem;
      }
      @media (max-width: 1268px) {
        grid-gap: 3rem;
        .item-2 {
          margin-left: -2rem;
        }
        .item-3 {
          margin-left: -2rem;
        }
        .item-5 {
          margin-left: -2rem;
        }
        .item-6 {
          margin-left: -2rem;
        }
      }
      @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        .item-2 {
          margin-left: 0;
        }
        .item-3 {
          margin-left: 0;
        }
        .item-5 {
          margin-left: 0;
        }
        .item-6 {
          margin-left: 0;
        }
      }
      @media (max-width: 578px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  .mintContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .mintBonus {
      padding: 8rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      width: 70%;
      @media (max-width: 992px) {
        width: 100%;
        height: 100%;
      }

      h2 {
        font-family: 'Northead' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 5rem;
        line-height: 61px;
        letter-spacing: -0.02em;
        color: #ffffff;
        text-align: center;
        @media (max-width: 992px) {
          width: 100%;
          height: 100%;
        }
        span {
          color: #ffc728;
          font-family: 'Northead' !important;
        }
      }
      .mintPara {
        font-family: 'Inter' !important;
        width: 689px;
        margin-bottom: 3.5rem;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        @media (max-width: 768px) {
          width: 100%;
          height: 100%;
          padding: 0 2rem;
          margin-bottom: 2rem;
        }
      }
      .mintBonusFlex {
        margin-bottom: 10rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        width: 100%;

        .item-1,
        .item-2,
        .item-3,
        .item-4 {
          z-index: -1;
          position: relative;
          width: 762px;
          background-color: rgba(51, 51, 51, 0.2);
          background-blend-mode: overlay, normal;
          border: 2px solid #383838;
          backdrop-filter: blur(33.193px);
          border-radius: 3px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          filter: drop-shadow(0px 1.22647px 4.29266px rgba(0, 0, 0, 0.75));

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 1rem;
          @media (max-width: 992px) {
            width: 100%;
            height: 100%;
          }
          @media (max-width: 576px) {
            width: 90%;
          }
          p {
            text-align: center;
            width: 718px;
            font-family: 'Northead' !important;
            font-size: 29px;
            line-height: 38px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            @media (max-width: 992px) {
              width: 100%;
            }
            @media (max-width: 576px) {
              font-family: 'Inter' !important;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.02em;
              text-align: center;
            }
          }
        }
        .tagWrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .place {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            margin: 0 auto;
            margin-bottom: -1rem;
            width: 296px;
            height: 36px;
            background: #ffc728;
            border: 3px solid #ffffff;
            box-sizing: border-box;
            font-family: 'Northead' !important;
            font-size: 32px;
            line-height: 36px;
            text-align: center;
            @media (max-width: 576px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
`;
