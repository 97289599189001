import { Routes } from './components';
import { MainLayout } from './layouts';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <MainLayout>
      <Routes />
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            overflow: 'hidden',
            maxWidth: '100%',
            width: 'fit-content',
          },
        }}
      />
    </MainLayout>
  );
}

export default App;
