import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import arrowLeft from '../assets/arrows/vector-l.png';
import arrowRight from '../assets/arrows/vector-r.png';
import { config } from '../config';
import { humanizeAddress } from '../helpers';
import { IResult } from '../types';
import Loader from './Loader';

interface DropTableProps {
  batch: string;
  results: IResult[];
  curPage: number;
  isLoading: boolean;
  paginate: number;
  setCurPage: Dispatch<SetStateAction<number>>;
  maxPage: number;
  winnersEnabled: boolean;
}

export function DropTable({
  batch,
  results,
  curPage,
  isLoading,
  paginate,
  setCurPage,
  maxPage,
  winnersEnabled,
}: DropTableProps) {
  return (
    <StyledDropTable>
      <div className="players-table">
        <h2>
          Showing {results.length} {winnersEnabled ? 'winners' : 'losers'} for
          rank: {batch}, Round:{' '}
          {Math.log(Number(batch) / config.MAX_PLAYERS) / Math.log(0.5) + 1}
        </h2>
        <table>
          <thead>
            <tr>
              <th className="body-large">Rank</th>
              <th className="body-large">NFT</th>
              <th className="body-large">Token ID</th>
              <th className="body-large">Wallet Address</th>
              <th className="body-large">Linked Tier</th>
              <th className="body-large">Round</th>
              <th className="body-large">Won/Lost</th>
              <th className="body-large">Rewarded</th>
            </tr>
          </thead>
          <tbody>
            {results.length > 0 &&
              results.map((result, i: number) => {
                if (curPage === result.page) {
                  return (
                    <tr key={i + 1}>
                      <td className="body-large">
                        {config.MAX_PLAYERS * Math.pow(0.5, result.roundId - 1)}
                      </td>
                      <td className="body-large">
                        {['.gif', '.mp4'].some((ext) =>
                          result.metadata?.image?.endsWith(ext)
                        ) ? (
                          <video
                            src={result.metadata?.image?.replaceAll(
                              'https://gateway.pinata.cloud/',
                              'https://ipfs.thirdweb.com/'
                            )}
                            style={{
                              borderRadius: '50%',
                              height: '4.5rem',
                              width: '100%',
                            }}
                          />
                        ) : (
                          <img
                            src={
                              result.metadata?.image
                              //  ||
                              // require(`../assets/nfts/nft${
                              //   (result.tokenId % 2) + 1
                              // }.png`)
                            }
                            alt={result.metadata?.name}
                            height="2.5rem"
                            width="2.5rem"
                            style={{
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </td>
                      <td className="body-large">{result.tokenId}</td>
                      <td className="body-large">
                        {humanizeAddress(result.player || '')}
                      </td>
                      <td className="body-large">{result.metadata?.name}</td>
                      <td className="body-large">{result.roundId}</td>
                      <td className="body-large">
                        {result.nftWon ? 'Won' : 'Lost'}
                      </td>
                      <td className="body-large">
                        {result.rewarded ? 'Yes' : 'No'}
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
          </tbody>
        </table>
        {isLoading ? (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Loader />
          </div>
        ) : (
          results.length === 0 && (
            <p
              style={{
                textAlign: 'center',
                margin: '1rem',
              }}
            >
              No results Found.
            </p>
          )
        )}
      </div>
      <div className="pagination">
        <p className="all-pages">
          {Math.min(results.length, (curPage - 1) * paginate + 1)} -{' '}
          {Math.min(curPage * paginate, results.length)} of {results.length}
        </p>

        <p
          className="decrement"
          onClick={() => setCurPage((prev: number) => Math.max(1, prev - 1))}
        >
          <img src={arrowLeft} alt="left-arrow" />
        </p>

        {curPage <= 3 ? null : <p onClick={() => setCurPage(1)}>1</p>}
        {curPage <= 4 ? null : <p className="dots">...</p>}

        {curPage <= 2 ? null : (
          <p
            onClick={() => setCurPage((prev: number) => Math.max(1, prev - 2))}
          >
            {' '}
            {curPage - 2}
          </p>
        )}
        {curPage === 1 ? null : (
          <p
            onClick={() => setCurPage((prev: number) => Math.max(1, prev - 1))}
          >
            {' '}
            {curPage - 1}
          </p>
        )}

        {
          <p style={{ background: '#4C4F58' }} onClick={() => {}}>
            {' '}
            {curPage}
          </p>
        }

        {curPage >= maxPage ? null : (
          <p
            onClick={() =>
              setCurPage((prev: number) => Math.min(maxPage, prev + 1))
            }
          >
            {' '}
            {curPage + 1}
          </p>
        )}
        {curPage >= maxPage - 1 ? null : (
          <p
            onClick={() =>
              setCurPage((prev: number) => Math.min(maxPage, prev + 2))
            }
          >
            {' '}
            {curPage + 2}
          </p>
        )}

        {curPage >= maxPage - 3 ? null : <p className="dots">...</p>}
        {curPage >= maxPage - 2 ? null : (
          <p onClick={() => setCurPage(maxPage)}>{maxPage}</p>
        )}

        <p
          className="increment"
          onClick={() =>
            setCurPage((prev: number) => Math.min(maxPage, prev + 1))
          }
        >
          <img src={arrowRight} alt="right-arrow" />
        </p>
      </div>
    </StyledDropTable>
  );
}

const StyledDropTable = styled.div`
  width: calc(100vw - 2rem);
  max-width: calc(1280px - 2rem);
  h2 {
    font-size: 1.2rem;
    margin: 1rem 0 0.5rem;
  }
  .players-table {
    width: 100%;
    height: 90%;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    border: 1px solid #616268;
    border-radius: 0.5rem;
    overflow-x: auto;
    margin-left: -5px;
    padding: 0px 10px;
    background: #25262a;
  }

  .players-table > table {
    padding: 0px;
  }
  td > img {
    width: 3.3rem;
    height: 3.3rem;
  }

  .players-table::-webkit-scrollbar {
    width: 0.4em;
    margin-left: 10px;
  }

  .players-table::-webkit-scrollbar-track {
    box-shadow: inset 0 1 6px #4c4f58;
  }

  .players-table::-webkit-scrollbar-thumb {
    background-color: #4c4f58;
    /* outline: 1px solid slategrey; */
    border-radius: 10px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    min-width: 900px;
  }

  th,
  td {
    text-align: center;
    padding: 8px;
    background: #4c4f58;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    color: #b9babc;
    border-width: 10px 0px;
    border-style: solid;
    border-color: #25262a;
    position: sticky;
    z-index: 2;
    top: 0;
  }

  td {
    color: white;
    z-index: 1;
    border-width: 3px 0px;
    border-style: solid;
    border-color: #25262a;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .pagination > p {
    margin: 20px;
    padding: 6px;
    border-radius: 2px;
    color: white;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .pagination > p:not(.dots, .all-pages):hover {
    background: #363942;
    cursor: pointer;
  }
`;
