import { AuthProvider } from './auth';
import { GlobalProvider } from './global';
import { PokerProvider } from './poker';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ContractProvider } from './contracts';
import { colors } from '../styles/colors';

export const Providers = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <ThemeProvider theme={colors}>
      <BrowserRouter>
        <GlobalProvider>
          <ContractProvider>
            <AuthProvider>
              <PokerProvider>{children}</PokerProvider>
            </AuthProvider>
          </ContractProvider>
        </GlobalProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
