import { Helmet } from 'react-helmet';
import backgroundImg from '../assets/backgrounds/background2.png';
import { PlayersTable } from '../components/playersTable';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ContractContext, GlobalContext, PokerContext } from '../context';
import { IResult, ITournament } from '../types';
import toast from 'react-hot-toast';

function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState<IResult[]>([]);
  const [paginate, setPaginate] = useState(10);
  const [curPage, setCurPage] = useState(1);
  const [currentTournament, setCurrentTournament] =
    useState<ITournament | null>(null);

  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const { getTournaments } = useContext(ContractContext);
  const { getResults } = useContext(PokerContext);

  useEffect(() => {
    const getLeaderboard = async () => {
      setIsLoading(true);
      if (currentTournament) {
        getResults(currentTournament.id)
          .then((results) => {
            setLeaderboard(
              results
                .sort((a, b) => b.roundId - a.roundId || a.tokenId - b.tokenId)
                .map((result, i) => {
                  return {
                    ...result,
                    page: Math.ceil((i + 1) / paginate),
                  };
                })
            );
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err);
            setLeaderboard([]);
          });
      }
    };

    getLeaderboard();

    // fetch leaderboard every 45 seconds
    const interval = setInterval(() => {
      getLeaderboard();
    }, 45000);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTournament]);

  useEffect(() => {
    getTournaments()
      .then((tournaments) => {
        if (tournaments.length > 0) {
          // sort upcoming rounds by start time
          tournaments = tournaments.sort((a, b) => a.id - b.id);

          // get current tournament
          let tournament = tournaments[tournaments.length - 1];
          setCurrentTournament(tournament);
        } else {
          setIsLoading(false);
        }
      })
      .catch((_err) => {
        toast('Error fetching tournaments');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLeaderboard>
      <Helmet>
        <title>Lobby</title>
      </Helmet>
      <div className="container">
        <div className="heading">
          <h1>Game Lobby</h1>
          <p>
            This leaderboard includes the <span>1st Round</span> of the game
            that took place on <span>July 21st, 2022</span>. All the
            participants were given bounties according to the description in the{' '}
            <Link to="/game-guide">Game Guide</Link> page. All players who
            participated are shown regardless of their win/lost status.
          </p>
        </div>
        <PlayersTable
          results={leaderboard}
          curPage={curPage}
          setCurPage={setCurPage}
          paginate={paginate}
          setPaginate={setPaginate}
          isLoading={isLoading}
        />
      </div>
    </StyledLeaderboard>
  );
}

const StyledLeaderboard = styled.div`
  min-height: 100vh;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4rem 0;
  @media (max-width: 768px) {
    padding: 2rem 0;
  }
  .heading {
    margin-bottom: 2rem;
    h1 {
      font-size: 2.5rem;
    }
    p {
      line-height: 1.4;
      font-weight: 300;
      margin: 1rem 0 0;
      a,
      span {
        color: ${({ theme }) => theme.primary};
      }
      a {
        text-decoration: underline;
      }
    }
  }
`;

export default Leaderboard;
