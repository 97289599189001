import { useContext, useEffect, useState } from 'react';

import PlayerSeat from '../components/playerSeat';
import PokerTable from '../components/pokerTable';

import backgroundImg from '../assets/backgrounds/background.png';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { PokerContext } from '../context/poker';
import { useNavigate, useParams } from 'react-router-dom';
import { useCountdown } from '../hooks/useCountdown';
import { GlobalContext } from '../context';

function Game() {
  const { leaveTable, currentTable, replayMatch } = useContext(PokerContext);
  const { address } = useContext(GlobalContext);
  const [table, setTable] = useState(currentTable);

  const { countdown, setCountdown } = useCountdown(10);

  const navigate = useNavigate();

  const { gameId } = useParams();

  const handleReplay = () => {
    setCountdown(10);
  };

  useEffect(() => {
    if (currentTable && address) {
      let players = [currentTable?.player1, currentTable?.player2];

      let player1 = players.find(
        (player) => player?.address.toLowerCase() === address.toLowerCase()
      );

      if (player1) {
        let player2 = players.find(
          (player) => player?.tokenId !== player1?.tokenId
        );
        if (player2) {
          currentTable.player1 = player2;
          currentTable.player2 = player1;
          setTable(currentTable);

          setTable(currentTable);
        }
      }

      setTable(currentTable);
    }
  }, [address, currentTable]);

  useEffect(() => {
    gameId && replayMatch(gameId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  return table ? (
    <ScrollWrapper>
      <StyledGame>
        <Helmet>
          <title>Game</title>
        </Helmet>
        <div className="timer-wrapper" />
        <div className="game-container">
          {countdown < 4 && <div className="result">{table?.comment}</div>}
          <PlayerSeat player={table.player1} />
          <PokerTable currentTable={table} countdown={countdown} />
          <PlayerSeat player={table.player2} />
        </div>
        <div className="buttons">
          <button className="draw-hand" onClick={() => handleReplay()}>
            Replay
          </button>
          <button
            className="next-game"
            onClick={() => {
              leaveTable();
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
      </StyledGame>
    </ScrollWrapper>
  ) : (
    <div>No Data</div>
  );
}

const ScrollWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledGame = styled.div`
  background-image: url(${backgroundImg});
  background-color: '#1A1A1C';
  margin-bottom: 8rem;
  width: 100%;
  min-width: 1024px;
  position: relative;
  .result {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-40%);
    border: 2px solid ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.tertiary};
    padding: 1.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  .game-container {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .timer-wrapper {
    margin-top: 1rem;
    float: right;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .buttons {
    justify-content: center;
    align-items: center;
    /* margin-top: -8rem; */
  }

  .buttons > .draw-hand {
    float: left;
    font-size: 1.5rem;
    margin-left: 15rem;
    background: #ffc728;
    border-radius: 8px;
    padding: 1rem 2rem;
  }

  .buttons > .next-game {
    float: right;
    font-size: 1.5rem;
    margin-right: 15rem;
    color: #ffc728;
    border-radius: 8px;
    border-color: #ffc728;
    padding: 1rem 2rem;
    background: None;
  }
  .buttons > action-button {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #ffc728;
    border-radius: 8px;
    border-color: #ffc728;
    padding: 1rem 2rem;
    background: None;
    &:disabled {
      background: grey;
    }
  }

  button {
    padding: 1rem 1.5rem !important;
    font-size: 1.2rem !important;
    cursor: pointer;
  }
`;

export default Game;
