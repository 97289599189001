import { useEffect, useState } from 'react';

export const useCountdown = (initial: number) => {
  const [countdown, setCountdown] = useState<number>(initial);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  return { countdown, setCountdown };
};
