import { createContext } from 'react';
import { IAwards, ITournament, Round } from '../../types';

type IContractContext = {
  currentRound: Round | null;
  currentTournament: ITournament | null;
  upcomingRounds: Round[];
  pastRounds: Round[];
  tournaments: ITournament[];
  metadatas: Record<
    number,
    {
      tokenId: number;
      name: string;
      image: string;
      tier: string;
    }
  > | null;

  stake: (
    _roundId: number,
    _tournamentId: number,
    _assetContract: string,
    _tokenId: number
  ) => Promise<void>;
  getPlayers: (_tournamentId: number) => Promise<any[]>;
  unstake: (
    _tournamentId: number,
    _assetContract: string,
    _tokenId: number
  ) => Promise<void>;
  getMyStakedNFTs: (
    address: string,
    _tournamentId: number
  ) => Promise<
    {
      id: string;
      player: string;
      tournamentId: number;
      assetContract: string;
      tokenId: number;
      staked: boolean;
    }[]
  >;
  getRound: (_roundId: number, _tournamentId: number) => Promise<Round | null>;
  getTournaments: () => Promise<ITournament[]>;
  createTournament: (
    _name: string,
    _assetContract: string,
    _maxRounds: number
  ) => Promise<void>;

  createRound: (
    _roundId: number,
    _tournamentId: number,
    _startTime: number,
    _endTime: number,
    _maxPlayers: number
  ) => Promise<void>;
  enrollToRound: (
    _players: number[],
    _roundId: number,
    _tournamentId: number
  ) => Promise<void>;
  updateRound: (
    _roundId: number,
    _tournamentId: number,
    _startTime: number,
    _endTime: number,
    _maxPlayers: number
  ) => Promise<void>;

  setCurrentRound(nextRound: Round | null): void;
  setUpcomingRounds(nextRounds: Round[]): void;
  setPastRounds(pastRounds: Round[]): void;

  setRoundAwards(
    _roundId: number,
    _tournamentId: number,
    _awards: IAwards[]
  ): Promise<void>;

  grantAdminRole: (account: string) => Promise<void>;

  stakeAll: (
    nfts: {
      assetContract: string;
      tournamentId: number;
      tokenId: number;
      roundId: number;
    }[]
  ) => Promise<void>;

  unStakeAll: (
    nfts: {
      assetContract: string;
      tournamentId: number;
      tokenId: number;
    }[]
  ) => Promise<void>;

  hasAdminRole: () => Promise<boolean>;
  setCurrentTournament(tournament: ITournament | null): void;
  setTournaments(tournaments: ITournament[]): void;
  getOwnedNFTs: (account?: string) => Promise<number[]>;
  mintToSelf: (_quantity: number) => Promise<void>;

  fetchNFTSWithMetadata: () => Promise<Record<
    number,
    {
      tokenId: number;
      name: string;
      image: string;
      tier: string;
    }
  > | null>;
  setMetadatas: (
    metadatas: Record<
      number,
      {
        tokenId: number;
        name: string;
        image: string;
        tier: string;
      }
    > | null
  ) => void;
};

const defaultValue = {
  currentRound: null,
  currentTournament: null,
  upcomingRounds: [],
  pastRounds: [],
  tournaments: [],
  metadatas: null,

  stake: async (
    _roundId: number,
    _tournamentId: number,
    _assetContract: string,
    _tokenId: number
  ) => {},
  getPlayers: async (_tournamentId: number): Promise<any[]> => [],
  stakeAll: async (
    nfts: {
      roundId: number;
      tournamentId: number;
      assetContract: string;
      tokenId: number;
    }[]
  ): Promise<void> => {},
  unStakeAll: async (
    nfts: {
      tournamentId: number;
      assetContract: string;
      tokenId: number;
    }[]
  ): Promise<void> => {},
  unstake: async (
    tournamentId: number,
    assetContract: string,
    tokenId: number
  ) => {},

  createRound: async (
    _roundId: number,
    _tournamentId: number,
    _startTime: number,
    _endTime: number,
    _maxPlayers: number
  ) => {},
  updateRound: async (
    _roundId: number,
    _tournamentId: number,
    _startTime: number,
    _endTime: number,
    _maxPlayers: number
  ) => {},
  getRound: async (_roundId: number, _tournamentId: number) => null,
  getMyStakedNFTs: async (_address: string, _tournamentId: number) => [],
  getTournaments: async () => [],
  createTournament: async (
    _name: string,
    _assetContract: string,
    _maxRounds: number
  ) => {},
  grantAdminRole: async (account: string) => {},

  setCurrentRound: (_nextRound: Round | null) => {},
  setUpcomingRounds: (_nextRounds: Round[]) => {},
  hasAdminRole: async (): Promise<boolean> => false,
  setPastRounds: (_pastRounds: Round[]) => {},
  setCurrentTournament: (_tournament: ITournament | null) => {},
  setTournaments: (_tournaments: ITournament[]) => {},

  setRoundAwards: async (
    _roundId: number,
    _tournamentId: number,
    _awards: IAwards[]
  ) => {},

  getOwnedNFTs: async (address?: string): Promise<number[]> => [],
  mintToSelf: async (_quantity: number) => {},
  fetchNFTSWithMetadata: async () => null,
  setMetadatas: (_metadatas: Record<number, any> | null) => {},
  enrollToRound: async (
    _players: number[],
    _roundId: number,
    _tournamentId: number
  ) => {},
};

export const ContractContext = createContext<IContractContext>(defaultValue);
