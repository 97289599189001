import { Routes as Switch, Route } from 'react-router-dom';

import Home from '../../pages/home';
import Dashboard from '../../pages/dashboard';
import { Register } from '../../pages';
import Game from '../../pages/game';
import Leaderboard from '../../pages/leaderboard';
import GameGuide from '../../pages/gameGuide';
import ProtectedRoute from './ProtectedRoute';
import Admin from '../../pages/admin';
import Results from '../../pages/admin/results';
import Airdrop from '../../pages/admin/airdrop';
import CreateRound from '../../pages/admin/createRound';
import Rounds from '../../pages/admin/rounds';
import EditRound from '../../pages/admin/edit';
import Mint from '../../pages/admin/mint';
import RoundsDashboard from '../../pages/rounds';
import CreateTournament from '../../pages/admin/createTournament';

export const Routes = () => {
  return (
    <Switch>
      <Route path="/" element={<Home />} />

      <Route path="/admin" element={<ProtectedRoute component={Admin} />}>
        <Route path="results" element={<Results />} />
        <Route path="drop" element={<Airdrop />} />
        <Route path="rounds" element={<Rounds />} />
        <Route path="mint" element={<Mint />} />
        <Route path="create-tournament" element={<CreateTournament />} />
        <Route path="create" element={<CreateRound />} />
        <Route path="edit" element={<EditRound />} />
      </Route>
      <Route
        path="/dashboard"
        element={<ProtectedRoute component={Dashboard} />}
      />
      <Route
        path="/dashboard/rounds"
        element={<ProtectedRoute component={RoundsDashboard} />}
      />
      <Route path="/lobby" element={<Leaderboard />} />
      <Route path="/register" element={<Register />} />
      <Route path="/game-guide" element={<GameGuide />} />
      <Route
        path="/game/:gameId"
        element={<ProtectedRoute component={Game} />}
      />
      <Route
        path="*"
        element={
          <main style={{ padding: '1rem' }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Switch>
  );
};
