import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ContractContext, PokerContext } from '../../context';
import dateFormat from 'dateformat';
import toast from 'react-hot-toast';
import { PlayersInRound } from './current';

export default function Rounds() {
  const {
    upcomingRounds,
    pastRounds,
    currentRound,
    currentTournament,
    setCurrentRound,
    setUpcomingRounds,
    setPastRounds,
  } = useContext(ContractContext);
  const [selectedRound, setSelectedRound] = useState<{
    roundId: number;
    tournamentId: number;
  } | null>(null);

  const [show, setShow] = useState(false);
  const { getRounds, cancelRound } = useContext(PokerContext);

  useEffect(() => {
    if (currentTournament) {
      getRounds(currentTournament)
        .then((rounds) => {
          if (rounds.length > 0) {
            // get upcoming rounds
            let upcomingRounds = rounds.filter(
              (round) => round.startTime > Date.now()
            );

            // sort upcoming rounds by start time
            upcomingRounds = upcomingRounds.sort(
              (a, b) => a.startTime - b.startTime
            );

            setUpcomingRounds(upcomingRounds);

            // get current rounds
            let ongoingRounds = rounds.filter(
              (round) =>
                Date.now() > round.startTime && Date.now() < round.endTime
            );

            setCurrentRound(ongoingRounds.length > 0 ? ongoingRounds[0] : null);

            ///  get past rounds
            let pastRounds = rounds.filter(
              (round) => Date.now() > round.endTime && round.roundId > 0
            );

            setPastRounds(pastRounds);
          }
        })
        .catch((err) => {
          setCurrentRound(null);
          setUpcomingRounds([]);
          setPastRounds([]);
        });
    }
    // eslint-disable-next-line
  }, [currentTournament]);

  const handleCancelRound = (roundId: number, _tournamentId: number) => {
    if (window.confirm('Are you sure you want to cancel this round?')) {
      try {
        cancelRound(roundId, _tournamentId);
      } catch (err: any) {
        toast(err?.message || 'Error cancelling round');
      }
    } else {
      toast.error('Cancellation cancelled');
    }
  };

  const handleShowPlayers = (roundId: number, tournamentId: number) => {
    setShow(true);
    setSelectedRound({ roundId, tournamentId });
  };
  return (
    <Styledrounds className="container">
      <>
        <h1>Tournament {currentTournament?.name} Rounds</h1>
        <div className="table">
          <div className="table__header">
            <h3>Round No.</h3>
            <h3>Start Time</h3>
            <h3>End Time</h3>
            <h3>Status</h3>
            <h3>Action</h3>
          </div>
          <div className="table__body">
            {currentRound && (
              <div className="table__row" key={currentRound.endTime}>
                <p>{currentRound.roundId}</p>
                <p>{dateFormat(currentRound.startTime)}</p>
                <p>{dateFormat(currentRound.endTime)}</p>
                <p>Current</p>
                <p>
                  <button
                    className="button"
                    onClick={() =>
                      handleShowPlayers(
                        currentRound.roundId,
                        currentRound.tournamentId
                      )
                    }
                  >
                    Players
                  </button>

                  {
                    <button className="button" disabled={true}>
                      Edit
                    </button>
                  }
                  <button
                    className="button"
                    onClick={() =>
                      handleCancelRound(
                        currentRound.roundId,
                        currentRound.tournamentId
                      )
                    }
                    disabled={true}
                  >
                    Cancel
                  </button>
                </p>
              </div>
            )}

            {upcomingRounds
              .sort((round1, round2) => round2.roundId - round1.roundId)
              .map((round, i: number) => (
                <div className="table__row" key={i}>
                  <p>{round.roundId}</p>
                  <p>{dateFormat(round.startTime)}</p>
                  <p>{dateFormat(round.endTime)}</p>
                  <p>Upcoming</p>
                  <p>
                    <button
                      className="button"
                      onClick={() =>
                        handleShowPlayers(round.roundId, round.tournamentId)
                      }
                    >
                      Players
                    </button>

                    {
                      <Link to="/admin/edit" className="button">
                        Edit
                      </Link>
                    }

                    <button
                      className="button"
                      onClick={() =>
                        handleCancelRound(round.roundId, round.tournamentId)
                      }
                      // disabled={PlayersInRound.length > 0}
                    >
                      Cancel
                    </button>
                  </p>
                </div>
              ))}
            {pastRounds
              .sort((round1, round2) => round2.roundId - round1.roundId)
              .map((round, i: number) => (
                <div className="table__row" key={i}>
                  <p>{round.roundId}</p>
                  <p>{dateFormat(round.startTime)}</p>
                  <p>{dateFormat(round.endTime)}</p>
                  <p>Ended</p>
                  <p>
                    <button
                      className="button"
                      onClick={() =>
                        handleShowPlayers(round.roundId, round.tournamentId)
                      }
                    >
                      Players
                    </button>
                    <button className="button" disabled>
                      Edit
                    </button>
                    <button className="button" disabled>
                      Cancel
                    </button>
                  </p>
                </div>
              ))}
          </div>
        </div>
      </>
      {selectedRound && (
        <PlayersInRound
          roundId={selectedRound.roundId}
          tournamentId={selectedRound.tournamentId}
          show={show}
          onclose={() => setShow(false)}
        />
      )}
    </Styledrounds>
  );
}

const Styledrounds = styled.div`
  padding: 0;
  h1 {
    font-size: 1.5rem;
  }
  .table {
    overflow-x: auto;
    margin-top: 1.5rem;
    border: 1px solid ${({ theme }) => theme.quinary};
    h3 {
      width: fit-content;
      min-width: 12rem;
      font-size: 1.1rem;
      padding: 0.8rem;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.quinary};
      }
    }
    p {
      width: fit-content;
      overflow: auto;
      min-width: 12rem;
      font-weight: 300;
      padding: 0.6rem;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.quinary};
      }
    }
    &__header {
      display: grid;
      grid-template-columns: repeat(5, 0fr);
      text-align: center;
      @media (max-width: 864px) {
        width: fit-content;
      }
      background-color: ${({ theme }) => theme.quaternary};
      border-bottom: 1px solid ${({ theme }) => theme.quinary};
    }
    &__body {
      display: grid;
      grid-template-columns: 0fr;
    }
    &__row {
      display: grid;
      grid-template-columns: repeat(5, 0fr);
      text-align: center;
      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.quinary};
      }
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;

        @media (max-width: 864px) {
          gap: 1rem;
        }
      }
    }
  }
`;
