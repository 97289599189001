import { providers } from 'ethers';
import { createContext } from 'react';

type IGlobalContext = {
  isLoading: boolean;
  globalStateChanged: boolean;
  setIsLoading: (value: boolean) => void;
  userName: string | null;
  setUserName: (value: string | null) => void;
  address: string | null;
  setAddress: (value: string | null) => void;
  handleConnectWallet(): Promise<void>;
  getProviderOrSigner(
    needSigner?: boolean
  ): Promise<providers.Web3Provider | providers.JsonRpcSigner | null>;
  setGlobalStateChanged: (value: boolean) => void;
};

const defaultValue = {
  isLoading: true,
  userName: null,
  address: null,
  tables: [],
  players: [],
  globalStateChanged: false,

  setIsLoading: (value: boolean) => {},
  setUserName: (value: string | null) => {},
  setAddress: (value: string | null) => {},

  handleConnectWallet: async () => {},
  getProviderOrSigner: async (
    needSigner?: boolean
  ): Promise<providers.Web3Provider | providers.JsonRpcSigner | null> => null,
  setGlobalStateChanged: (value: boolean) => {},
};

export const GlobalContext = createContext<IGlobalContext>(defaultValue);
