import styled from 'styled-components';
import card1 from '../../assets/animation/1.png';
import card2 from '../../assets/animation/2.png';
import card3 from '../../assets/animation/3.png';
import replace_3 from '../../assets/animation/replace_3.png';
import card4 from '../../assets/animation/4.png';
import card5 from '../../assets/animation/5.png';
import replace_5 from '../../assets/animation/replace_5.png';
import card6 from '../../assets/animation/6.png';
import card7 from '../../assets/animation/7.png';
import replace_7 from '../../assets/animation/replace_7.png';
import card8 from '../../assets/animation/8.png';
import card9 from '../../assets/animation/9.png';
import replace_9 from '../../assets/animation/replace_9.png';
import card10 from '../../assets/animation/10.png';
import card11 from '../../assets/animation/11.png';
import replace_11 from '../../assets/animation/replace_11.png';
import card12 from '../../assets/animation/12.png';
import card13 from '../../assets/animation/13.png';
import replace_13 from '../../assets/animation/replace_13.png';
import card14 from '../../assets/animation/14.png';
import card15 from '../../assets/animation/15.png';
import animeGif from '../../assets/animation/animeGif.gif';

export default function Animation() {
  return (
    <StyledAnimation>
      <div className="gridContainer">
        <section className="container">
          <div className="cardGrid">
            <div className="row-1">
              <img src={card1} alt="" />
              <img src={card2} alt="" />
              <img src={replace_3} alt="" />
              <img src={card4} alt="" />
              <img src={replace_5} alt="" />
            </div>
            <div className="row-2">
              <img src={card6} alt="" />
              <img src={replace_7} alt="" />
              <img src={card8} alt="" />
              <img src={replace_9} alt="" />
              <img src={card10} alt="" />
            </div>
            <div className="row-3">
              <img src={replace_11} alt="" />
              <img src={card12} alt="" />
              <img src={replace_13} alt="" />
              <img src={card14} alt="" />
              <img src={card15} alt="" />
            </div>
          </div>

          <div className="overlay">
            <div className="cardGrid">
              <div className="row-1">
                <img src={card1} alt="" />
                <img src={card2} alt="" />
                <img src={card3} alt="" />
                <img src={card4} alt="" />
                <img src={card5} alt="" />
              </div>
              <div className="row-2">
                <img src={card6} alt="" />
                <img src={card7} alt="" />
                <img src={card8} alt="" />
                <img src={card9} alt="" />
                <img src={card10} alt="" />
              </div>
              <div className="row-3">
                <img src={card11} alt="" />
                <img src={card12} alt="" />
                <img src={card13} alt="" />
                <img src={card14} alt="" />
                <img src={card15} alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobileAnime">
        <img src={animeGif} alt="" />
      </div>
    </StyledAnimation>
  );
}

const StyledAnimation = styled.section`
  margin: 0 auto;
  padding-top: 5rem;
  margin-bottom: 10rem;

  .gridContainer {
    display: none;
    @media (min-width: 780px) {
      display: block;
      .container {
        img {
          width: 256px;
          height: 343px;
        }
        height: 1360px;
        overflow-x: hidden;
        position: relative;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        .cardGrid {
          .row-1 {
            transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 1rem;
          }
          .row-2 {
            transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 1rem;
          }
          .row-3 {
            transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 1rem;
          }
          .row-4 {
            transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 1rem;
          }
          .row-5 {
            transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
            margin-top: 1rem;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 1rem;
          }
        }

        .overlay {
          position: absolute;
          opacity: 0;
          display: grid;
          .cardGrid {
            .row-1 {
              transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
              margin-top: 1rem;
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 1rem;
            }
            .row-2 {
              transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
              margin-top: 1rem;
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 1rem;
            }
            .row-3 {
              transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
              margin-top: 1rem;
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 1rem;
            }
            .row-4 {
              transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
              margin-top: 1rem;
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 1rem;
            }
            .row-5 {
              transform: matrix(0.99, -0.13, 0.14, 0.99, 0, 0);
              margin-top: 1rem;
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 1rem;
            }
          }
          transition: opacity 1s ease-out;
        }
      }
      .container:hover .overlay {
        opacity: 1;
      }
    }
  }
  .mobileAnime {
    display: none;
    @media (max-width: 780px) {
      background-position: top;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 23rem;
      img {
        width: 95%;
      }
    }
  }
`;
