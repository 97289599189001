import { useContext, useState } from 'react';
import { IRound, ITournament } from '../../types';
import { PokerContext } from './pokerContext';
import { GlobalContext } from '../global';
import { config } from '../../config';
import { toast } from 'react-hot-toast';
import { recoverError, sleep } from '../../helpers';
import { useNavigate } from 'react-router-dom';

export const PokerProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const { setIsLoading, globalStateChanged, setGlobalStateChanged } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const [currentTable, setCurrentTable] = useState<IRound | null>(null);
  useState<NodeJS.Timeout | null>(null);

  const leaveTable = () => {
    setCurrentTable(null);
  };

  const createRound = async (params: {
    roundId: number;
    tournamentId: number;
    tournamentAddress: string;
    startTime: number;
    endTime: number;
    maxPlayers: number;
  }) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          let token = localStorage.getItem('token');

          let { data } = await config.axios({
            url: '/api/game/round/create',
            method: 'POST',
            data: JSON.stringify(params),
            headers: {
              Authorization: `x-auth-token ${token}`,
            },
          });

          const { success, error, message, msg } = data;

          if (!success || error) {
            reject(message || msg || error || 'Error creating round');
            return;
          }
          resolve(`${message || msg || 'Round created successfully'}`);
          setIsLoading(false);
          setGlobalStateChanged(!globalStateChanged);
          navigate('/admin/rounds');
        } catch (e: any) {
          setIsLoading(false);
          reject(
            e?.response.data.error || e?.message || 'Error creating round'
          );
        }
      }),
      {
        loading: `Creating round ${params.roundId} in tournament ${params.tournamentId}...`,
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const getRounds = async (tournament: ITournament) => {
    const tournamentId = tournament.id;
    const tournamentAddress = config.TOURNAMENT_ADDRESS;

    const { data } = await config.axios({
      method: 'POST',
      url: `api/game/rounds`,
      data: JSON.stringify({
        tournamentId,
        tournamentAddress,
      }),
    });

    const { data: rounds } = data;

    return rounds.map((round: any) => ({
      roundId: round.roundId,
      startTime: new Date(round.startTime).getTime(),
      endTime: new Date(round.endTime).getTime(),
      tournamentId: round.tournamentId,
      maxPlayers: round.maxPlayers,
      assetContract: round.assetContract || config.EDITION_ADDRESS,
      playerCount: round.playerCount || 0,
    }));
  };

  const getResults = async (tournamentId?: number, roundId?: number) => {
    const { data } = await config.axios({
      method: 'POST',
      url: `api/game/results`,
      data: JSON.stringify({
        tournamentId,
        roundId,
        tournamentAddress: config.TOURNAMENT_ADDRESS,
      }),
    });

    return data?.data;
  };

  const getPlayers = async (tournamentId: number, roundId: number) => {
    setIsLoading(true);
    try {
      const { data } = await config.axios({
        method: 'POST',
        url: `api/game/players`,
        data: JSON.stringify({
          tournamentId,
          roundId,
        }),
      });

      const { data: players } = data;
      setIsLoading(false);
      return players;
    } catch (e: any) {
      toast.error(
        e?.response.data.error || e?.message || 'Error getting players'
      );
    }
    setIsLoading(false);
    return [];
  };

  const replayMatch = async (gameId: string) => {
    const { data } = await config.axios({
      url: `api/game/replay`,
      method: 'POST',
      data: JSON.stringify({
        gameId,
      }),
    });
    const { success, data: round, error } = data;
    !success && error && toast.error(error);
    round && setCurrentTable(round);
    return round;
  };
  const startRound = async (roundId: number, tournamentId: number) => {
    await toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          let token = localStorage.getItem('token');
          const { data } = await config.axios({
            method: 'POST',
            url: `api/game/round/start`,
            data: JSON.stringify({
              roundId,
              tournamentId,
            }),
            headers: {
              Authorization: `x-auth-token ${token}`,
            },
          });
          const { success, error, msg } = data;
          if (!success) {
            reject(
              `Error: ${
                error?.response.data?.error ||
                error?.data?.message ||
                msg ||
                error?.data?.message ||
                error?.message ||
                error
              }`
            );
            setIsLoading(false);
            return;
          }
          resolve(`Results for round ${roundId} are now available.`);
          await sleep(2_000);
          setGlobalStateChanged(!globalStateChanged);
          setIsLoading(false);
        } catch (error: any) {
          reject(
            `Error: ${
              error?.response.data?.error ||
              error?.msg ||
              error?.data?.message ||
              error?.message ||
              error
            }`
          );
          setIsLoading(false);
        }
      }),
      {
        loading: `Starting round ${roundId}...`,
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };
  const cancelRound = async (roundId: number, tournamentId: number) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const { data } = await config.axios({
            method: 'POST',
            url: `api/game/round/cancel`,
            data: JSON.stringify({
              roundId,
              tournamentId,
            }),
            headers: {
              Authorization: `x-auth-token ${localStorage.getItem('token')}`,
            },
          });
          const { success, error, message, msg } = data;
          if (success) {
            resolve(`Round ${roundId} has been cancelled successfully`);
            setGlobalStateChanged(!globalStateChanged);
            setIsLoading(false);
            navigate('/admin/rounds');
          } else {
            reject(message || msg || error || 'Something went wrong');
          }
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
          setIsLoading(false);
        }
      }),
      {
        loading: 'Canceling Round...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };
  const updateRound = async (params: {
    roundId: number;
    tournamentId: number;
    startTime: number | undefined;
    endTime: number | undefined;
    maxPlayers: number | undefined;
  }) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const { data } = await config.axios({
            method: 'POST',
            url: `api/game/round/edit`,
            data: JSON.stringify(params),
            headers: {
              Authorization: `x-auth-token ${localStorage.getItem('token')}`,
            },
          });
          const { success, error, message, msg } = data;
          if (success) {
            resolve(`Round ${params.roundId} has been updated successfully`);
            setGlobalStateChanged(!globalStateChanged);
            setIsLoading(false);
            navigate('/admin/rounds');
          } else {
            reject(message || msg || error || 'Something went wrong');
          }
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
          setIsLoading(false);
        }
      }),
      {
        loading: 'Updating Round...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  return (
    <PokerContext.Provider
      value={{
        currentTable,
        leaveTable,
        setCurrentTable,
        getRounds,
        replayMatch,
        startRound,
        createRound,
        getResults,
        getPlayers,
        cancelRound,
        updateRound,
      }}
    >
      {children}
    </PokerContext.Provider>
  );
};
