import styled from 'styled-components';
import icon from '../../assets/icon.png';

export default function Navigation() {
  return (
    <StyledNavigation id="faq">
      <section className="container">
        <img src={icon} alt="" />
        <div>
          <a href="#info">What is Capped Range?</a>
          <a href="#benefits">Benefits</a>
          <a href="#roadmap">Roadmap</a>
          {/* <a href="#team">Team</a> */}
          <a href="#faq">FAQ</a>
        </div>
      </section>
    </StyledNavigation>
  );
}

const StyledNavigation = styled.section`
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.ttf') format('truetype');
  }
  padding-top: 10rem;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 3rem 0;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      font-family: 'Inter' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      @media (max-width: 576px) {
        gap: 1rem;
        font-size: 10px;
      }
      a {
        color: #fff;

        text-decoration: none;
        transition: all 0.3s ease;
        &:hover {
          color: #ffc728;
        }
      }
    }
  }
`;
