import styled from 'styled-components';
import tableImg from '../assets/table.png';
import { IRound } from '../types';

function importAll(r: any) {
  let images: any = [];
  r.keys().forEach((item: any) => {
    images[item.replace('./', '').replace('.png', '')] = r(item);
  });
  return images;
}

const cardsImages = importAll(
  require.context('../assets/cards', false, /\.(png)$/)
);

function PokerTable({
  currentTable,
  countdown,
}: {
  currentTable: IRound;
  countdown: number;
}) {
  const cardsToImages = (cards: string[], seatNumber: number) => {
    return cards.map((card, i) => {
      card = card.toUpperCase();
      let src = cardsImages[card];
      if (
        !src ||
        (seatNumber === 1 && countdown > 7) ||
        (seatNumber === 2 && countdown > 8) ||
        // 3. commmunity cards
        (seatNumber === 0 && countdown > 6 && i < 3) ||
        // the turn
        (seatNumber === 0 && countdown > 5 && i === 3) ||
        // the river
        (seatNumber === 0 && countdown > 4 && i === 4)
      ) {
        src = cardsImages['BACK'];
      }
      return <img key={i} className="card" src={src} alt={card} />;
    });
  };

  return (
    <StyledPokerTable>
      <div className="hand-cards opponent-hand">
        {cardsToImages(currentTable.player1.hand, 1)}
      </div>
      <div className="flops-cards">
        {cardsToImages(currentTable.table.board, 0)}
      </div>
      <div className="hand-cards my-hand">
        {cardsToImages(currentTable.player2.hand, 2)}
      </div>
    </StyledPokerTable>
  );
}

const StyledPokerTable = styled.div`
  background-image: url(${tableImg});
  background-size: contain;
  background-repeat: no-repeat;
  height: max-content;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30rem;
  width: 60rem;
  margin: 1rem auto;
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 35%;
  }

  .hand-cards {
    bottom: 0;
  }

  .hand-cards,
  .flops-cards {
    display: flex;
    flex-direction: row;
  }

  .card {
    max-height: 100%;
    display: block;
    margin: 1rem;
  }

  .flops-cards > .card {
    max-width: 20%;
  }

  .hand-cards > .card {
    max-width: 50%;
  }
`;

export default PokerTable;
