import { useContext, FC } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../context/auth';

const ProtectedRoute = ({ component: Component }: { component: FC }) => {
  const { isLoggedIn } = useContext(AuthContext);
  return isLoggedIn ? <Component /> : <Navigate to="/" />;
};

export default ProtectedRoute;
