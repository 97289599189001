import { FormEvent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { config } from '../../config';
import { ContractContext, GlobalContext, PokerContext } from '../../context';
import { toast } from 'react-hot-toast';
import Select from '../../components/Select';

export default function CreateRound() {
  const { currentTournament, tournaments } = useContext(ContractContext);
  const { createRound } = useContext(PokerContext);
  const { setIsLoading } = useContext(GlobalContext);
  const [roundId, setRoundId] = useState<number>(
    (currentTournament?.roundCount || 0) + 1
  );
  const [tournamentId, setTournamentId] = useState<number>(
    currentTournament?.id || 1
  );
  const [startTime, setStartTime] = useState<string>(
    new Date().toISOString().split(':').slice(0, 2).join(':')
  );

  const [endTime, setEndTime] = useState<string>(
    new Date(new Date(Date.now()).setHours(24 * 7))
      .toISOString()
      .split(':')
      .slice(0, 2)
      .join(':')
  );
  const [maxPlayers, setMaxPlayers] = useState<number>(config.MAX_PLAYERS);

  useEffect(() => {
    setMaxPlayers(config.MAX_PLAYERS * Math.pow(0.5, roundId - 1));
  }, [roundId]);

  const handleCreateRound = async (e: FormEvent) => {
    e.preventDefault();

    // validate user input
    if (roundId < 1) {
      toast.error('Round number must be greater than 0');
      return;
    }
    if (tournamentId < 1) {
      toast.error('Tournament ID must be greater than 0');
      return;
    }

    if (startTime > endTime) {
      toast.error('Start time must be less than end time');
      return;
    }
    if (new Date(startTime) < new Date()) {
      toast.error('Start time must be in the future');
      return;
    }
    if (maxPlayers < 1) {
      toast.error('Max players must be greater than 0');
      return;
    }

    try {
      setIsLoading(true);

      createRound({
        roundId,
        tournamentId,
        startTime: new Date(startTime).getTime(),
        endTime: new Date(endTime).getTime(),
        maxPlayers,
        tournamentAddress: config.TOURNAMENT_ADDRESS,
      });
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
      setIsLoading(false);
    }
  };

  return (
    <StyledAdminPanel>
      <h1>Create Round</h1>
      <form onSubmit={handleCreateRound}>
        <label>
          <h4>Round ID</h4>
          <input
            type="number"
            step={1}
            value={roundId}
            onChange={(e) => setRoundId(Number(e.target.value))}
            required
            min={1}
          />
        </label>
        <label>
          <h4>Tournament ID</h4>
          <Select
            value={tournamentId.toString()}
            onChange={(e) => setTournamentId(Number(e.target.value))}
            options={tournaments.map((tournament) => ({
              value: tournament.id.toString(),
              label: `${tournament.name}`,
            }))}
          />
        </label>
        <label>
          <h4>Round start time</h4>
          <input
            type="datetime-local"
            name="start_time"
            required
            onChange={(e) => setStartTime(e.target.value)}
          />
        </label>
        <label>
          <h4>Round end time</h4>
          <input
            type="datetime-local"
            name="end_time"
            required
            onChange={(e) => setEndTime(e.target.value)}
          />
        </label>
        <label>
          <h4>Max Players {roundId ? `In Round ${roundId}` : ''}</h4>
          <input
            type="number"
            placeholder={`Max players in round ${roundId}`}
            name="maxPlayers"
            value={maxPlayers}
            onChange={(e) => setMaxPlayers(Number(e.target.value))}
            required
            min={1}
          />
        </label>
        <button type="submit" className="button">
          Create Round
        </button>
      </form>
    </StyledAdminPanel>
  );
}

const StyledAdminPanel = styled.div`
  h1 {
    font-size: 1.5rem;
  }
  form {
    margin-top: 1.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
    }
    label {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;
      h4 {
        font-size: 1rem;
        font-weight: 400;
      }
    }
    input {
      width: 100%;
      display: inline-block;
      background: #25262a;
      border: 1px solid #4c4f58;
      padding: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      color: #fff;
    }
  }
`;
