import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoImg from '../assets/icons/logo.png';
import TwitterIcon from '../assets/icons/twitter.png';
import DiscordIcon from '../assets/icons/discord.png';
import { FaBars } from 'react-icons/fa';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/auth';
import { GlobalContext, ContractContext } from '../context';
import { toast } from 'react-hot-toast';

export default function Nav() {
  const { isLoggedIn, logout, login } = useContext(AuthContext);
  const { userName, address, handleConnectWallet, isLoading } =
    useContext(GlobalContext);

  const { hasAdminRole } = useContext(ContractContext);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (address) {
      login(address);

      hasAdminRole().then(
        (res: boolean | ((prevState: boolean) => boolean)) => {
          setIsAdmin(res);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const [showMenu, setShowMenu] = useState(
    window.innerWidth > 1120 ? true : false
  );

  const handleLogin = async () => {
    try {
      await handleConnectWallet();
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleMenuClose = () => {
    if (window.innerWidth <= 1120) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 1120) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    });
    return () => {
      window.removeEventListener('resize', () => {
        if (window.innerWidth > 1120) {
          setShowMenu(true);
        } else {
          setShowMenu(false);
        }
      });
    };
  }, []);

  return (
    <StyledNav>
      <div className="container">
        <Link to="/">
          <img src="/logo.png" alt="Capped Range Logo" className="logo" />
          <img className="logoImg" src={logoImg} alt="" />
        </Link>
        <button className="menu" onClick={() => setShowMenu(!showMenu)}>
          <FaBars />
        </button>
        {showMenu && (
          <div className="links">
            <div className="hrefs">
              {isLoggedIn ? (
                <>
                  <Link to="/dashboard" onClick={() => handleMenuClose()}>
                    Dashboard
                  </Link>
                  <Link to="/lobby" onClick={() => handleMenuClose()}>
                    Lobby
                  </Link>
                  {isAdmin && (
                    <Link to="/admin" onClick={() => handleMenuClose()}>
                      Admin Panel
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <a href="/#info" onClick={() => handleMenuClose()}>
                    What is Capped Range?
                  </a>
                  <a href="/#benefits" onClick={() => handleMenuClose()}>
                    Benefits
                  </a>
                  <a href="/#roadmap" onClick={() => handleMenuClose()}>
                    Roadmap
                  </a>

                  {/* <a href="/#team" onClick={() => handleMenuClose()}>
                    Team
                  </a> */}
                  <a href="/#faq" onClick={() => handleMenuClose()}>
                    FAQ
                  </a>
                </>
              )}
            </div>
            <div className="actions">
              {isLoggedIn ? (
                <>
                  <span>{userName}</span>
                  <button
                    onClick={logout}
                    style={{
                      border: 'none',
                      background: 'none',
                      color: '#fff',
                    }}
                  >
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="button"
                    style={{
                      opacity: isLoading ? 0.5 : 1,
                      pointerEvents: isLoading ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      handleLogin();
                      handleMenuClose();
                    }}
                  >
                    Connect Wallet
                  </button>
                  <a
                    href="https://twitter.com/cappedrange"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleMenuClose()}
                  >
                    <img src={TwitterIcon} alt="" />
                  </a>
                  <a
                    href="https://discord.gg/4JqkTg4VE8"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleMenuClose()}
                  >
                    <img src={DiscordIcon} alt="" />
                  </a>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </StyledNav>
  );
}

const StyledNav = styled.nav`
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.ttf') format('truetype');
  }

  background: ${({ theme }) => theme.tertiary};
  padding: 1rem 0;
  z-index: 2;
  position: relative;
  .container {
    display: flex;
    align-items: center;
    position: relative;
    .logoImg {
      margin-top: 0.4rem;
    }
  }
  .menu {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    cursor: pointer;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.primary};
    font-size: 1.2rem;
    @media (max-width: 1120px) {
      display: block;
    }
  }
  .links {
    font-family: 'Inter' !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    margin-left: 1.5rem;
    flex: 1;
    @media (max-width: 1120px) {
      position: absolute;
      top: 100%;
      left: 0;
      flex-direction: column;
      background: ${({ theme }) => theme.tertiary};
      width: 100%;
      padding: 1.5rem 1rem;
      margin-left: 0;
    }
  }
  a {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 2rem;
    margin-right: 1rem;
  }
  .hrefs {
    display: flex;
    align-items: center;
    @media (max-width: 1120px) {
      flex-direction: column;
    }
    a {
      &:not(:last-of-type) {
        margin-right: 1rem;
        padding-right: 1rem;

        @media (max-width: 1120px) {
          /* border: none; */
          margin-bottom: 1rem;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    button {
      font-size: 1rem;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    span {
      color: ${({ theme }) => theme.primary};
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    a {
      &:not(:last-child) {
        margin-right: 1.5rem;
        @media (max-width: 1120px) {
          margin-right: 1rem;
        }
      }
      svg {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.primary};
      }
    }
  }
`;
