import { ThirdwebSDK } from '@thirdweb-dev/sdk';
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import { config } from '../../config';
import { ContractContext, GlobalContext, PokerContext } from '../../context';
import Select from '../../components/Select';
import { DropTable } from '../../components/dropTable';
import { IAwards, IResult } from '../../types';
import { providers } from 'ethers';
import { FaSadCry, FaTrophy } from 'react-icons/fa';

export default function Airdrop() {
  const { getProviderOrSigner, setIsLoading, globalStateChanged } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  const [recipient, setRecipient] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [tokenId, setTokenId] = useState('');
  const [assetContract, setAssetContract] = useState(config.EDITION_ADDRESS);

  const [quantity1, setQuantity1] = useState(1);
  const [tokenId1, setTokenId1] = useState('');

  const [winnersEnabled, setWinnersEnabled] = useState(true);
  const [assetContract1, setAssetContract1] = useState('');

  const [batchValue, setBatchValue] = useState('');
  const [results, setResults] = useState<IResult[]>([]);
  const [filteredResults, setFilteredResults] = useState<IResult[]>([]);
  const [paginate, setPaginate] = useState(10);
  const [curPage, setCurPage] = useState(1);
  const { isLoading } = useContext(GlobalContext);
  const { currentTournament, setRoundAwards, metadatas } =
    useContext(ContractContext);
  const { getResults } = useContext(PokerContext);

  const maxPage = useMemo(() => {
    return Math.max(Math.ceil(filteredResults.length / paginate), 1);
  }, [paginate, filteredResults]);

  useEffect(() => {
    if (currentTournament) {
      getResults(currentTournament.id)
        .then((_results) => {
          setResults(
            _results.map((result, i) => ({
              ...result,
              page: Math.ceil((i + 1) / paginate),
            }))
          );
        })
        .catch((err) => {
          setResults([]);
          toast.error(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTournament, metadatas, globalStateChanged]);

  useEffect(() => {
    setFilteredResults(
      results
        .filter(
          (result) =>
            config.MAX_PLAYERS * Math.pow(0.5, result.roundId - 1) ===
              Number(batchValue) && winnersEnabled === result.nftWon
        )
        .map((result, i: number) => {
          return {
            ...result,
            page: Math.ceil((i + 1) / paginate),
          };
        })
    );
  }, [batchValue, results, paginate, winnersEnabled]);

  const handleOnSubmitSingle = async (e: FormEvent) => {
    e.preventDefault();
    if (!recipient.trim()) {
      toast.error('Please enter a valid recipient address');
      return;
    }

    if (!assetContract.trim()) {
      toast.error('Please enter asset contract address');
      return;
    }

    if (!tokenId.trim()) {
      toast.error('Please enter a tokenId');
      return;
    }

    // prompt user to confirm
    const confirm = window.confirm(
      `Are you sure you want to send ${quantity} nft(${assetContract}) to ${recipient}?`
    );

    if (!confirm) {
      toast('Airdrop cancelled!');
      return;
    }
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          if (window?.ethereum) {
            const signer = await getProviderOrSigner(true);
            if (signer) {
              const sdk = new ThirdwebSDK(signer);
              const contract = sdk.getNFTCollection(assetContract);

              await contract.transfer(recipient, tokenId);
              resolve('Airdrop successful');
              setIsLoading(false);
              navigate('/admin');
            }
          } else {
            reject('No signer');
          }
        } catch (error: any) {
          reject(`Airdrop failed: ${error?.data?.message || error?.message}`);
          setIsLoading(false);
        }
      }),
      {
        loading: `Airdropping to ${recipient}...`,
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const handleOnSubmitBatch = async (e: FormEvent) => {
    e.preventDefault();
    if (!assetContract1.trim()) {
      toast.error('Please enter asset contract address');
      return;
    }

    if (!tokenId1.trim()) {
      toast.error('Please enter a tokenId');
      return;
    }

    if (quantity1 < 1) {
      toast.error('Please enter a valid quantity');
      return;
    }

    if (!batchValue.trim()) {
      toast.error('Please select a batch/rank to airdrop');
      return;
    }

    const recipients = results.filter(
      (result: IResult) =>
        config.MAX_PLAYERS * Math.pow(0.5, result.roundId - 1) ===
          Number(batchValue) && winnersEnabled === result.nftWon
    );

    if (recipients.length !== quantity1) {
      toast.error(
        `Quantity, recipient count mismatch!. Found ${recipients.length} recipients for rank ${batchValue} and specified quantity was ${quantity1}`
      );
      return;
    }

    // prompt user to confirm
    const confirm = window.confirm(
      `Are you sure you want to send ${quantity1} nfts(${assetContract1}) to ${
        recipients.length
      } ${winnersEnabled ? 'winners' : 'losers'}?`
    );

    if (!confirm) {
      toast('Airdrop cancelled!');
      return;
    }
    try {
      toast.promise(
        new Promise(async (resolve, reject) => {
          setIsLoading(true);
          try {
            const signer = (await getProviderOrSigner(
              true
            )) as providers.JsonRpcSigner;

            const sdk = new ThirdwebSDK(signer);
            const contract = sdk.getEdition(assetContract1);

            const nfts = await contract.getOwned();

            const _nft = nfts.find(
              (nft) => Number(nft.metadata.id) === Number(tokenId1)
            );
            if (_nft?.supply && Number(_nft.supply) >= recipients.length) {
              await contract.airdrop(
                tokenId1,
                recipients.map((_recipient) => {
                  return {
                    address: _recipient.player,
                    quantity: 1,
                  };
                })
              );

              let _roundId = 0;
              let _tournamentId = 0;
              const _awards: IAwards[] = recipients.map((_recipient) => {
                _roundId = _recipient.roundId;
                _tournamentId = _recipient.tournamentId;
                return {
                  player: _recipient.player,
                  roundId: _recipient.roundId,
                  tournamentId: _recipient.tournamentId,
                  assetContract: assetContract1,
                  tokenId: Number(tokenId1),
                  awardId: 0,
                  amount: 1,
                };
              });

              await setRoundAwards(_roundId, _tournamentId, _awards);
              resolve('Airdrop successful');
              setIsLoading(false);
              navigate('/admin/drop');
            } else {
              reject(
                `Not enough NFTs to airdrop. Found ${nfts.length} NFTs and specified quantity was ${recipients.length}`
              );
              setIsLoading(false);
            }
          } catch (error: any) {
            reject(`Airdrop failed: ${error?.data?.message || error?.message}`);
            setIsLoading(false);
          }
        }),
        {
          loading: `Airdropping ${quantity1} nfts to ${recipients.length} ${
            winnersEnabled ? 'winners' : 'losers'
          }...`,
          success: (msg: any) => `${String(msg)}`,
          error: (msg: any) => `${String(msg)}`,
        }
      );
    } catch (error: any) {
      toast.error(
        `Batch Airdrop failed: ${
          error?.data?.message || error?.message || error
        }`
      );
      setIsLoading(false);
    }
  };

  return (
    <StyledAirdrop>
      <h1>Airdrop (ERC721)</h1>
      <form>
        <input
          type="string"
          placeholder="Asset Contract"
          required
          value={assetContract}
          onChange={(e) => setAssetContract(e.target.value)}
        />
        <input
          type="number"
          min={0}
          placeholder="Token Id"
          value={tokenId}
          required
          onChange={(e) => setTokenId(e.target.value)}
        />
        <input
          type="number"
          placeholder="Amount"
          required
          min={1}
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
        />
        <input
          type="text"
          placeholder="Address"
          required
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
        />
        <button type="submit" className="button" onClick={handleOnSubmitSingle}>
          Send
        </button>
      </form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <h3>Batch Airdrop (ERC1155)</h3>
        <h3
          onClick={() => {
            setWinnersEnabled(!winnersEnabled);
          }}
          style={{
            display: 'flex',
            gap: '0.5rem',
            cursor: 'pointer',
            alignContent: 'center',
          }}
        >
          <span>
            {' '}
            Click to {winnersEnabled ? 'Show Losers' : 'Show Winners'}
          </span>
          <span>
            {winnersEnabled ? (
              <FaSadCry color="red" />
            ) : (
              <FaTrophy color="green" />
            )}
          </span>
        </h3>
      </div>
      <form>
        <input
          type="string"
          placeholder="Asset Contract"
          required
          value={assetContract1}
          onChange={(e) => setAssetContract1(e.target.value)}
        />
        <input
          type="number"
          min={0}
          placeholder="Token Id"
          required
          value={tokenId1}
          onChange={(e) => setTokenId1(e.target.value)}
        />
        <input
          type="number"
          placeholder="Amount Per Address/Winner"
          required
          min={1}
          value={quantity1}
          onChange={(e) => setQuantity1(Number(e.target.value))}
        />
        <Select
          options={[
            {
              value: '',
              label: 'Choose Batch',
            },
            {
              value: '1',
              label: `Rank 1 (1 ${winnersEnabled ? 'winner' : 'loser'})`,
            },
            {
              value: '2',
              label: `Rank 2 (1 ${winnersEnabled ? 'winner' : 'loser'})`,
            },
            {
              value: '4',
              label: `Rank 4 (2 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '8',
              label: `Rank 8 (4 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '16',
              label: `Rank 16 (8 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '32',
              label: `Rank 32 (16 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '64',
              label: `Rank 64 (32 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '128',
              label: `Rank 128 (64 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '256',
              label: `Rank 256 (128 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '512',
              label: `Rank 512 (256 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '1024',
              label: `Rank 1024 (512 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '2048',
              label: `Rank 2048 (1024 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '4096',
              label: `Rank 4096 (2048 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
            {
              value: '8192',
              label: `Rank 8192 (4096 ${winnersEnabled ? 'winner' : 'loser'}s)`,
            },
          ]}
          value={batchValue}
          onChange={(e) => setBatchValue(e.target.value)}
          placeholder="Choose Batch"
        />
        <button type="submit" className="button" onClick={handleOnSubmitBatch}>
          Send
        </button>
      </form>
      {batchValue && (
        <DropTable
          batch={batchValue}
          results={filteredResults}
          curPage={curPage}
          isLoading={isLoading}
          paginate={paginate}
          setCurPage={setCurPage}
          maxPage={maxPage}
          winnersEnabled={winnersEnabled}
        />
      )}
    </StyledAirdrop>
  );
}

const StyledAirdrop = styled.div`
  h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  h3 {
    margin-bottom: 1.5rem;
  }
  form {
    margin-bottom: 1.5rem;
    width: 100%;
    display: flex;
    /* align-items: center; */
    gap: 1rem;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
    }
    input {
      width: 100%;
      display: inline-block;
      background: #25262a;
      border: 1px solid #4c4f58;
      padding: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      color: #fff;
    }
  }
`;
