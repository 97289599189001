import styled from 'styled-components';

export default function RoundDashboard() {
  return (
    <Styledrounds className="container">
      <h1>Past/Upcoming Rounds</h1>
      <div className="table">
        <div className="table__header">
          <h3>Round No.</h3>
          <h3>Date and Time</h3>
          <h3>Status</h3>
        </div>
        <div className="table__body">
          <div className="table__row">
            <p>1</p>
            <p>{new Date().toUTCString()}</p>
            <p>Finished</p>
          </div>
        </div>
      </div>
    </Styledrounds>
  );
}

const Styledrounds = styled.div`
  padding: 2rem 1rem !important;
  h1 {
    font-size: 1.5rem;
  }
  .table {
    overflow-x: auto;
    margin-top: 1.5rem;
    border: 1px solid ${({ theme }) => theme.quinary};
    h3 {
      /* width: 207.5px; */
      width: 100%;
      min-width: 207.5px;
      font-size: 1.1rem;
      padding: 0.8rem;
      overflow: auto;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.quinary};
      }
    }
    p {
      /* width: 207.5px; */
      width: 100%;
      min-width: 207.5px;
      font-weight: 300;
      padding: 0.6rem;
      overflow: auto;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.quinary};
      }
    }
    &__header {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      /* width: fit-content; */
      @media (max-width: 654px) {
        width: fit-content;
      }
      background-color: ${({ theme }) => theme.quaternary};
      border-bottom: 1px solid ${({ theme }) => theme.quinary};
    }
    &__body {
      /* width: fit-content; */
      display: grid;
      grid-template-columns: 1fr;
    }
    &__row {
      /* width: fit-content; */
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.quinary};
      }
      p {
        overflow: auto;
      }
    }
  }
`;
