import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../../components/Loader';
import { ContractContext, GlobalContext, PokerContext } from '../../context';
import { groupBy, humanizeAddress } from '../../helpers';
import { IResult } from '../../types';
import { usePapaParse } from 'react-papaparse';
import flatten from 'flat';
import { config } from '../../config';

export default function Results() {
  const { jsonToCSV } = usePapaParse();
  const { getResults } = useContext(PokerContext);
  const navigate = useNavigate();

  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const [results, setResults] = useState<IResult[]>([]);
  const [matches, setMatches] = useState<Record<string, IResult[]>>({});
  const [filteredMatches, setFilteredMatches] = useState<
    Record<string, IResult[]>
  >({});

  const { currentTournament } = useContext(ContractContext);

  useEffect(() => {
    if (currentTournament) {
      setIsLoading(true);
      getResults(currentTournament.id)
        .then((results) => {
          setResults(results);
        })
        .catch((err) => {
          toast.error(err);
          setIsLoading(false);
          setResults([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTournament]);

  useEffect(() => {
    if (results.length > 0) {
      setIsLoading(true);
      let _matches = groupBy(
        results.sort((a, b) => b.roundId - a.roundId),
        'id'
      );
      setMatches(_matches);
      setFilteredMatches(_matches);
      setIsLoading(false);
    } else {
      let _matches = groupBy(
        results.sort((a, b) => b.roundId - a.roundId),
        'id'
      );
      setMatches(_matches);
      setFilteredMatches(_matches);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const handleResultFilter = (e: { target: { value: string } }) => {
    setIsLoading(true);
    const value = e.target.value.trim();
    if (value === '' || value === 'all') {
      setFilteredMatches(matches);
    }
    // filter results by round ID
    else {
      const _matches: Record<string, IResult[]> = {};
      Object.keys(matches).forEach((key) => {
        const match = matches[key];
        if (match.length > 0) {
          let _match = match.filter((result) => {
            return result.roundId.toString() === value;
          });
          if (_match.length > 0) {
            _matches[key] = _match;
          }
        }
      });
      setFilteredMatches(_matches);
    }
    setIsLoading(false);
  };

  const exportToCSV = () => {
    toast.promise(
      new Promise((resolve, reject) => {
        try {
          const flattenedResults = Object.entries(filteredMatches).map(
            ([id, results]) => {
              const [player1, player2] = results;

              const winner: IResult = player1.nftWon ? player1 : player2;

              const _result = {
                tournamentId: winner.tournamentId,
                roundId: winner.roundId,
                assetContract: player1.assetContract,
                playerATokenId: player1.tokenId,
                playerAAddress: player1.player,
                playerBTokenId: player2.tokenId,
                playerBAddress: player2.player,
                WinnerTokenID: winner.tokenId,
                WinnerAddress: winner.player,
              };

              return flatten(_result);
            }
          );

          const csv = jsonToCSV(flattenedResults);
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'results.csv';
          a.click();
          resolve(csv);
        } catch (err: any) {
          reject(err.message || err);
        }
      }),
      {
        loading: 'Exporting...',
        success: 'Exported successfully',
        error: 'Error exporting',
      }
    );
  };

  const handleOnReplay = (matchId: string) => {
    if (matchId) {
      navigate(`/game/${matchId}`);
    }
  };

  return (
    <StyledResults>
      <div className="header">
        <h1>
          {isLoading ? (
            `Loading ${currentTournament?.name || ''} results...`
          ) : (
            <>
              {' '}
              Showing {currentTournament?.name || ''} Results (for{' '}
              <b>{Object.keys(filteredMatches).length}</b> team
              {Object.keys(filteredMatches).length > 1 ? 's' : ''}
              {/* {currentTournament?.roundCount &&
                `, round ${currentTournament?.roundCount}`} */}
              )
            </>
          )}
        </h1>
        <select
          style={{
            padding: '0.5rem',
            borderRadius: '0.5rem',
            border: '1px solid #ccc',
          }}
          onChange={handleResultFilter}
          disabled={isLoading}
        >
          <option value="">{'Show All Results'}</option>
          {Array.from(
            { length: currentTournament?.roundCount || config.MAX_ROUNDS },
            (_, i) => i + 1
          ).map((id) => (
            <option key={id} value={id}>
              Show round {id} results
            </option>
          ))}
        </select>
        <div>
          {results.length > 0 && (
            <button className="button" onClick={() => exportToCSV()}>
              Export to CSV
            </button>
          )}
        </div>
      </div>
      <div className="table">
        <div className="table__header">
          <h3>Tournament ID</h3>
          <h3>Round ID</h3>
          <h3>Player 1 (Token ID)</h3>
          <h3>Player 2 (Token ID)</h3>
          <h3>Winner</h3>
          <h3>Winner Hand Type</h3>
          <h3>Created At</h3>
          <h3>Replay</h3>
          <h3>Published</h3>
        </div>
        <div className="table__body">
          {Object.keys(filteredMatches).length > 0 ? (
            Object.entries(filteredMatches).map(([id, result], i) => {
              const [player1, player2] = result;

              const winner: IResult = player1.nftWon ? player1 : player2;

              return (
                <div className="table__row" key={i}>
                  <p>{winner?.tournamentId}</p>
                  <p>{winner.roundId}</p>
                  <p>{`${humanizeAddress(player1.player || '')} (${
                    player1.tokenId
                  })`}</p>
                  <p>{`${humanizeAddress(player2.player || '')} (${
                    player2.tokenId
                  })`}</p>
                  <p>{`${humanizeAddress(winner.player || '')} (${
                    winner.tokenId
                  })`}</p>
                  <p>{winner.winnerHandType}</p>
                  <p>{new Date(winner.createdAt).toLocaleString()}</p>
                  <p>
                    <span
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: 'yellow',
                      }}
                      onClick={() => handleOnReplay(id)}
                    >
                      Replay
                    </span>
                  </p>
                  <p>
                    {results.some(
                      (r) =>
                        r.roundId === winner.roundId &&
                        r.assetContract.toUpperCase() ===
                          config.EDITION_ADDRESS.toUpperCase() &&
                        [player1.tokenId, player2.tokenId].includes(r.tokenId)
                    )
                      ? 'Yes'
                      : 'No'}
                  </p>
                </div>
              );
            })
          ) : (
            <p>{!isLoading && 'No results found'}</p>
          )}
          {isLoading && <Loader />}
        </div>
      </div>
    </StyledResults>
  );
}

const StyledResults = styled.div`
  h1 {
    font-size: 1.5rem;
  }
  select:disabled {
    background-color: #ccc;
    cursor: progress;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      button {
        margin-left: 0.5rem;
      }
    }
  }
  .table {
    overflow-x: auto;
    margin-top: 1.5rem;
    border: 1px solid ${({ theme }) => theme.quinary};
    h3 {
      /* width: 207.5px; */
      font-size: 1rem;
      padding: 0.8rem;
      line-height: 1.5rem;
      overflow: auto;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.quinary};
      }
    }
    p {
      /* width: 207.5px; */
      font-weight: 300;
      padding: 0.6rem;
      overflow: auto;
      &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.quinary};
      }
    }
    &__header {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      text-align: center;
      background-color: ${({ theme }) => theme.quaternary};
      border-bottom: 1px solid ${({ theme }) => theme.quinary};
    }
    &__body {
      display: grid;
      grid-template-columns: 1fr;
    }
    &__row {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      text-align: center;
      &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.quinary};
      }
      p {
        overflow: auto;
      }
    }
  }
`;
