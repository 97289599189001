import { Contract, providers, utils } from 'ethers';
import { useContext } from 'react';
import { toast } from 'react-hot-toast';
import { config } from '../config';
import { GlobalContext } from '../context';
import { TournamentABI } from '../utils';
import { recoverError, sleep, _chunk } from '../helpers';
import { useNavigate } from 'react-router-dom';
import { IAwards } from '../types';

import axios from 'axios';

export const useContract = () => {
  const navigate = useNavigate();
  const {
    setIsLoading,
    address,
    getProviderOrSigner,
    globalStateChanged,
    setGlobalStateChanged,
  } = useContext(GlobalContext);

  const hasAdminRole = async () => {
    try {
      const signer = (await getProviderOrSigner(
        true
      )) as providers.JsonRpcSigner;

      const TournamentContract = new Contract(
        config.TOURNAMENT_ADDRESS,
        JSON.stringify(TournamentABI),
        signer
      );

      const AdminRole = await TournamentContract.DEFAULT_ADMIN_ROLE();

      return await TournamentContract.hasRole(AdminRole, address);
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
    return false;
  };
  const grantAdminRole = async (account: string) => {
    try {
      setIsLoading(true);

      const signer = (await getProviderOrSigner(
        true
      )) as providers.JsonRpcSigner;

      const TournamentContract = new Contract(
        config.TOURNAMENT_ADDRESS,
        JSON.stringify(TournamentABI),
        signer
      );

      const AdminRole = await TournamentContract.DEFAULT_ADMIN_ROLE();

      await TournamentContract.grantRole(AdminRole, account);
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
    setIsLoading(true);
  };

  const getSignature = async (
    params: {
      tournamentId: number;
      assetContract: string;
      tokenId: number;
      roundId: number;
      sender: string;
      tournamentAddress: string;
    }[]
  ) => {
    try {
      const { data } = await config.axios({
        method: 'post',
        url: '/api/game/staking',
        data: JSON.stringify({ payload: params }),
      });
      const { data: signature, error, success, msg, message } = data;

      if (!success || error || msg || message) {
        toast.error(msg?.msg || message || error || 'An error occurred');
        return;
      }
      return signature;
    } catch (error: any) {
      return [''];
    }
  };

  const getPlayers = async (
    tournamentId: number
  ): Promise<
    {
      id: number;
      player: string;
      tournamentId: number;
      assetContract: string;
      tokenId: number;
      staked: boolean;
    }[]
  > => {
    setIsLoading(true);

    try {
      let players = [];
      for (let start = 0; start < config.MAX_PLAYERS; start += 1000) {
        for (let i = 0; i < 2; i++) {
          try {
            let query = `
            {
              stakings(first: 1000, where: {tokenId_gte: ${start},tokenId_lte: ${
              start + 1000
            },tournamentId: ${tournamentId},staked: ${
              i % 2 === 0
            }},orderBy: tokenId,orderDirection: asc) {
                id
                tournamentId
                assetContract
                player
                tokenId
                staked
                timestamp
              }
            }
            `;

            delete axios.defaults.headers.common['x-auth-token'];

            const { data } = await axios({
              method: 'POST',
              url:
                process.env.REACT_APP_SUBGRAPH_URL ||
                'https://api.thegraph.com/subgraphs/name/johsuadev/capped-range',
              data: {
                query,
              },
            });
            let { stakings } = data?.data;
            stakings && players.push(...stakings);
          } catch (error: any) {
            console.error(error);
          }
          await sleep(500);
        }
      }

      setIsLoading(false);
      return players;
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
    setIsLoading(false);
    return [];
  };

  const approve = async (
    signer: providers.JsonRpcSigner,

    assetContract: string,
    _tokenId: number,
    _approveAll: 'all' | null = null
  ) => {
    try {
      const contract = new Contract(
        assetContract,
        [
          `function approve(address operator, uint256 tokenId)`,
          `function setApprovalForAll(address operator, bool value)`,
          `function isApprovedForAll(address owner, address operator) view returns (bool)`,
        ],
        signer
      );
      let isApproved = await contract.isApprovedForAll(
        address,
        config.TOURNAMENT_ADDRESS
      );
      if (!isApproved) {
        toast('Approving...');
        if (_approveAll) {
          await contract
            .setApprovalForAll(config.TOURNAMENT_ADDRESS, true)
            .then(async (tx: any) => {
              const provider =
                (await getProviderOrSigner()) as providers.Web3Provider;
              await provider.waitForTransaction(tx.hash, 1);

              toast.success('Approval was successful');
            })
            .catch((err: any) => {
              setIsLoading(false);
              toast.error(`Error: ${err?.data?.message || err?.message}`);
            });
        } else {
          await contract
            .approve(config.TOURNAMENT_ADDRESS, _tokenId)
            .then(async (tx: any) => {
              const provider =
                (await getProviderOrSigner()) as providers.Web3Provider;
              await provider.waitForTransaction(tx.hash, 1);

              toast.success('Approval was successful');
            })
            .catch((err: any) => {
              setIsLoading(false);
              toast.error(`Error: ${err?.data?.message || err?.message}`);
            });
        }
      }
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
      setIsLoading(false);
    }
  };
  const stake = async (
    _roundId: number,
    _tournamentId: number,
    _assetContract: string,
    _tokenId: number
  ) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            JSON.stringify(TournamentABI),
            signer
          );

          await approve(signer, _assetContract, _tokenId);

          const [_data] = await getSignature([
            {
              tournamentId: _tournamentId,
              assetContract: _assetContract,
              tokenId: _tokenId,
              roundId: _roundId,
              sender: await signer.getAddress(),
              tournamentAddress: config.TOURNAMENT_ADDRESS,
            },
          ]);

          const tx = await TournamentContract.stake(_data);
          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);
          resolve(`Staked successfully at tx: ${tx.hash}`);
          await sleep(2_000);
          setGlobalStateChanged(!globalStateChanged);
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
        }
        setIsLoading(false);
        setGlobalStateChanged(!globalStateChanged);
      }),
      {
        loading: 'Staking...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const stakeAll = async (
    nfts: {
      roundId: number;
      tournamentId: number;
      assetContract: string;
      tokenId: number;
    }[]
  ) => {
    const chunkSize = 150;
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        const signer = (await getProviderOrSigner(
          true
        )) as providers.JsonRpcSigner;

        try {
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            JSON.stringify(TournamentABI),
            signer
          );
          let sender = await signer.getAddress();

          const nft = nfts[0];
          await approve(
            signer,
            nft.assetContract || config.EDITION_ADDRESS,
            nft.tokenId,
            'all'
          );

          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;

          const _nfts = _chunk(nfts, chunkSize);
          let tx;
          for (let i = 0; i < _nfts.length; i++) {
            let payload = _nfts[i];

            tx = await TournamentContract.batchStake(
              await getSignature(
                payload.map((nft: any) => ({
                  tournamentId: nft.tournamentId,
                  assetContract: nft.assetContract,
                  tokenId: nft.tokenId,
                  roundId: nft.roundId,
                  sender,
                  tournamentAddress: config.TOURNAMENT_ADDRESS,
                }))
              )
            );
            await provider.waitForTransaction(tx.hash, 1);
          }
          resolve('Staked Successfully at tx: ' + tx.hash);
          await sleep(2_000);
          setGlobalStateChanged(!globalStateChanged);
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
        }
        setIsLoading(false);
        setGlobalStateChanged(!globalStateChanged);
      }),
      {
        loading:
          nfts.length > chunkSize
            ? `Staking all NFTs in chunks of ${chunkSize}...`
            : 'Staking...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const setRoundAwards = async (
    _roundId: number,
    _tournamentId: number,
    _awards: IAwards[]
  ) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;

          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            JSON.stringify(TournamentABI),
            signer
          );

          const tx = await TournamentContract.setAwards(
            _roundId,
            _tournamentId,
            _awards
          );
          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);

          resolve(
            `Awards for round ${_roundId} have been successfully published at tx: ${tx.hash}`
          );
          await sleep(2_000);
          setGlobalStateChanged(!globalStateChanged);
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
        }
        setIsLoading(false);
      }),
      {
        loading: 'Publishing awards...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const getMyStakedNFTs = async (
    address: string,
    tournamentId: number
  ): Promise<
    {
      id: string;
      player: string;
      tournamentId: number;
      assetContract: string;
      tokenId: number;
      staked: boolean;
    }[]
  > => {
    let players = [];
    try {
      for (let start = 0; start < config.MAX_PLAYERS; start += 1000) {
        for (let i = 0; i < 2; i++) {
          try {
            let query = `
            {
              stakings(first: 1000, where: {tokenId_gte: ${start},tokenId_lte: ${
              start + 1000
            },player_contains_nocase: "${address}",staked: ${
              i % 2 === 0
            },assetContract_contains_nocase: "${
              config.EDITION_ADDRESS
            }",tournamentId: ${tournamentId}},orderBy: tokenId,orderDirection: asc) {
                id
                assetContract
                tournamentId
                player
                tokenId
                staked
                timestamp
              }
            }
            `;

            delete axios.defaults.headers.common['x-auth-token'];

            const { data } = await axios({
              method: 'POST',
              url:
                process.env.REACT_APP_SUBGRAPH_URL ||
                'https://api.thegraph.com/subgraphs/name/johsuadev/capped-range',
              data: {
                query,
              },
            });

            let { stakings } = data?.data;
            stakings && players.push(...stakings);
          } catch (error) {
            console.log(error);
          }
          await sleep(500);
        }
      }
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
    let _players: Record<
      string,
      {
        id: string;
        player: string;
        tournamentId: number;
        assetContract: string;
        tokenId: number;
        staked: boolean;
      }
    > = {};
    players
      .sort((a, b) => b.timestamp - a.timestamp)
      .forEach((player) => {
        if (!_players[player.tokenId]) {
          _players[player.tokenId] = {
            ...player,
            tokenId: Number(player.tokenId),
          };
        }
      });

    return Object.values(_players);
  };

  const getTournaments = async () => {
    try {
      const fallBackProvider =
        (await getProviderOrSigner()) as providers.Web3Provider;

      const TournamentContract = new Contract(
        config.TOURNAMENT_ADDRESS,
        JSON.stringify(TournamentABI),
        fallBackProvider
      );
      const totalTournaments = await TournamentContract.totalTournaments();

      const tournaments = [];
      for (let t = Number(totalTournaments); t > 0; t--) {
        let tournament = await TournamentContract.tournaments(t);
        tournament &&
          Number(tournament.id) !== 0 &&
          tournaments.push(tournament);
      }
      return tournaments.map((tournament: any) => {
        return {
          id: Number(tournament.id),
          name: tournament.name,
          assetContract: tournament.assetContract || config.EDITION_ADDRESS,
          maxRounds: Number(tournament.maxRounds),
          roundCount: Number(tournament.roundCount),
        };
      });
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
    return [];
  };

  const getRound = async () => {
    setIsLoading(true);
    try {
      const fallBackProvider =
        (await getProviderOrSigner()) as providers.Web3Provider;

      const TournamentContract = new Contract(
        config.TOURNAMENT_ADDRESS,
        JSON.stringify(TournamentABI),
        fallBackProvider
      );
      const data = await TournamentContract.getRounds();
      setIsLoading(false);

      return data
        ?.map((round: any) => {
          return {
            roundId: Number(round.roundId),
            tournamentId: Number(round.tournamentId),
            startTime: new Date(Number(round.startTime) * 1000),
            endTime: new Date(Number(round.endTime) * 1000),
            maxPlayers: Number(round.playersCount),
            playerCount: Number(round.playerCount),
          };
        })
        .filter((round: any) => round.roundId > 0);
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
    setIsLoading(false);
    return [];
  };

  const createTournament = async (
    _name: string,
    _assetContract: string,
    _maxRounds: number
  ) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            JSON.stringify(TournamentABI),
            signer
          );
          const tx = await TournamentContract.createTournament(
            _name,
            _assetContract,
            _maxRounds
          );
          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);
          resolve(`Tournament ${_name} has been created successfully`);
          setGlobalStateChanged(!globalStateChanged);

          setIsLoading(false);
          if (tx?.hash) {
            await provider.waitForTransaction(tx.hash, 1);
            navigate('/admin/create');
          } else {
            reject(tx?.data?.message || 'Something went wrong');
          }
          return tx;
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
          setIsLoading(false);
          return error;
        }
      }),
      {
        loading: `Creating tournament: ${_name}...`,
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const createRound = async (
    _roundId: number,
    _tournamentId: number,
    _startTime: number,
    _endTime: number,
    _maxPlayers: number
  ) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;
          const iface = new utils.Interface(JSON.stringify(TournamentABI));
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            iface,
            signer
          );
          let data = [
            iface.encodeFunctionData('createRound', [
              _roundId,
              _tournamentId,
              _startTime,
              _endTime,
              _maxPlayers,
            ]),
          ];

          let tx;
          for (let i = 0; i < data.length; i++) {
            tx = await TournamentContract.multicall([data[i]]);
            await sleep(1000);
          }

          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);
          resolve(`Round ${_roundId} has been created successfully`);
          setIsLoading(false);

          if (tx?.hash) {
            await provider.waitForTransaction(tx.hash, 1);
            setGlobalStateChanged(!globalStateChanged);

            navigate('/admin/rounds');
          } else {
            reject(tx?.data?.message || 'Something went wrong');
          }
          return tx;
        } catch (error: any) {
          console.log(error);
          let msg = recoverError(error);

          reject(msg);
          setIsLoading(false);
          return error;
        }
      }),
      {
        loading: 'Creating Round...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };
  const enrollToRound = async (
    _players: number[],
    _roundId: number,
    _tournamentId: number
  ) => {
    let chunkSize = 50;

    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;
          const iface = new utils.Interface(JSON.stringify(TournamentABI));
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            iface,
            signer
          );

          let playersInRound = await getPlayers(_tournamentId);

          // filter out already enrolled players
          _players = _players.filter(
            (tokenId: number) =>
              !playersInRound.some(
                (player) => Number(player.tokenId) === tokenId
              )
          );
          const players = _chunk(_players, chunkSize);
          let tx;
          for (let i = 0; i < players.length; i++) {
            tx = await TournamentContract.enrollToRound(
              players[i],
              _tournamentId,
              _roundId
            );
          }

          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);
          resolve(`Players have been successfully enrolled`);
          setIsLoading(false);

          if (tx?.hash) {
            await provider.waitForTransaction(tx.hash, 1);
            setGlobalStateChanged(!globalStateChanged);

            navigate('/admin/rounds');
          } else {
            reject(tx?.data?.message || 'Something went wrong');
          }
          return tx;
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
          setIsLoading(false);
          return error;
        }
      }),
      {
        loading:
          _players.length > chunkSize
            ? `Enrolling players to round ${_roundId} in chunks of ${chunkSize}`
            : `Enrolling players to round ${_roundId}...`,
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };
  const updateRound = async (
    _roundId: number,
    _tournamentId: number,
    _startTime: number,
    _endTime: number,
    _maxPlayers: number
  ) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);
        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            JSON.stringify(TournamentABI),
            signer
          );
          const tx = await TournamentContract.updateRound(
            _roundId,
            _tournamentId,
            _startTime,
            _endTime,
            _maxPlayers
          );
          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);
          resolve(`Round ${_roundId} has been updated successfully`);
          setIsLoading(false);
          setGlobalStateChanged(!globalStateChanged);

          if (tx?.hash) {
            await provider.waitForTransaction(tx.hash, 1);
            navigate('/admin/rounds');
          } else {
            reject(tx?.data?.message || 'Something went wrong');
          }
          return tx;
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
          setIsLoading(false);
          return error;
        }
      }),
      {
        loading: 'Updating Round...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const unstake = async (
    tournamentId: number,
    assetContract: string,
    tokenId: number
  ) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);

        let _data: string[] = [];
        // ensure the nft is not in an active round
        try {
          const { data } = await config.axios({
            method: 'POST',
            url: '/api/game/unstaking',
            data: JSON.stringify({
              payload: [
                {
                  tournamentId,
                  assetContract,
                  tokenId,
                },
              ],
            }),
          });

          const { error, success, msg, message, data: _signatures } = data;

          if (!success || error) {
            reject(error || msg || message);

            setIsLoading(false);
            return;
          }
          _data = _signatures;
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
          setIsLoading(false);
          return error;
        }
        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            JSON.stringify(TournamentABI),
            signer
          );
          const tx = await TournamentContract.unstake(_data[0]);

          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);
          resolve('Unstaked Successfully at tx: ' + tx.hash);
          setGlobalStateChanged(!globalStateChanged);
        } catch (error: any) {
          let msg = recoverError(error);
          reject(msg);
        }
        setIsLoading(false);
        setGlobalStateChanged(!globalStateChanged);
      }),
      {
        loading: 'Unstaking...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const unStakeAll = async (
    nfts: {
      tournamentId: number;
      assetContract: string;
      tokenId: number;
    }[]
  ) => {
    const chunkSize = 75;
    toast.promise(
      new Promise(async (resolve, reject) => {
        setIsLoading(true);

        try {
          const signer = (await getProviderOrSigner(
            true
          )) as providers.JsonRpcSigner;
          const TournamentContract = new Contract(
            config.TOURNAMENT_ADDRESS,
            JSON.stringify(TournamentABI),
            signer
          );

          const _nfts = _chunk(nfts, chunkSize);
          let tx;
          for (let i = 0; i < _nfts.length; i++) {
            try {
              const { data } = await config.axios({
                method: 'POST',
                url: '/api/game/unstaking',
                data: JSON.stringify({
                  payload: _nfts[i],
                }),
              });

              const { error, success, msg, message, data: _signatures } = data;

              if (!success || error) {
                reject(error || msg || message);

                setIsLoading(false);
                return;
              }
              tx = await TournamentContract.batchUnstake(_signatures);
            } catch (error: any) {
              let msg = recoverError(error);

              reject(msg);
              setIsLoading(false);
              return error;
            }
          }

          const provider =
            (await getProviderOrSigner()) as providers.Web3Provider;
          await provider.waitForTransaction(tx.hash, 1);
          resolve('Unstaked Successfully at tx: ' + tx.hash);
          await sleep(2_000);
          setGlobalStateChanged(!globalStateChanged);
        } catch (error: any) {
          let msg = recoverError(error);

          reject(msg);
        }
        setIsLoading(false);
        setGlobalStateChanged(!globalStateChanged);
      }),
      {
        loading:
          nfts.length > chunkSize
            ? `Unstaking all NFTs in chunks of ${chunkSize}...`
            : 'Unstaking...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const getOwnedNFTs = async (address?: string): Promise<number[]> => {
    try {
      const signer = (await getProviderOrSigner(
        true
      )) as providers.JsonRpcSigner;

      if (!address) {
        address = await signer.getAddress();
      }
      const contract = new Contract(
        config.EDITION_ADDRESS,
        [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'owner',
                type: 'address',
              },
            ],
            name: 'tokensOfOwner',
            outputs: [
              {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
        ],
        signer
      );

      let nfts = await contract.tokensOfOwner(address);
      nfts = nfts.map((nft: any) => nft.toNumber());
      return nfts;
    } catch (error: any) {
      let msg = recoverError(error);

      throw new Error(msg);
    }
  };

  const mintToSelf = async (_quantity: number) => {
    try {
      const signer = (await getProviderOrSigner(
        true
      )) as providers.JsonRpcSigner;

      const contract = new Contract(
        config.EDITION_ADDRESS,
        [
          `function mintTo(
            address to,
            uint256 _quantity
        ) external returns (uint256)`,
        ],
        signer
      );

      // const buffer = await new Response(file).arrayBuffer();

      // const uri = await _uploadToIPFS(buffer, signer);

      // const _metadatas = await Promise.all(
      //   metadatas.map((metadata: any) => {
      //     return _uploadToIPFS(
      //       JSON.stringify({
      //         ...metadata,
      //         image: `ipfs/${uri}`,
      //       }),
      //       signer
      //     );
      //   })
      // );

      return await contract.mintTo(await signer.getAddress(), _quantity);
    } catch (error) {
      let msg = recoverError(error);

      throw new Error(msg);
    }
  };

  // const _uploadToIPFS = async (
  //   _data: ArrayBuffer | string,
  //   signer: providers.JsonRpcSigner
  // ) => {
  //   try {
  //     const ipfs = create({
  //       host: 'ipfs.infura.io',
  //       port: 5001,
  //       protocol: 'https',
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //       },
  //     });

  //     const result = await ipfs.add(_data);
  //     console.log(result);
  //     return result.path;
  //   } catch (error: any) {
  //     throw new Error('Error: ' + error?.message || error);
  //   }
  // };

  const fetchNFTSWithMetadata = async () => {
    try {
      delete axios.defaults.headers.common['x-auth-token'];
      const { data } = await axios.get(config.REACT_PINATA_API_URL);

      const _nfts: Record<
        number,
        {
          tokenId: number;
          name: string;
          image: string;
          tier: string;
        }
      > = {};

      data.forEach((nft: any) => {
        const { name, image, attributes, properties } = nft;

        const _attributes = attributes || properties;

        const attribute = (_attributes as any)?.find(
          (attribute: { value: string; trait_type: string }) =>
            ['rarity', 'tier'].includes(attribute.trait_type)
        );
        const attribute2 = (_attributes as any)?.find(
          (attribute: { value: string; trait_type: string }) =>
            ['id'].includes(attribute.trait_type)
        );
        const tokenId = Number(attribute2.value);
        _nfts[tokenId] = {
          tokenId,
          name,
          image,
          tier: attribute.value,
        };
      });

      return _nfts;
    } catch (error: any) {
      console.error('Error: ' + error?.message || error);
      return null;
    }
  };
  return {
    hasAdminRole,
    getPlayers,
    stake,
    createRound,
    setRoundAwards,
    unstake,
    getRound,
    getMyStakedNFTs,
    getTournaments,
    stakeAll,
    unStakeAll,
    createTournament,
    updateRound,
    grantAdminRole,
    getOwnedNFTs,
    mintToSelf,
    enrollToRound,
    fetchNFTSWithMetadata,
  };
};
