import TwitterIcon from '../assets/icons/twitter.png';
import DiscordIcon from '../assets/icons/discord.png';
import styled from 'styled-components';

export default function Footer() {
  return (
    <StyledFooter>
      <div className="footer">
        <p>© 2022 Capped Range. All rights reserved.</p>
        <div className="footerIcons">
          <a
            href="https://twitter.com/cappedrange"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TwitterIcon} alt="" />
          </a>
          <a
            href="https://discord.gg/4JqkTg4VE8"
            target="_blank"
            rel="noreferrer"
          >
            <img src={DiscordIcon} alt="" />
          </a>
        </div>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  @font-face {
    font-family: 'Northead';
    src: url('/fonts/northead.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.ttf') format('truetype');
  }
  .footer {
    width: 100%;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    background-color: #1c1f22;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
    }
    .footerIcons {
      display: flex;
      align-items: center;
      a {
        margin-right: 1rem;
      }
    }
  }
  p {
    font-family: 'Inter' !important;
    font-weight: 300;
    line-height: 1.4;
    @media (max-width: 768px) {
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
    a {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
`;
