import { providers } from 'ethers';
import { useRef, useState } from 'react';
import { GlobalContext } from './globalContext';
import Web3Modal from 'web3modal';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
// import WalletConnect from '@walletconnect/web3-provider';

import { toast } from 'react-hot-toast';
import { config } from '../../config';
import { humanizeAddress } from '../../helpers';
import { colors } from '../../styles';

export const GlobalProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const web3ModalRef = useRef<Web3Modal>();

  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [globalStateChanged, setGlobalStateChanged] = useState(false);

  const handleConnectWallet = async () => {
    try {
      web3ModalRef.current = new Web3Modal({
        network: 'mumbai',
        providerOptions: getProviderOptions(),
        theme: {
          background: colors.background,
          main: colors.secondary,
          secondary: colors.primary,
          border: colors.background,
          hover: colors.tertiary,
        },
      });
      await web3ModalRef.current.connect();

      const signer = (await getProviderOrSigner(
        true
      )) as providers.JsonRpcSigner;
      let _address = await signer.getAddress();
      setAddress(_address);
      setUserName(humanizeAddress(_address));
      subscribeProvider(signer);
    } catch (err: any) {
      toast.error('Failed to connect wallet');
    }
  };
  const getProviderOptions = () => {
    const infuraId = config.REACT_APP_INFURA_ID;
    const providerOptions = {
      // walletconnect: {
      //   package: WalletConnect,
      //   options: {
      //     infuraId,
      //   },
      // },
      // torus: {
      //   package: Torus,
      // },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: config.REACT_APP_NAME,
          infuraId,
        },
      },
    };
    return providerOptions;
  };

  const subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on('close', () => resetApp());
    provider.on('accountsChanged', async (accounts: string[]) => {
      setAddress(accounts[0]);
    });
    provider.on('chainChanged', async (chainId: number) => {
      if (!config.SUPPORTED_CHAID_IDS.includes(chainId)) {
        toast.error('Change network to Mumbai testnet');
      }
    });

    provider.on('networkChanged', async (networkId: number) => {
      if (!config.SUPPORTED_CHAID_IDS.includes(networkId)) {
        toast.error('Change network to Mumbai testnet');
      }
    });
  };

  const resetApp = async () => {
    // if (Web3Modal.currentProvider && web3ModalRef.currentProvider.close) {
    //   await web3.currentProvider.close();
    // }
    await (web3ModalRef.current as any).clearCachedProvider();
    setUserName(null);
    setAddress(null);
  };

  const getProviderOrSigner = async (needSigner = false) => {
    const provider = new providers.Web3Provider(window.ethereum);

    const { chainId } = await provider.getNetwork();
    if (!config.SUPPORTED_CHAID_IDS.includes(chainId)) {
      toast.error('Change network to Mumbai testnet');
      return null;
    }

    if (needSigner) {
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      if (signer) {
        return signer;
      }
      toast.error('You need to allow MetaMask.');
      return null;
    }

    return provider;
  };

  return (
    <GlobalContext.Provider
      value={{
        isLoading,
        setIsLoading,
        userName,
        setUserName,
        address,
        setAddress,
        getProviderOrSigner,
        handleConnectWallet,
        globalStateChanged,
        setGlobalStateChanged,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
