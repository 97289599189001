import styled, { keyframes } from 'styled-components';

export default function Loader() {
  return (
    <StyledLoader>
      <img src="/logo.png" alt="" />
    </StyledLoader>
  );
}

const pulse = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const StyledLoader = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto;
  padding: 4rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  animation: ${pulse} 1s infinite;
`;
