import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import AttributesSelector from '../../components/Attributes';
import { ContractContext, GlobalContext } from '../../context';

window.Buffer = window.Buffer || require('buffer').Buffer;

interface StyledProps {
  fileName: string;
  isDragging: boolean;
}

export default function Mint() {
  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const { mintToSelf } = useContext(ContractContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('Choose File');
  const [imgData, setImgData] = useState<string>('/0');
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [supply, setSupply] = useState<number>(1);

  const handleMint = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          supply > 0 && (await mintToSelf(supply));

          resolve(`Successfully minted ${supply} nfts!`);
          setIsLoading(false);
        } catch (error: any) {
          reject(`Error: ${error?.data?.message || error?.message || error}`);
          setIsLoading(false);
        }
      }),
      {
        loading: 'Minting...',
        success: (msg: any) => `${String(msg)}`,
        error: (msg: any) => `${String(msg)}`,
      }
    );
  };

  const addPreview = (file: File) => {
    const reader = new FileReader();
    if (file) {
      reader.addEventListener('load', () => {
        setImgData(String(reader.result));
      });
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (fileRef.current) {
      fileRef.current.files = e.dataTransfer.files;
      setFileName(
        fileRef.current.files && fileRef.current.files.length
          ? fileRef.current.files[0].name
          : 'Choose File'
      );
      fileRef.current.files?.length && addPreview(fileRef.current.files[0]);
    }
  };

  useEffect(() => {
    document.addEventListener('dragover', (e) => e.preventDefault());
    return () => {
      document.removeEventListener('dragover', (e) => e.preventDefault());
    };
  }, []);

  return (
    <Styledmint fileName={fileName} isDragging={isDragging}>
      <form
        onSubmit={(e) => handleMint(e)}
        style={{
          opacity: isLoading ? 0.5 : 1,
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
      >
        {/* <label>
          <h4>
            Image File<span>*</span>
          </h4>
          <input
            type="file"
            ref={fileRef}
            onChange={(e) => {
              setFileName(
                e.target.files!.length > 0
                  ? e.target.files![0].name
                  : 'Choose File'
              );
              addPreview(e.target.files![0]);
            }}
            required
          />
          <div
            className="preview"
            onDrop={(e) => {
              handleDrop(e);
              setIsDragging(false);
            }}
            onDragOver={() => setIsDragging(true)}
            onDragLeave={() => setIsDragging(false)}
          >
            <img src={imgData} alt={' '} height={300} width={300} />
          </div>
        </label> */}
        {/* <label>
          <h4>
            Name<span>*</span>
          </h4>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label> */}
        {/* <label>
          <h4>
            Description<span>*</span>
          </h4>
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            required
          />
        </label> */}
        <label>
          <h4>
            Quantity<span>*</span>
          </h4>
          <input
            type="number"
            placeholder="Supply"
            value={supply}
            step={1}
            min={1}
            max={500}
            onChange={(e) => setSupply(Number(e.target.value))}
            required
          />
        </label>
        {/* <AttributesSelector
          attributes={attributes as any}
          setAttributes={setAttributes}
        /> */}
        <button type="submit" className="button" disabled={supply < 1}>
          Mint {supply} NFTs
        </button>
      </form>
    </Styledmint>
  );
}

const Styledmint = styled.div<StyledProps>`
  h1 {
    font-size: 1.5rem;
  }
  form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    label {
      display: block;
      h4 {
        font-size: 1.2rem;
        font-weight: 500;
        span {
          color: ${({ theme }) => theme.primary};
          margin-left: 0.125rem;
        }
      }
    }
    button {
      padding: 1rem 1.5rem;
    }
    select,
    input,
    textarea {
      margin-top: 0.5rem;
    }
    input {
      &[type='file'] {
        border-radius: 0;
        width: auto;
        border: none;
        padding: 0;
        color: transparent;
        background: transparent;
        &::-webkit-file-upload-button {
          visibility: hidden;
        }
        &::before {
          border-radius: 0.5rem;
          display: inline-block;
          content: ' ${(props) => props.fileName}';
          border: none;
          background-color: ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.background};
          padding: 0.5rem 1rem;
          font-size: 1rem;
          line-height: 1.4;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            background-color: ${({ theme }) => theme.primary};
            color: ${({ theme }) => theme.secondary};
          }
        }
      }
    }
    input,
    textarea {
      width: 100%;
      display: inline-block;
      background: #25262a;
      border: 1px solid #4c4f58;
      padding: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      color: #fff;
    }
    textarea {
      resize: none;
    }
    .preview {
      position: relative;
      margin-top: 1rem;
      border: ${(props) =>
        props.isDragging
          ? `1px solid ${props.theme.primary}`
          : `1px solid ${props.theme.secondary}80`};
      overflow: hidden;
      display: block;
      font-size: 0.9rem;
      width: 300px;
      height: 300px;
      cursor: pointer;
      transition: all 0.3s ease;
      img {
        object-fit: contain;
        transition: all 0.3s ease;
        opacity: ${(props) => (props.fileName === 'Choose File' ? '0' : '1')};
      }
      &::after {
        content: 'Drop your file here';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${(props) =>
          props.isDragging
            ? props.theme.primary
            : `${props.theme.secondary}80`};
        font-size: 0.9rem;
        font-weight: 600;
        z-index: -1;
      }
    }
  }
`;
