import styled from 'styled-components';
import table from '../../assets/board/table.png';
import tableMobile from '../../assets/board/tableMobile.png';
import first_row_Cards from '../../assets/board/first_row_Cards.png';
import second_row_Cards from '../../assets/board/second_row_Cards.png';
import third_row_cards from '../../assets/board/third_row_cards.png';
import card1 from '../../assets/board/card1.png';
import card2 from '../../assets/board/card2.png';
import arrow_1 from '../../assets/board/Arrow_1.png';
import arrow_2 from '../../assets/board/Arrow_2.png';

export default function Board() {
  return (
    <StyledBoard>
      <section className="table">
        <div className="container">
          <div className="cardWrapper">
            {' '}
            <div className="card top">
              <div>
                <h2>OPPONENT</h2>
                <p> 0x703B...f2eC</p>
              </div>
              <div>
                <p>100% Range:</p>
                <p>In this example, player 1’s hand is</p>
                <p>randomly chosen from 100% of all </p>
                <p>possible hands.</p>
              </div>
            </div>
            <div>
              <img className="arrow1" src={arrow_1} alt="" />
              <img className="cardTop" src={card1} alt="" />
            </div>
          </div>
        </div>
        <div className="tableWrapper">
          <div>
            <div>
              <img className="tableImg" src={table} alt="" />
            </div>
            <div className="table">
              <div>
                {' '}
                <img
                  className="first_row_Cards"
                  src={first_row_Cards}
                  alt=""
                  width={329}
                  height={205}
                />
              </div>
              <div>
                {' '}
                <img
                  className="second_row_Cards"
                  src={second_row_Cards}
                  alt=""
                  width={851}
                  height={197}
                />
              </div>
              <div>
                <img
                  className="third_row_cards"
                  src={third_row_cards}
                  alt=""
                  width={324}
                  height={203}
                />
              </div>
            </div>
          </div>
          <div className="card2">
            <p> {'0x887e...a5bE wins with a Full House, 9’s over 7’s'}</p>
          </div>
        </div>
        <div className="container bottom">
          <div className="cardWrapper">
            {' '}
            <div className="card">
              <div>
                <h2>YOU</h2>
                <p> 0x887e...a5bE</p>
              </div>
              <div>
                <p>
                  20% Range: <br /> In this example, player 2’s hand is randomly
                  chosen from the top 20% of all possible hands.
                </p>
              </div>
            </div>
            <div className="bottom-right">
              <img className="arrow2" src={arrow_2} alt="" width={290} />
              <img className="cardBottom" src={card2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <div className="tableSmallDevice">
        <img src={tableMobile} alt="" />
      </div>
    </StyledBoard>
  );
}

const StyledBoard = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @font-face {
    font-family: 'Northead';
    src: url('/fonts/northead.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.otf') format('truetype');
  }
  .table {
    @media (max-width: 767px) {
      /* background remove */

      display: none;
    }
    .container {
      display: flex;
      justify-content: center;

      .cardWrapper {
        position: relative;
        display: flex;
        gap: 3rem;
        .top {
          margin-left: 1rem;
        }
        .arrow1 {
          position: absolute;
          right: 19rem;
          top: 17rem;
          @media (max-width: 1200px) {
            right: 14rem;
            top: 13rem;
          }
          @media (max-width: 992px) {
            right: 11rem;
            top: 10rem;
            width: 35%;
          }
        }
        .arrow2 {
          position: absolute;
          right: 19rem;
          top: -15rem;
          @media (max-width: 1200px) {
            right: 13rem;
            top: -16rem;
          }
          @media (max-width: 992px) {
            right: 11rem;
            width: 35%;
            top: -14rem;
          }
        }

        @media (max-width: 1200px) {
          width: 100%;
          margin-bottom: 4rem;
          .cardTop {
            width: 100%;
          }
          .cardBottom {
            width: 100%;
          }
        }
        @media (max-width: 992px) {
          .cardTop {
            width: 110%;
          }
          .cardBottom {
            width: 110%;
          }
        }
      }
      .card {
        width: 850px;
        height: 167px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        background-color: rgba(51, 51, 51, 0.2);
        background-blend-mode: overlay, normal;
        border: 2px solid #383838;
        backdrop-filter: blur(33.193px);
        border-radius: 3px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @media (max-width: 1200px) {
          gap: 4rem;
          width: 100%;
        }
        @media (max-width: 992px) {
          gap: 2rem;
          width: 100%;
        }
        h2 {
          font-size: 74px;
          text-align: center;
          font-weight: 400;
          font-family: 'Northead' !important;
          line-height: 50px;
          text-transform: uppercase;
          letter-spacing: -2%;
          color: #ffc728;
          @media (max-width: 992px) {
            font-size: 50px;
          }
        }
        div:nth-child(1) {
          width: 201px;
          height: 108px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          text-align: center;
          h2 {
            text-align: center;
            width: 100%;
          }
          p {
            font-family: 'Inter' !important;
            font-weight: 400;
            font-size: 28px;
            line-height: 33px;
            letter-spacing: -2%;
          }
        }
        div:nth-child(2) {
          width: 261px;
          height: 108px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          p {
            font-family: 'Inter' !important;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            letter-spacing: -2%;
            text-align: center;
            @media (max-width: 992px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .tableWrapper {
      margin-top: -10rem;
      display: flex;
      justify-content: center;
      gap: 9rem;
      align-items: center;
      .second_row_Cards {
        margin-left: 1rem;
      }

      @media (max-width: 1200px) {
        gap: 2rem;
        .first_row_Cards {
          width: 80%;
          height: 80%;
        }
        .second_row_Cards {
          width: 90%;
          height: 90%;
          margin-left: 1rem;
        }
        .third_row_cards {
          width: 80%;
          height: 80%;
        }
      }
      @media (max-width: 992px) {
        .first_row_Cards {
          width: 60%;
          height: 80%;
        }
        .second_row_Cards {
          width: 70%;
          height: 90%;
          margin-left: 2rem;
        }
        .third_row_cards {
          width: 60%;
          height: 80%;
        }
      }
      .tableImg {
        position: absolute;
        z-index: -1;
        @media (max-width: 1200px) {
          width: 80%;
          gap: 1rem;
        }
      }
      .table {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
        @media (max-width: 1200px) {
          gap: 1rem;
        }
        @media (max-width: 992px) {
          margin-top: 2.5rem;
        }
      }
      .card2 {
        z-index: 2;
        width: 310px;
        height: 210px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        background-blend-mode: overlay, normal;
        border: 2.68328px solid #3d3d3d;
        backdrop-filter: blur(39.9286px);
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
        border-radius: 1.78886px;
        @media (max-width: 1200px) {
          width: 25%;
        }
        @media (max-width: 992px) {
          margin-top: 2.5rem;
          width: 25%;
          height: 40%;
        }

        p {
          width: 272px;
          height: 105px;
          font-family: 'Inter' !important;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-weight: 400;
          font-size: 20px;
          line-height: 40px;
          text-align: center;
          letter-spacing: -0.02em;
          @media (max-width: 992px) {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }
    .bottom {
      margin-top: 8rem;
      .bottom-right {
        margin-top: -15rem;
        @media (max-width: 1200px) {
          margin-top: -10rem;
        }
      }
    }
  }
  .tableSmallDevice {
    display: none;
    @media (max-width: 768px) {
      display: block;

      img {
        width: 100%;
      }
    }
  }
`;
