import profileImg from '../assets/profileInfoIcons/profile.png';
import totalNftsImg from '../assets/profileInfoIcons/totalNfts.png';
import totalRoundsImg from '../assets/profileInfoIcons/totalRounds.png';
import styled from 'styled-components';

interface InfoCardProps {
  upperText: string | number;
  lowerText: string | number;
  icon: string;
  isActive?: string;
}

interface ProfileInfoProps {
  username?: string;
  isActive: boolean;
  numNfts: number;
  totalRounds: number;
  activeNfts: number;
  isLoading: boolean;
}

const InfoCard = (props: InfoCardProps) => {
  return (
    <StyledProfileInfo>
      <img src={props.icon} alt="" />
      <div>
        <h4>{props.upperText}</h4>
        <p
          style={{
            color:
              props.isActive &&
              (props.isActive === 'yes'
                ? '#00ff00'
                : props.isActive === 'idk'
                ? '#ffffff'
                : '#ff0000'),
          }}
        >
          {props.lowerText}
        </p>
      </div>
    </StyledProfileInfo>
  );
};

function ProfileInfo(props: ProfileInfoProps) {
  return (
    <InfoCards>
      <InfoCard
        icon={profileImg}
        upperText={props.username ?? 'username'}
        lowerText={
          props.isActive
            ? 'Active'
            : props.isLoading || props.numNfts === 0
            ? '_ _ _'
            : 'Eliminated'
        }
        isActive={
          props.isActive
            ? 'yes'
            : props.isLoading || props.numNfts === 0
            ? 'idk'
            : 'no'
        }
      />
      <InfoCard
        icon={totalNftsImg}
        upperText="Active / Total NFTs"
        lowerText={`${props.numNfts === 0 ? '_ _ ' : props.activeNfts} / ${
          props.numNfts === 0 ? '_ _ ' : props.numNfts
        }`}
      />
      <InfoCard
        icon={totalRoundsImg}
        upperText="Total Rounds"
        lowerText={
          props.totalRounds === 0 ? '_ _ _' : props.totalRounds ?? '_ _ _'
        }
      />
      {/* <InfoCard
        icon={totalBountiesImg}
        upperText="Total Bounties"
        lowerText={totalBounties ?? 0}
      /> */}
    </InfoCards>
  );
}

const StyledProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.quaternary};
  box-shadow: 0px 4px 56px 18px rgba(103, 103, 103, 0.11);
  border-radius: 1rem;
  padding: 1.2rem 1rem;
  img {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
    object-position: center;
  }
  h4 {
    color: ${({ theme }) => theme.quinary};
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  p {
    color: ${({ theme }) => theme.secondary};
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

const InfoCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  /* @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  } */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default ProfileInfo;
