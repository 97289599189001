import { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../context';
import { IRound } from '../types';
import { humanizeAddress } from '../helpers';

function PlayerSeat({
  player,
}: {
  player: IRound['player1'] | IRound['player2'];
}) {
  const { address } = useContext(GlobalContext);

  let label =
    player.address.toLowerCase() === address?.toLowerCase()
      ? 'YOU'
      : 'OPPONENT';

  return (
    <StyledPlayerSeat label={label}>
      {
        <div className="player-card-text-box">
          <span className={label === 'YOU' ? 'green' : 'yellow'}>{label}</span>
          <span className="white">{humanizeAddress(player.address)}</span>
        </div>
      }
    </StyledPlayerSeat>
  );
}

const StyledPlayerSeat = styled.div<{ label: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  margin-top: 0.5rem;
  & > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -1.5rem;
    position: relative;
    z-index: 2;
  }
  .player-card-text-box {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    border: 2px solid
      ${({ label }) => (label === 'YOU' ? '#FFC728' : '#FFC728')};
    box-sizing: border-box;
    box-shadow: inset 0px 0px 15px #3c3d3f;
    background: #4c4f58;
    width: fit-content;
    padding: 1rem;
    width: 10rem;
    /* height: 6rem; */
    text-align: center;
  }
`;

export default PlayerSeat;
