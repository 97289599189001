const padTime = (time: number) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const displayRemainingTime = (seconds: number) => {
  let days = '0';
  let hours = '0';
  let mins = '0';
  let secs = '0';

  if (seconds > 0) {
    days = padTime(Math.floor(seconds / (60 * 60 * 24)));
    seconds %= 60 * 60 * 24;

    hours = padTime(Math.floor(seconds / (60 * 60)));
    seconds %= 60 * 60;

    mins = padTime(Math.floor(seconds / 60));
    seconds %= 60;

    secs = padTime(seconds);
  }

  return {
    days,
    hours,
    mins,
    secs,
  };
};
