import { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import ProfileInfo from '../components/profileInfo';
import NftList from '../components/nftList';
import backgroundImg from '../assets/backgrounds/background.png';
import NextRoundTimer from '../components/nextRoundTimer';
import styled from 'styled-components';
import { ContractContext, GlobalContext, PokerContext } from '../context';
import { config } from '../config';
import { toast } from 'react-hot-toast';
import { IResult } from '../types';
import { groupBy } from '../helpers';

export default function Lobby() {
  const { userName, address, setIsLoading, globalStateChanged, isLoading } =
    useContext(GlobalContext);
  const {
    getMyStakedNFTs,
    setUpcomingRounds,
    upcomingRounds,
    stakeAll,
    unStakeAll,
    currentRound,
    setCurrentRound,
    currentTournament,
    setCurrentTournament,
    setTournaments,
    getTournaments,
    getOwnedNFTs,
    metadatas,
  } = useContext(ContractContext);
  const { getRounds, getResults } = useContext(PokerContext);

  const [myNFTs, setNfts] = useState<
    Array<{
      tokenId: number;
      image: string;
      isActive: boolean;
      roundId: number;
      name: string;
      tournamentId: number;
      tier: string;
      staked: boolean;
      assetContract: string;
      resultsReady: boolean;
      player: string;
      id: string;
      roundIsOver: boolean;
    }>
  >([]);
  const [nftsWithRoundInfo, setNftsWithRoundInfo] = useState<
    Array<{
      tokenId: number;
      image: string;
      isActive: boolean;
      roundId: number;
      name: string;
      tournamentId: number;
      tier: string;
      staked: boolean;
      assetContract: string;
      resultsReady: boolean;
      player: string;
      id: string;
      roundIsOver: boolean;
    }>
  >([]);

  const [paginate, setPaginate] = useState(10);
  const [isActive, setIsActive] = useState(true);

  const [totalRounds, setTotalRounds] = useState(0);

  const [countdown, setCounter] = useState(0);

  const [results, setResults] = useState<IResult[]>([]);

  useEffect(() => {
    getTournaments()
      .then((tournaments) => {
        if (tournaments.length > 0) {
          // sort upcoming rounds by start time
          tournaments = tournaments.sort((a, b) => a.id - b.id);

          setTournaments(tournaments);

          // get the most recent tournament
          let tournament = tournaments[tournaments.length - 1];

          getRounds(tournament)
            .then((rounds) => {
              setCurrentTournament({
                ...tournament,
                roundCount: Math.max(...rounds.map((r) => r.roundId).concat(0)),
              });

              if (rounds.length > 0) {
                // get upcoming rounds
                let upcomingRounds = rounds.filter(
                  (round) => round.startTime > Date.now()
                );

                // sort upcoming rounds by start time
                upcomingRounds = upcomingRounds.sort(
                  (a, b) => a.startTime - b.startTime
                );

                setUpcomingRounds(upcomingRounds);

                // get current rounds
                let ongoingRounds = rounds.filter(
                  (round) =>
                    Date.now() > round.startTime && Date.now() < round.endTime
                );

                setCurrentRound(
                  ongoingRounds.length > 0 ? ongoingRounds[0] : null
                );
              }
            })
            .catch((err) => {
              setCurrentRound(null);
              setUpcomingRounds([]);
            });
        }
      })
      .catch((_err) => {
        toast('Error fetching tournaments');
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1000), 1000);
    }

    if (countdown < 1) {
      if (currentTournament) {
        getRounds(currentTournament)
          .then((rounds) => {
            if (rounds.length > 0) {
              // get upcoming rounds
              let upcomingRounds = rounds.filter(
                (round) => round.startTime > Date.now()
              );

              // sort upcoming rounds by start time
              upcomingRounds = upcomingRounds.sort(
                (a, b) => a.startTime - b.startTime
              );

              setUpcomingRounds(upcomingRounds);

              // get current rounds
              let ongoingRounds = rounds.filter(
                (round) =>
                  Date.now() > round.startTime && Date.now() < round.endTime
              );

              setCurrentRound(
                ongoingRounds.length > 0 ? ongoingRounds[0] : null
              );
            }
          })
          .catch((err) => {
            setCurrentRound(null);
            setUpcomingRounds([]);
          });
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, currentTournament]);

  useEffect(() => {
    currentTournament?.id &&
      getResults()
        .then((results) => {
          setResults(
            Object.values(results).map((result, i) => {
              return {
                ...result,
                page: Math.ceil((i + 1) / paginate),
              };
            })
          );
        })
        .catch((err) => {
          toast.error(err);
          setResults([]);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStateChanged, currentTournament]);

  useEffect(() => {
    const getMyNFTs = async (setLoading = true) => {
      if (
        address &&
        metadatas &&
        Object.keys(metadatas).length > 0
        // currentTournament?.id
      ) {
        setIsLoading(setLoading);

        // get nfts not in a round
        let nfts: Map<
          number,
          {
            id: string;
            tokenId: number;
            image: string;
            isActive: boolean;
            roundId: number;
            name: string;
            tournamentId: number;
            tier: string;
            staked: boolean;
            assetContract: string;
            currentRound: number;
            player: string;
            roundIsOver: boolean;
            resultsReady: boolean;
          }
        > = new Map();

        const owned = await getOwnedNFTs(address);

        owned.forEach((tokenId: number) => {
          const metadata = metadatas[tokenId];

          metadata &&
            nfts.set(tokenId, {
              ...metadata,
              id: '',
              isActive: true,
              currentRound: 0,
              roundId: 0,
              staked: false,
              player: address,
              assetContract: config.EDITION_ADDRESS,
              tournamentId:
                currentTournament?.id || currentRound?.tournamentId || 0,
              roundIsOver: false,
              resultsReady: false,
            });
        });

        if (currentTournament?.id) {
          let staked = await getMyStakedNFTs(address, currentTournament?.id);

          staked.forEach((nft) => {
            let metadata = metadatas[Number(nft.tokenId)];

            if (
              nft?.assetContract?.toLowerCase() ===
              config.EDITION_ADDRESS.toLowerCase()
            ) {
              nfts.set(nft.tokenId, {
                ...metadata,
                ...nft,
                roundId: 1,
                currentRound: 1,
                isActive: true,
                resultsReady: false,
                roundIsOver: false,
              });
            }
          });
        }
        setNfts([...nfts.values()]);
        setIsLoading(false);
      }
    };

    getMyNFTs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, metadatas, currentTournament]);

  useEffect(() => {
    let _results = results.filter(
      (r) => r.tournamentId === currentTournament?.id
    );
    if (_results.length > 0 && myNFTs.length > 0) {
      let __results = groupBy(
        _results.sort((a, b) => b.roundId - a.roundId),
        'tokenId'
      );

      const isRoundOver = (roundId: number) => {
        // round is over when the current round id > than the given round id
        // when current round endtime is in the
        return currentRound
          ? Number(currentRound.roundId) > Number(roundId)
          : true;
      };

      let _totalRounds = 0;

      let _nfts = myNFTs.map((nft) => {
        // if (nft.staked) {
        const participated = __results[nft.tokenId] || [];

        let currentHighestRound = participated[0];

        let roundId =
          currentHighestRound?.roundId || currentRound?.roundId || 0;

        _totalRounds = Math.max(roundId, _totalRounds);

        return {
          ...nft,
          id: currentHighestRound?.id || '',
          roundId,
          currentRound: roundId,
          isActive: !currentHighestRound || currentHighestRound?.nftWon,
          resultsReady: currentHighestRound?.id ? true : false,
          roundIsOver: isRoundOver(roundId),
        };
        // }
      });

      setNftsWithRoundInfo(
        _nfts.sort(
          (a, b) =>
            b.roundId - a.roundId ||
            a.tokenId - b.tokenId ||
            (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1)
        )
      );
      setIsActive(_nfts.some((nft) => nft.isActive));
      setTotalRounds(_totalRounds);
    } else {
      setNftsWithRoundInfo(
        myNFTs.sort(
          (a, b) =>
            b.roundId - a.roundId ||
            a.tokenId - b.tokenId ||
            (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1)
        )
      );
      setIsActive(myNFTs.some((nft) => nft.isActive));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, myNFTs, currentTournament]);

  const handleOnStakeAll = async () => {
    const nextRound = upcomingRounds[0];
    if (nextRound) {
      let _unStaked = nftsWithRoundInfo.filter((nft) => !nft.staked);
      let unStaked: {
        tokenId: number;
        assetContract: string;
        roundId: number;
        tournamentId: number;
      }[] = _unStaked.map((nft) => {
        return {
          tokenId: nft.tokenId,
          assetContract: nft.assetContract,
          roundId: Number(nextRound.roundId),
          tournamentId: Number(nextRound.tournamentId),
        };
      });

      try {
        await stakeAll(unStaked);
      } catch (error: any) {
        toast.error(error);
      }
    }
  };
  const handleOnUnStakeAll = async () => {
    const _staked = nftsWithRoundInfo.filter((nft) => nft.staked);

    let staked: {
      tokenId: number;
      tournamentId: number;
      assetContract: string;
    }[] = _staked.map((nft) => {
      return {
        tokenId: Number(nft.tokenId),
        tournamentId: Number(nft.tournamentId),
        assetContract: nft.assetContract,
      };
    });
    try {
      await unStakeAll(staked);
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <StyledDashboard className="container">
      <Helmet>
        <title>Dashboard | Capped Range</title>
      </Helmet>
      <ProfileInfo
        username={userName || '_ _ _'}
        isActive={isActive}
        numNfts={nftsWithRoundInfo.length}
        totalRounds={totalRounds}
        isLoading={isLoading}
        activeNfts={
          nftsWithRoundInfo.filter((nft) => nft.isActive && nft.roundId > 0)
            .length
        }
      />
      {upcomingRounds.length > 0 ? (
        <NextRoundTimer
          nextRound={upcomingRounds[0]}
          setCounter={setCounter}
          counter={countdown}
          playerIsActive={isActive}
        />
      ) : (
        currentRound && (
          <NextRoundTimer
            nextRound={currentRound}
            setCounter={setCounter}
            counter={countdown}
            playerIsActive={isActive}
            isCurrent={true}
          />
        )
      )}
      <div className="actions">
        <button
          className="button"
          onClick={handleOnStakeAll}
          disabled={
            (upcomingRounds.length === 0 &&
              nftsWithRoundInfo.some((nft) => !nft.staked)) ||
            isLoading ||
            nftsWithRoundInfo.length === 0 ||
            upcomingRounds.length === 0 ||
            nftsWithRoundInfo.filter((nft) => !nft.staked).length === 0
          }
        >
          Stake All
        </button>
        <button
          className="button"
          onClick={handleOnUnStakeAll}
          disabled={
            nftsWithRoundInfo.filter(
              (nft) => nft.staked
              // && nft.roundIsOver
            ).length === 0 || isLoading
          }
        >
          Unstake All
        </button>
        {/* <Link to="/dashboard/rounds" className="button">
          Past/Upcoming Rounds
        </Link> */}
      </div>
      <NftList
        nfts={nftsWithRoundInfo}
        paginate={paginate}
        setPaginate={setPaginate}
      />
    </StyledDashboard>
  );
}

const StyledDashboard = styled.div`
  min-height: 100vh;
  background-image: url(${backgroundImg});
  background-size: cover;
  /* margin-top: 2rem; */
  padding: 2rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    button,
    a {
      font-size: 1rem;
      cursor: pointer;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    button:disabled {
      cursor: not-allowed;
      background: #fff;
    }
  }
`;
