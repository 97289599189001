import {
  useState,
  useMemo,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import arrowLeft from '../assets/arrows/vector-l.png';
import arrowRight from '../assets/arrows/vector-r.png';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { GlobalContext, ContractContext } from '../context';
import Loader from './Loader';
import { Round } from '../types';
import { roundFormatter } from '../helpers';
import { useNavigate } from 'react-router-dom';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

function NftList({
  nfts,
  paginate,
  setPaginate,
}: {
  nfts: {
    page?: number;
    tokenId: number;
    image: string | undefined;
    roundId: number;
    tournamentId: number;
    name: string;
    tier: string;
    isActive: boolean;
    staked: boolean;
    assetContract: string;
    roundIsOver: boolean;
    resultsReady: boolean;
    id: string;
  }[];
  paginate: number;
  setPaginate: Dispatch<SetStateAction<number>>;
}) {
  const [curPage, setCurPage] = useState(1);
  const [sortedNFTs, setSortedNFTs] = useState(nfts);
  const [isSorted, setIsSorted] = useState(true);

  const { stake, unstake, upcomingRounds, currentTournament } =
    useContext(ContractContext);
  const [nextRound, setNextRound] = useState<Round | null>(null);
  const { isLoading } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (upcomingRounds.length > 0) {
      setNextRound(upcomingRounds[0]);
    } else {
      setNextRound(null);
    }
  }, [upcomingRounds]);

  useEffect(() => {
    setSortedNFTs(nfts);
  }, [nfts]);

  const handleNFTSorting = () => {
    setIsSorted(!isSorted);
    if (isSorted) {
      setSortedNFTs(
        nfts.sort((a, b) =>
          a.isActive === b.isActive ? 0 : a.isActive ? 1 : -1
        )
      );
    } else {
      setSortedNFTs(
        nfts.sort((a, b) =>
          a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1
        )
      );
    }
  };

  const handleStake = async (
    roundId: number,
    tournamentId: number,
    assetContract: string,
    tokenId: number
  ) => {
    try {
      stake(roundId, tournamentId, assetContract, tokenId);
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
  };

  const handleUnstake = async (
    tournamentId: number,
    assetContract: string,
    tokenId: number
  ) => {
    try {
      unstake(tournamentId, assetContract, tokenId);
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
    }
  };

  const handleReplayGame = async (matchId: string) => {
    matchId && navigate(`/game/${matchId}`);
  };

  const maxPage = useMemo(() => {
    return Math.max(Math.ceil(sortedNFTs.length / paginate), 1);
  }, [paginate, sortedNFTs]);

  return (
    <StyledNftList>
      <div
        className="nfts-table"
        style={{
          opacity: isLoading ? 0.5 : 1,
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
      >
        <table>
          <thead>
            <tr>
              <th className="body-large">#</th>
              <th className="body-large">Token ID</th>
              <th className="body-large">NFT</th>
              <th className="body-large">Linked Tier</th>
              {/* <th className="body-large">Linked Tier</th> */}
              <th className="body-large">Current Round</th>
              {/* <th className="body-large">Bounty (USDT)</th> */}
              <th
                className="body-large"
                onClick={handleNFTSorting}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  justifyContent: 'center',
                }}
              >
                <span> Status</span>
                {isSorted ? <FaArrowUp /> : <FaArrowDown />}
              </th>
              <th className="body-large">Stake/Unstake</th>
            </tr>
          </thead>
          <tbody>
            {sortedNFTs.length > 0 &&
              sortedNFTs
                .slice((curPage - 1) * paginate, curPage * paginate)
                .map((nft, index) => {
                  return (
                    <tr key={index}>
                      <td className="body-large">
                        {(curPage - 1) * paginate + index + 1}
                      </td>
                      <td className="body-large">{nft.tokenId}</td>
                      <td className="body-large">
                        {['.gif', '.mp4'].some((ext) =>
                          nft.image?.endsWith(ext)
                        ) ? (
                          <video
                            src={nft.image?.replaceAll(
                              'https://gateway.pinata.cloud/',
                              'https://ipfs.thirdweb.com/'
                            )}
                            style={{
                              borderRadius: '50%',
                              height: '4.5rem',
                              width: '100%',
                            }}
                          />
                        ) : (
                          <img
                            src={
                              nft.image ||
                              require(`../assets/nfts/nft${
                                (nft.tokenId % 2) + 1
                              }.png`)
                            }
                            alt={nft.name}
                            height="2.5rem"
                            width="2.5rem"
                            style={{
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </td>
                      <td className="body-large">{nft.name}</td>
                      {/* <td className="body-large">
                        <img
                          src={require(`../assets/tiers/${nft.tier}.png`)}
                          alt={nft.tier || 'tier'}
                        />
                      </td> */}
                      <td className="body-large">
                        {nft.roundId > 0
                          ? `${roundFormatter(nft.roundId)} Round`
                          : '-'}
                      </td>
                      {/* <td className="body-large">${nft.bounty}</td> */}
                      <td className="body-large">
                        {nft.isActive ? (
                          <span style={{ color: '#51CB20' }}>Active</span>
                        ) : isLoading ? (
                          <span style={{ color: '#EBEBEB' }}>----</span>
                        ) : (
                          <span style={{ color: '#EB3128' }}>Eliminated</span>
                        )}
                        <br />
                        {nft.staked && nft?.resultsReady && nft?.id && (
                          <button
                            style={{
                              color: 'yellow',
                              border: 'none',
                              background: 'none',
                              marginTop: '0.8rem',
                            }}
                            onClick={() => handleReplayGame(nft.id)}
                          >
                            Replay
                          </button>
                        )}
                      </td>
                      <td className="body-large">
                        {nft.staked && (
                          // && nft.roundIsOver
                          <button
                            className="unstake-nft-btn"
                            onClick={() =>
                              handleUnstake(
                                nft.tournamentId,
                                nft.assetContract,
                                nft.tokenId
                              )
                            }
                          >
                            Unstake
                          </button>
                        )}
                        {
                          // !nft.roundIsOver &&
                          !nft?.staked && (
                            <button
                              className="stake-nft-btn"
                              onClick={() =>
                                nextRound
                                  ? handleStake(
                                      nextRound.roundId,
                                      nft.tournamentId,
                                      nft.assetContract,
                                      nft.tokenId
                                    )
                                  : {}
                              }
                              disabled={
                                nextRound &&
                                !nft.staked &&
                                (nft.isActive ||
                                  (nft.tournamentId !== currentTournament?.id &&
                                    !nft.isActive))
                                  ? false
                                  : true
                              }
                            >
                              Stake {nft.staked}
                              {nft.roundIsOver}
                            </button>
                          )
                        }
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {!isLoading && sortedNFTs.length === 0 && (
          <p
            style={{
              padding: '1rem',
              textAlign: 'center',
            }}
          >
            No NFTs found.
          </p>
        )}
        {isLoading && <Loader />}
      </div>
      <div className="pagination">
        <p className="all-pages">
          {Math.min(sortedNFTs.length, (curPage - 1) * paginate + 1)} -{' '}
          {Math.min(curPage * paginate, sortedNFTs.length)} of{' '}
          {sortedNFTs.length}
        </p>

        <p
          className="decrement"
          onClick={() => setCurPage((prev) => Math.max(1, prev - 1))}
        >
          <img src={arrowLeft} alt="left-arrow" />
        </p>

        {curPage <= 3 ? null : <p onClick={() => setCurPage(1)}>1</p>}
        {curPage <= 4 ? null : <p className="dots">...</p>}

        {curPage <= 2 ? null : (
          <p onClick={() => setCurPage((prev) => Math.max(1, prev - 2))}>
            {' '}
            {curPage - 2}
          </p>
        )}
        {curPage === 1 ? null : (
          <p onClick={() => setCurPage((prev) => Math.max(1, prev - 1))}>
            {' '}
            {curPage - 1}
          </p>
        )}

        {
          <p style={{ background: '#4C4F58' }} onClick={() => {}}>
            {' '}
            {curPage}
          </p>
        }

        {curPage >= maxPage ? null : (
          <p onClick={() => setCurPage((prev) => Math.min(maxPage, prev + 1))}>
            {' '}
            {curPage + 1}
          </p>
        )}
        {curPage >= maxPage - 1 ? null : (
          <p onClick={() => setCurPage((prev) => Math.min(maxPage, prev + 2))}>
            {' '}
            {curPage + 2}
          </p>
        )}

        {curPage >= maxPage - 3 ? null : <p className="dots">...</p>}
        {curPage >= maxPage - 2 ? null : (
          <p onClick={() => setCurPage(maxPage)}>{maxPage}</p>
        )}

        <p
          className="increment"
          onClick={() => setCurPage((prev) => Math.min(maxPage, prev + 1))}
        >
          <img src={arrowRight} alt="right-arrow" />
        </p>
      </div>
    </StyledNftList>
  );
}

const StyledNftList = styled.div`
  width: calc(100vw - 2rem);
  max-width: calc(1280px - 2rem);
  button {
    cursor: pointer;
  }
  button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  .nfts-table {
    width: 100%;
    height: 75%;
    justify-content: center;
    align-items: center;
    padding: 0px 0.9rem;
    border: 1px solid #616268;
    border-radius: 0.5rem;
    margin-left: -0.3rem;
    padding: 0px 0.6rem;
    overflow-x: auto;
    background: #25262a;
  }

  .nfts-table > table {
    padding: 0px;
  }

  .nfts-table::-webkit-scrollbar {
    width: 0.4em;
    margin-left: 0.03rem;
  }

  .nfts-table::-webkit-scrollbar-track {
    box-shadow: inset 0 1 6px #4c4f58;
  }

  .nfts-table::-webkit-scrollbar-thumb {
    background-color: #4c4f58;
    /* outline: 1px solid slategrey; */
    border-radius: 10px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    min-width: 900px;
  }

  th,
  td {
    text-align: center;
    padding: 0.5rem;
    background: #4c4f58;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    color: #b9babc;
    border-width: 10px 0px;
    border-style: solid;
    border-color: #25262a;
    position: sticky;
    z-index: 2;
    top: 0;
  }

  td {
    color: white;
    z-index: 1;
    border-width: 3px 0px;
    border-style: solid;
    border-color: #25262a;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .pagination > p {
    margin: 1.25rem;
    padding: 0.4rem;
    border-radius: 2px;
    color: white;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 0.9rem;
  }

  .pagination > p:not(.dots, .all-pages):hover {
    background: #363942;
    cursor: pointer;
  }

  .stake-nft-btn {
    border: none;
    font-size: 1rem;
    color: #25262a;
    background: ${({ theme }) => theme.primary};
    border-radius: 8px;
    width: 8rem;
    height: 3rem;
    font-family: 'Josefin Sans';
  }

  .unstake-nft-btn {
    font-size: 1rem;
    color: ${({ theme }) => theme.primary};
    border-radius: 8px;
    border-color: ${({ theme }) => theme.primary};
    width: 8rem;
    height: 3rem;
    background: transparent;
  }

  td > img {
    width: 3.3rem;
    height: 3.3rem;
  }
`;

export default NftList;
