import styled from 'styled-components';
import roadMapTable from '../../assets/roadmap/roadMapTable.png';
import roadMapMobileView from '../../assets/roadmap/mobileView.png';
import roadmapTopBg from '../../assets/roadmap/roadmapTopBg.png';
import roadmapBottomcard from '../../assets/roadmap/roadmapBottomcard.png';
export default function Roadmap() {
  return (
    <StyledRoadmap id="roadmap" className="container">
      <div className="heading">
        <h2>Roadmap</h2>
        <p>
          We’re just getting started but we have big plans and we’re working
          hard to provide a fantastic short and long-term experience for all of
          our players!
        </p>
      </div>
      <div className="roadmap">
        <img className="roadmapTopBg" src={roadmapTopBg} alt="" />
        <img className="roadmapBottomcard" src={roadmapBottomcard} alt="" />
        <img src={roadMapTable} alt="" />
        <div className="phase_1">
          <ul>
            <li>- Project ideation</li>
            <li>- Team formation</li>
            <li>- Game and range structure defined</li>
            <li>- Implement UI & UX</li>
            <li>- Art finalized</li>
            <li>- Game development</li>
            <li>- Genesis NFT launch</li>
            <li>- 8,192 NFTs issued</li>
            {/* <li>- april 2023 - Initial Mint</li> */}
          </ul>
        </div>
        <div className="phase_2">
          <ul>
            <li>
              <span>- </span>
              <span>Implement Staking and rewards</span>
            </li>

            <li>
              <span>- </span> <span>Run weekly automated tournaments</span>
            </li>
            <li>
              <span>- </span>{' '}
              <span>
                Host Monthly online tournaments through CappeD range’s Poker
                room
              </span>
            </li>
          </ul>
        </div>
        <div className="phase_3">
          <ul>
            <li>
              <span>- </span>
              <span>
                Develop private room for members with hours of poker content
              </span>
            </li>
            <li>
              <span>- </span>
              <span>
                Development of token to correlate with rewards and integration
                into CR Poker Room
              </span>
            </li>
            <li>
              <span>- </span>
              <span>
                Continue to establish partnerships and grow the Capped Range
                Brand
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="notVisible">
        <img src={roadMapMobileView} alt="" />
      </div>
    </StyledRoadmap>
  );
}

const StyledRoadmap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @font-face {
    font-family: 'Northead';
    src: url(../../fonts/northead.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url(../../fonts/Inter-Light.ttf) format('truetype');
  }
  @media (max-width: 576px) {
    background-size: 1360px 200%;
  }
  .heading {
    padding-top: 5rem;
    h2 {
      font-family: 'Northead' !important;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 88.7888px;
      line-height: 61px;
      letter-spacing: -0.02em;
    }
    p {
      margin-bottom: 5rem;
      margin-top: 1rem;
      font-family: 'Inter' !important;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      max-width: 689px;
    }
  }
  .roadmap {
    position: relative;
    z-index: 1;
    .roadmapBottomcard {
      z-index: -1;
      width: 399px;
      height: 500px;
      position: absolute;
      bottom: -8rem;
      left: -8rem;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .roadmapTopBg {
      z-index: -1;
      width: 400px;
      height: 500px;
      position: absolute;
      right: -3rem;
      top: -15rem;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    @media (max-width: 850px) {
      display: none;
    }
    position: relative;
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1024px) {
        width: 80%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .phase_1 {
      position: absolute;
      top: 19%;
      left: 40%;
      @media (max-width: 910px) {
        top: 18%;
      }

      ul {
        list-style-position: inside;
        text-align: left;
        list-style-type: none;

        li {
          font-family: 'Northead' !important;
          font-weight: 400;
          font-size: 40px;
          line-height: 70%;
          letter-spacing: 0.02em;
          color: #ffffff;
          margin-bottom: 1rem;
          @media (max-width: 1024px) {
            font-size: 25px;
            margin-left: -1.5rem;
          }
        }
      }
    }
    .phase_2 {
      position: absolute;
      top: 47%;
      left: 40%;

      ul {
        list-style-position: inside;
        text-align: left;
        list-style-type: none;

        color: #ffffff;
        li {
          width: 414px;
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          font-style: normal;
          font-weight: 400;
          font-size: 40px;
          line-height: 70%;
          letter-spacing: 0.02em;
          color: #ffffff;
          margin-bottom: 1.5rem;
          @media (max-width: 1024px) {
            font-size: 25px;
            margin-left: -1.5rem;
            width: 70%;
            height: 100%;
          }
          span {
            font-family: 'Northead' !important;
          }
        }
      }
    }
    .phase_3 {
      position: absolute;
      bottom: 20%;
      left: 40%;
      ul {
        list-style-position: inside;
        text-align: left;
        list-style-type: none;
        li {
          font-family: 'Northead' !important;
          font-weight: 400;
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          width: 75%;
          font-size: 40px;
          line-height: 70%;
          letter-spacing: 0.02em;
          color: #ffffff;
          margin-bottom: 1rem;
          @media (max-width: 1024px) {
            font-size: 25px;
            margin-left: -1.5rem;
          }
        }
      }
    }
  }
  .notVisible {
    display: none;
  }
  @media screen and (max-width: 850px) {
    .notVisible {
      display: block;
      img {
        width: 100%;
      }
    }
  }
`;
