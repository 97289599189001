import { useState, useRef, useEffect } from 'react';
import styled, { ThemeConsumer } from 'styled-components';

interface AccordionProps {
  title: string;
  collapsed?: boolean;
  children: React.ReactNode | string;
}

export default function Accordion({
  title,
  collapsed,
  children,
}: AccordionProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(collapsed || false);
  const [contentHeight, setContentHeight] = useState(1000);

  useEffect(() => {
    setContentHeight(contentRef.current!.offsetHeight);
  }, [isOpen]);

  return (
    <StyledAccordion onClick={() => setIsOpen(!isOpen)}>
      <div className="title">
        <h3>{title}</h3>
        <span>{isOpen ? '˅' : '˃'}</span>
      </div>
      <div
        className="content"
        style={{
          maxHeight: isOpen ? `${contentHeight}px` : '0px',
        }}
      >
        <div ref={contentRef} className="content-container">
          {children}
        </div>
      </div>
    </StyledAccordion>
  );
}

const StyledAccordion = styled.div`
  background: rgba(36, 40, 44, 0.8);
  backdrop-filter: blur(12px);
  border-radius: 2px;
  cursor: pointer;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 2rem;
    h3 {
      font-size: 1.2rem;
      line-height: 1.4;
      color: ${({ theme }) => theme.primary};
    }
    span {
      font-size: 2rem;
      line-height: 0;
    }
  }
  .content {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    &-container {
      padding: 0 2rem 1.5rem;
      font-size: 1rem;
      line-height: 1.4;
      font-weight: 300;
      color: ${({ theme }) => theme.secondary};
    }
  }
`;
