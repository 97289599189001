import axios from 'axios';

let defaultConfig = {
  isProduction: process.env.NODE_ENV === 'production',
};

// BACKEND_URL
const REACT_APP_API_URL = defaultConfig.isProduction
  ? 'https://api.cappedrange.gg'
  : `https://api.cappedrange.gg`;
// `http://localhost:5000`;
// `http://192.168.0.108:5000`;

const Axios = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const config = {
  ...defaultConfig,
  REACT_APP_API_URL,
  REACT_APP_INFURA_ID: process.env.REACT_APP_INFURA_ID,
  REACT_APP_NAME: 'Capped Range',
  // REACT_PINATA_API_URL: `https://ipfs.thirdweb.com/ipfs/QmXYvYhJk7N8F6HqkhjFZ8u71m6p73DQmu4woJJ5UuiGhj`,
  REACT_PINATA_API_URL: `https://gateway.ipfscdn.io/ipfs/QmXYvYhJk7N8F6HqkhjFZ8u71m6p73DQmu4woJJ5UuiGhj`,
  axios: Axios,

  // EDITION Address
  // EDITION_ADDRESS: `0x38410cf0B127D2fb77433585F5dc8E27310c4C65`,
  EDITION_ADDRESS: `0x5791172590603cE43647916AA70D6bE50c671F7b`,

  // TOURNAMENT Contract
  TOURNAMENT_ADDRESS: `0xed508F9f009eAC8f7915537e773c4Be7230D59d9`,
  /**
   * SUPPORTED_CHAID_IDS
   */
  SUPPORTED_CHAID_IDS: [137, 80001, 4],

  /**
   * Round Max Players
   */
  MAX_PLAYERS: 8192,
  MAX_ROUNDS: 13,
};
