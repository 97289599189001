import { FormEvent, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { config } from '../../config';
import { ContractContext, GlobalContext } from '../../context';

export default function CreateTournament() {
  const [tournamentName, setTournamentName] = useState<string>('');
  const [assetContract, setAssetContract] = useState<string>(
    config.EDITION_ADDRESS
  );
  const [maxRounds, setMaxRounds] = useState<number>(13);
  const { setIsLoading } = useContext(GlobalContext);
  const { createTournament } = useContext(ContractContext);

  const handleCreateTournament = async (e: FormEvent) => {
    e.preventDefault();

    // validate user input
    if (!tournamentName) {
      toast.error('Tournament name must be provided');
      return;
    }
    if (!assetContract) {
      toast.error('Asset contract address is required');
      return;
    }

    if (maxRounds < 1) {
      toast.error('Max Rounds must be greater than 0');
      return;
    }

    try {
      setIsLoading(true);

      createTournament(tournamentName, assetContract, maxRounds);
    } catch (error: any) {
      toast.error(`Error: ${error?.data?.message || error?.message}`);
      setIsLoading(false);
    }
  };

  return (
    <StyledcreateTournament>
      <h1>Create Tournament</h1>
      <form>
        <label>
          <h4>Tournament Name</h4>
          <input
            type="text"
            placeholder="Tournament Name"
            value={tournamentName}
            required
            onChange={(e) => setTournamentName(e.target.value)}
          />
        </label>
        <label>
          <h4>Asset Contract</h4>
          <input
            type="text"
            placeholder="Asset Contract"
            value={assetContract}
            required
            onChange={(e) => setAssetContract(e.target.value)}
          />
        </label>
        <label>
          <h4>Max Rounds</h4>
          <input
            type="number"
            placeholder="Max Rounds"
            value={maxRounds}
            required
            onChange={(e) => setMaxRounds(Number(e.target.value))}
          />
        </label>
        <button
          type="submit"
          className="button"
          onClick={handleCreateTournament}
        >
          Create
        </button>
      </form>
    </StyledcreateTournament>
  );
}

const StyledcreateTournament = styled.div`
  h1 {
    font-size: 1.5rem;
  }
  h3 {
    margin-top: 1.5rem;
  }
  form {
    margin-top: 1.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-end;
    }
    label {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;
      h4 {
        font-size: 1rem;
        font-weight: 400;
      }
    }
    input {
      width: 100%;
      display: inline-block;
      background: #25262a;
      border: 1px solid #4c4f58;
      padding: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      color: #fff;
    }
  }
`;
