import { useState, useCallback, useContext, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import backgroundImg from '../../assets/backgrounds/background2.png';
import styled from 'styled-components';
import { GlobalContext } from '../../context';
import { AuthContext } from '../../context/auth';

export function Register() {
  const {
    address,
    userName: nickname,
    setUserName,
    handleConnectWallet,
  } = useContext(GlobalContext);
  const { register, isLoggedIn } = useContext(AuthContext);
  const [nicknameError, setNicknameError] = useState('');
  const [addressError, setAddressError] = useState('');

  let navigate = useNavigate();

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (!nickname) {
        setNicknameError("Nickname can't be empty!");
        return;
      }
      setNicknameError('');

      if (!address) {
        setAddressError("Address can't be empty!");
        return;
      }
      register(nickname, address);
    },
    [address, nickname, register]
  );

  return (
    <StyledRegister>
      <div className="content">
        {isLoggedIn ? (
          <div className="card">
            <h5>Congratulation!</h5>
            <h6>Here's your Game NFT</h6>
            <p className="body-large">
              You have successfully registered to Capped Range Poker Game! You
              can log into your account anytime to see leaderboard and upcoming
              round. The first round will take place on
              <span>July 12th, 2022</span>.
            </p>
            <button onClick={() => navigate('/lobby')}>Go to Lobby</button>
          </div>
        ) : address ? (
          <div className="card">
            <h3>Profile</h3>
            <h4>
              Choose a nickname for your NFT that you’ll be using throught the
              game.
            </h4>
            <form onSubmit={onSubmit}>
              <label>
                <b>Nickname</b>
                <input
                  type="text"
                  placeholder="A unique nickname"
                  name="nickname"
                  value={nickname || ''}
                  onChange={(e) => setUserName(e.target.value.trim())}
                />
              </label>
              <span>{nicknameError || addressError}</span>
              <div
                className="list"
                style={{
                  marginBottom: '4rem',
                }}
              >
                <p>
                  <img src="/images/diamond.png" alt="" />
                  Needs to be unique
                </p>
                <p>
                  <img src="/images/diamond.png" alt="" />
                  You can't include symbols or other punctuation marks.
                </p>
                <p>
                  <img src="/images/diamond.png" alt="" />
                  Should be less than 30 characters.
                </p>
              </div>
              <button type="submit" className="button">
                Complete
              </button>
            </form>
          </div>
        ) : (
          <div className="card">
            <h3>Game Registration</h3>
            <button onClick={() => handleConnectWallet()} className="button">
              <img src="/images/metamask.png" alt="" />
              Connect with Metamask
            </button>
            <p>
              By continuing, you agree to our <Link to="/">Terms</Link> and{' '}
              <Link to="/">Privacy Policy</Link>.
            </p>
            <div className="list">
              <p>
                <img src="/images/diamond.png" alt="" />
                Make sure you have you have Metamask Extension
              </p>
              <p>
                <img src="/images/diamond.png" alt="" />
                Connect to your Metamask Account
              </p>
              <p>
                <img src="/images/diamond.png" alt="" />
                Your NFTs will be visible to us
              </p>
            </div>
            <div className="info">
              *Disclaimer: You still have full ownership of your NFTs. We only
              have view access until your stake them.
            </div>
          </div>
        )}
      </div>
    </StyledRegister>
  );
}

const StyledRegister = styled.div`
  min-height: 100vh;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4rem 0;
  .card {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border-radius: 1rem;
    background: ${({ theme }) => theme.background};
    box-shadow: 0px 4px 56px 18px rgba(103, 103, 103, 0.11);
    h3 {
      margin: 0.5rem 0 1.5rem;
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.primary};
      line-height: 1.4;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    button {
      font-size: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 1rem;
        background: #fff;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        object-fit: contain;
        object-position: center;
        padding: 0.125rem;
      }
    }
    p {
      line-height: 1.4;
      font-weight: 300;
      margin: 1rem 0 0;
      font-size: 0.8rem;
      color: ${({ theme }) => theme.quinary};
      a,
      span {
        color: ${({ theme }) => theme.primary};
      }
    }
    form {
      width: 100%;
      label {
        display: block;
        margin-bottom: 1rem;
        b {
          display: block;
          font-size: 1rem;
        }
        input {
          background: #25262a;
          border: 1px solid #4c4f58;
          padding: 1rem;
          width: 100%;
          border-radius: 0.5rem;
          font-size: 1rem;
          margin-top: 0.5rem;
          color: #fff;
        }
      }
    }
  }
  .list {
    margin: 1rem 0 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.125rem;
    p {
      font-size: 1rem;
      line-height: 1;
      font-weight: 500;
      img {
        margin-right: 0.5rem;
      }
    }
  }
  .info {
    margin: 2rem 0 0;
    padding: 1rem 0 0;
    border-top: 1px solid ${({ theme }) => theme.quaternary};
    font-size: 0.8rem;
    color: ${({ theme }) => theme.quinary};
    font-weight: 300;
    text-align: center;
    line-height: 1.4;
  }
`;
