import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import backgroundImg from '../../assets/backgrounds/background.png';
import styled from 'styled-components';

declare let window: any;

export function RegisterSuccess() {
  const [hasMetaMask, setHasMetaMask] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      if (typeof window.ethereum !== 'undefined') {
        setHasMetaMask(true);
      }
      await window.ethereum
        .enable()
        .then(() => {
          navigate('/lobby');
        })
        .catch(() => {
          alert('You are not registered!');
        });
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRegisterSuccess>
      <div className="content">
        <div>
          Game registration
          <button onClick={() => null}>Register with Metamask</button>
        </div>
      </div>
    </StyledRegisterSuccess>
  );
}

const StyledRegisterSuccess = styled.div`
  height: 100vh;
  background-color: #25262a;
  display: flex;
  flex-direction: column;
  .content {
    background-image: url(${backgroundImg});
    height: 80%;
    margin-left: 10%;
    background-color: #25262a;
    background-size: cover;
    background-repeat: no-repeat;
    width: 80%;
    display: flex;
    flex-direction: row;
  }
`;
