import { flatten } from 'flat';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaFileExport, FaWindowClose } from 'react-icons/fa';
import { usePapaParse } from 'react-papaparse';
import styled from 'styled-components';
import Loader from '../../components/Loader';
import { config } from '../../config';
import { ContractContext, GlobalContext, PokerContext } from '../../context';
import { groupBy } from '../../helpers';

export function PlayersInRound({
  roundId,
  tournamentId,
  show,
  onclose,
}: {
  roundId: number;
  tournamentId: number;
  show: boolean;
  onclose: () => void;
}) {
  const [playersInRound, setPlayersInRound] = useState<any[]>([]);
  const { getPlayers: getPlayersInRound } = useContext(PokerContext);
  const { isLoading, setIsLoading } = useContext(GlobalContext);
  const { getPlayers: getPlayersInTournament } = useContext(ContractContext);
  const { jsonToCSV } = usePapaParse();

  useEffect(() => {
    if (roundId && tournamentId) {
      setIsLoading(true);
      getPlayersInRound(tournamentId, roundId)
        .then(async (players) => {
          let playersInTournament = await getPlayersInTournament(tournamentId);

          let playersInTournamentGroupedByTokenId = groupBy(
            playersInTournament,
            'tokenId'
          );

          setPlayersInRound(
            players.map((player) => {
              let playerInTournament = (
                playersInTournamentGroupedByTokenId[player.tokenId] || []
              ).sort(
                (a: { timestamp: number }, b: { timestamp: number }) =>
                  b.timestamp - a.timestamp
              )[0];

              if (playerInTournament) {
                return {
                  ...player,
                  staked: playerInTournament.staked,
                };
              }
              return player;
            })
          );

          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundId, tournamentId]);

  if (!show) {
    return null;
  }
  const handleExportToCSV = () => {
    toast.promise(
      new Promise((resolve, reject) => {
        try {
          const flattened = playersInRound.map((player) =>
            flatten({
              player: player.playerAddress,
              stakedNFT: player.assetContract || config.EDITION_ADDRESS,
              stakedTokenID: player.tokenId,
              roundNumber: player.roundId,
              tournamentID: player.tournamentId,
              staked: player.staked ? 'Staked' : 'Unstaked',
            })
          );

          const csv = jsonToCSV(flattened);
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = `tournament-${tournamentId}-round-${roundId}-players.csv`;
          link.click();
          resolve(csv);
        } catch (err: any) {
          reject(err.message || err);
        }
      }),
      {
        loading: 'Exporting players...',
        success: 'Exported successfully',
        error: 'Error exporting',
      }
    );
  };
  return (
    <StyledCurrent>
      <div className="modal">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '65.5%',
          }}
        >
          <h1>
            {isLoading ? 'Loading ' : `Found ${playersInRound.length}`} players
            in round {roundId}, Tournament {tournamentId}
          </h1>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            {playersInRound.length > 0 ? (
              <FaFileExport
                onClick={handleExportToCSV}
                style={{
                  cursor: 'pointer',
                  color: 'yellow',
                  fontSize: '1.4rem',
                }}
                title="Export to CSV"
              />
            ) : (
              <FaFileExport
                style={{
                  cursor: 'not-allowed',
                  color: 'grey',
                  fontSize: '1.4rem',
                }}
                title="Export to CSV"
              />
            )}

            <FaWindowClose
              onClick={onclose}
              style={{
                cursor: 'pointer',
                color: 'red',
                fontSize: '1.4rem',
              }}
              title="Close Modal"
            />
          </div>
        </div>
        <div className="table">
          <div className="table__header">
            <h3>#</h3>
            <h3>Player Address</h3>
            <h3>Staked NFT</h3>
            <h3>Staked Token ID</h3>
            <h3>Round Number</h3>
            <h3>Staked/Unstaked</h3>
          </div>
          <div className="table__body">
            {playersInRound.length > 0 && !isLoading ? (
              playersInRound
                .sort((a, b) => a.tokenId - b.tokenId)
                .map((player, i) => (
                  <div className="table__row" key={i}>
                    <p>{i + 1}</p>
                    <p>{player.playerAddress || player.player}</p>
                    <p>{player.assetContract || config.EDITION_ADDRESS}</p>
                    <p>{Number(player.tokenId)}</p>
                    <p>{Number(player.roundId)}</p>
                    <p>{player.staked ? 'Staked' : 'Unstaked'}</p>
                  </div>
                ))
            ) : (
              <>
                {!isLoading && (
                  <p
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {roundId
                      ? `No players found in round ${roundId}`
                      : 'No current round'}
                  </p>
                )}
              </>
            )}
          </div>
          {isLoading && <Loader />}
        </div>
      </div>
    </StyledCurrent>
  );
}

const StyledCurrent = styled.div`
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    h1 {
      font-size: 1.5rem;
    }
    .table {
      overflow-x: auto;
      margin-top: 1.5rem;
      border: 1px solid ${({ theme }) => theme.quinary};
      h3 {
        width: 207.5px;
        font-size: 1.1rem;
        padding: 0.8rem;
        overflow: auto;
        &:not(:last-child) {
          border-right: 1px solid ${({ theme }) => theme.quinary};
        }
      }
      p {
        width: 207.5px;
        font-weight: 300;
        padding: 0.6rem;
        overflow: auto;
        &:not(:last-child) {
          border-right: 1px solid ${({ theme }) => theme.quinary};
        }
      }
      &__header {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        text-align: center;
        width: fit-content;
        background-color: ${({ theme }) => theme.quaternary};
        border-bottom: 1px solid ${({ theme }) => theme.quinary};
      }
      &__body {
        width: fit-content;
        display: grid;
        grid-template-columns: 1fr;
      }
      &__row {
        width: fit-content;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        text-align: center;
        &:not(:last-child) {
          border-bottom: 1px solid ${({ theme }) => theme.quinary};
        }
        p {
          overflow: auto;
        }
      }
    }
  }
`;
