export const humanizeAddress = (address: string) => {
  return address.slice(0, 6) + '...' + address.slice(-4);
};

export const groupBy = function (xs: any[], key: string | number) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
