import subtractBg from '../../assets/subtractBg.png';
import eth from '../../assets/ethereiun.png';
import gifCard from '../../assets/hero.gif';
import leftCards from '../../assets/cards_hero/cards_left.png';
import rightCards from '../../assets/cards_hero/cards_right.png';
import styled from 'styled-components';
import { FormEvent, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../../context/auth';
import { GlobalContext } from '../../context';

export default function Hero() {
  const [email, setEmail] = useState('');

  const { joinWaitList } = useContext(AuthContext);
  const { isLoading } = useContext(GlobalContext);

  const handleJoinWaitlist = async (e: FormEvent) => {
    e.preventDefault();

    if (email.length < 1) {
      toast.error('Please enter an email address');
      return;
    }

    if (!email.includes('@')) {
      toast.error('Please enter a valid email address');
      return;
    }

    joinWaitList(email.trim());
  };
  return (
    <StyledHero>
      <div className="container hero">
        <div className="hero">
          <h1>
            Play heads-up and <span>win incredible prizes</span> through Capped
            Range
          </h1>
          <p>
            Your Capped Range NFT is your entry into the world’s first heads-up
            NFT poker tournament experience! Spin your entry into incredible
            prizes as you progress through each of our tournaments throughout
            the next year!
          </p>
        </div>
        <div className="statsContainer">
          {' '}
          <div className="stats">
            <div className="firstStat">
              <span>8k+</span>
              <span className="bottom_font first">Players</span>
            </div>
            <div className="secondStat">
              <span>50+</span>
              <span className="bottom_font second">Tournaments</span>
            </div>
            <div className="thirdStat">
              <span>
                <img src={eth} alt="" />
                <span>200+</span>
              </span>
              <span className="bottom_font">in Prizes*</span>
            </div>
          </div>
          <p className="prizePara">
            *Prizepool to be split among all scheduled tournaments
          </p>
        </div>
        <div className="subtract">
          <div className="capture">
            <form
              style={{
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? 'none' : 'all',
              }}
            >
              <input
                type="email"
                required
                placeholder="Join our presale list"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="button"
                onClick={handleJoinWaitlist}
              >
                Join
              </button>
            </form>
          </div>
        </div>
        <div className="card-left">
          <img src={leftCards} alt="" />
        </div>
        <div className="card-right">
          <img src={rightCards} alt="" />
        </div>
      </div>
      <section className="smallScreen">
        <div className="container">
          <div>
            <h1>
              Play heads-up and <span>win incredible prizes</span> through
              Capped Range
            </h1>
            <p>
              Your Capped Range NFT is your entry into the world’s first
              heads-up NFT poker tournament experience! Spin your entry into
              incredible prizes as you progress through each of our tournaments
              throughout the next year!
            </p>
          </div>
          <div className="statsContainerSmall">
            {' '}
            <div className="statsSmall">
              <div className="headingRow">
                <span className="head">8k+</span>
                <span className="headSpan head1">Players</span>
              </div>
              <div className="headingRow readingRow13">
                <span className="head">50+</span>
                <span className="headSpan">Tournaments</span>
              </div>
              <div className="headingRow">
                <span className="ethRow">
                  <img src={eth} alt="" />
                  <span className="head head200">200+</span>
                </span>
                <span className="headSpan head3">in Prizes*</span>
              </div>
            </div>
            <p>*Prizepool to be split among all scheduled tournaments</p>
          </div>
          <div className="subtractSmall">
            <div className="captureSmall">
              <form
                style={{
                  opacity: isLoading ? 0.5 : 1,
                  pointerEvents: isLoading ? 'none' : 'all',
                }}
              >
                <input
                  type="email"
                  required
                  placeholder="Join our presale list"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="button"
                  onClick={handleJoinWaitlist}
                >
                  Join
                </button>
              </form>
            </div>
          </div>
        </div>
        <img className="heroGif" src={gifCard} alt="" />
      </section>
    </StyledHero>
  );
}

const StyledHero = styled.section`
  @font-face {
    font-family: 'Northead';
    src: url('/fonts/northead.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.ttf') format('truetype');
  }

  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 18rem;
  .statsContainer {
    display: none;
    @media (min-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;

      p {
        padding-top: 1rem !important;
        font-family: 'Inter' !important;

        /* margin-top: 2rem; */
      }
      span:first-child {
        color: #ffc728;
      }
      .prizePara {
        margin-left: -0.5rem;
      }
      .stats {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        align-items: center;
        gap: 1.7rem;
        /* margin-left: 1.5rem; */
        width: 342px;
        .firstStat {
          padding-left: 1.2rem;
          .first {
            margin-left: -2rem;
          }
        }
        .secondStat {
          padding-left: 1rem;
          .second {
            margin-right: 1.8rem;
          }
        }
        .thirdStat {
          /* padding-left: 0.5rem; */
        }
        div {
          display: flex;
          flex-direction: column;
          /* justify-content: center; */
          align-items: center;

          span:first-child {
            font-family: 'Northead' !important;
            letter-spacing: 0.03em;
            color: #ffc728;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 75px;
            gap: 0.5rem;
          }
          .bottom_font {
            font-family: 'Inter' !important;
            font-weight: 300;
            font-size: 14.4548px;
            line-height: 22px;
            margin-left: 0.3rem;
          }
        }

        @media (max-width: 1200px) {
          gap: 2rem;
        }
      }
      @media (max-width: 1200px) {
        margin-top: -1rem;
      }
      /* Here */
      @media (max-width: 576px) {
        margin-left: -1.5rem;
      }
    }
  }
  .hero {
    display: none;
    @media (min-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .card-left {
        position: absolute;
        top: -1rem;
        left: -12rem;
        z-index: -1;

        @media (max-width: 1200px) {
          left: 5rem;
          img {
            width: 85% !important;
            height: 100% !important;
          }
        }
        @media (max-width: 1080px) {
          top: 0;
          left: 5rem;
          img {
            width: 65% !important;
          }
        }
        @media (max-width: 992px) {
          display: none;
        }
      }

      .card-right {
        position: absolute;
        top: -1rem;
        right: -12rem;
        z-index: -1;

        @media (max-width: 1200px) {
          right: 3rem;
          img {
            width: 85% !important;
            height: 100% !important;
          }
        }
        @media (max-width: 1080px) {
          top: 0.5rem;
          right: -3rem;
          img {
            width: 65% !important;
          }
        }
        @media (max-width: 992px) {
          display: none;
        }
      }

      h1 {
        font-family: 'Northead' !important;
        width: 750px;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 5.5rem;
        text-align: center;
        line-height: 3.5rem;
        letter-spacing: -2px;
        @media (max-width: 1200px) {
          width: 55%;
          font-size: 4.1rem;
        }
        @media (max-width: 1080px) {
          width: 60%;
          font-size: 3.5rem;
          line-height: 2.5rem;
        }
        @media (max-width: 992px) {
          width: 90%;
          font-size: 4.5rem;
          line-height: 3rem;
          letter-spacing: -0.02em;
        }
        @media (max-width: 768px) {
          width: 90%;
          font-size: 3.5rem;
          line-height: 2.5rem;
          letter-spacing: -0.02em;
        }
        @media (max-width: 576px) {
          width: 100%;
          font-size: 2.5rem;
          line-height: 2rem;
        }

        span {
          font-family: 'Northead' !important;
          color: #ffc728;
        }
      }

      .subtract {
        margin-top: 1rem;
        background: url(${subtractBg});
        background-size: contain;
        background-repeat: no-repeat;
        width: 750px;
        background-size: 100% 100%;
        height: 171px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1200px) {
          width: 60%;
          height: 130px;
        }
        @media (max-width: 1080px) {
          width: 60%;
          height: 140px;
        }
        @media (max-width: 992px) {
          width: 90%;
        }
        @media (max-width: 576px) {
          height: 100px;
        }
        .capture {
          display: flex;
          align-items: center;
          justify-content: center;
          form {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          input {
            background: #25262a;
            border: 1px solid #4c4f58;
            padding: 1rem;
            width: 450px;
            height: 50px;
            border-radius: 4px;
            font-size: 1rem;
            outline: none;
            color: #fff;
            @media (max-width: 1200px) {
              height: 40px;
            }
            @media (max-width: 1080px) {
              height: 40px;
              width: 450px;
            }

            @media (max-width: 576px) {
              width: 100%;
              height: 30px;
            }
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            margin-left: -50px;
            height: 50px;
            width: 133px;
            font-size: 1rem;
            cursor: pointer;
            border-radius: 2px;
            @media (max-width: 1200px) {
              height: 40px;
            }
            @media (max-width: 576px) {
              width: 20%;
              height: 30px;
            }
          }
        }
      }

      p {
        width: 500px;
        height: 69px;
        font-weight: lighter;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        margin-top: 1rem;
        font-family: 'inter' !important;

        @media (max-width: 1200px) {
          width: 40%;
          font-size: 12px;
        }
        @media (max-width: 1080px) {
          width: 45%;
          font-size: 12px;
        }
        @media (max-width: 992px) {
          width: 70%;
          font-size: 14px;
        }
        @media (max-width: 768px) {
          width: 65%;
          font-size: 14px;
        }
        @media (max-width: 576px) {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
        }
      }

      @media (max-width: 1080px) {
        margin-bottom: 0;
      }
      @media (max-width: 992px) {
        margin-bottom: 0.5rem;
      }
      @media (max-width: 768px) {
        margin-bottom: 0.5rem;
      }
      @media (max-width: 576px) {
        margin-bottom: 0.5rem;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 3rem 0rem;
  }
  @media (max-width: 992px) {
    padding: 3rem 0rem;
  }

  @media (max-width: 576px) {
    padding: 2rem 0.5rem;
  }

  .smallScreen {
    display: none;
    @media (max-width: 576px) {
      .head200 {
        margin-left: -0.9rem;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-family: 'Northead' !important;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 3.5rem;
        text-align: center;
        line-height: 2.5rem;
        letter-spacing: -2px;
        span {
          font-family: 'Northead' !important;
          color: #ffc728;
        }
      }

      p {
        width: 100%;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        margin-top: 1rem;
        font-family: 'inter' !important;
      }
      .statsContainerSmall {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .statsSmall {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin: 1rem 0rem;
          .readingRow13 {
            margin-left: -2rem;
          }
          .headingRow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 0.5rem;
            flex-direction: column;
            margin-bottom: 1rem;

            .head {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              font-size: 4rem;
              font-weight: 400;
              font-family: 'Northead' !important;

              color: #ffc728;
            }
            .ethRow {
              img {
                margin-top: 5px;
              }
              display: flex;
              align-items: center;
              justify-content: baseline;
              width: 100%;
            }

            .headSpan {
              font-family: 'Inter' !important;
            }
            .head1 {
              margin-right: 1.2rem;
            }
          }
          .head3 {
            width: 100%;
            margin-left: 3.2rem;
          }
        }
        p {
          margin: 0 1rem;
          font-family: 'Inter' !important;
          span {
            color: #ffc728;
          }
        }
      }
      .heroGif {
        width: 100%;
        height: 100%;
      }
      .subtractSmall {
        margin-top: 2rem;
        background: url(${subtractBg});
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 13vh;
        margin-bottom: 1rem;
        .captureSmall {
          display: flex;
          align-items: center;
          justify-content: center;
          form {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          input {
            background: #25262a;
            border: 1px solid #4c4f58;
            padding: 1rem;
            width: 250px;
            height: 40px;
            border-radius: 4px;
            font-size: 14px;
            outline: none;
            color: #fff;
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            margin-left: -50px;
            height: 40px;
            width: 60px;
            font-size: 1rem;
            cursor: pointer;
            border-radius: 2px;
          }
        }
      }
    }
  }
`;
