import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GlobalContext } from '../../context';

import backgroundImg from '../../assets/backgrounds/background.png';
import styled from 'styled-components';
import { providers } from 'ethers';

// import "./registerMetamask.css";

export function RegisterMetamask() {
  function RegisterMetamask() {
    const [hasMetaMask, setHasMetaMask] = useState(false);
    const { setAddress, getProviderOrSigner } = useContext(GlobalContext);

    let navigate = useNavigate();

    useEffect(() => {
      const init = async () => {
        if (typeof window.ethereum !== 'undefined') {
          setHasMetaMask(true);
        }
      };
      init();
    });

    const handleClick = async (e: any) => {
      e.preventDefault();
      hasMetaMask &&
        (await window.ethereum
          .enable()
          .then(async () => {
            const provider = (await getProviderOrSigner(
              true
            )) as providers.JsonRpcSigner;
            const address = (await provider?.getAddress()) || null;

            setAddress(address);
            navigate('/register');
          })
          .catch(() => {
            window.alert('You need to allow MetaMask.');
          }));
    };

    return (
      <StyledRegisterMetamask>
        <div className="content">
          <div>
            Game registeration
            <button onClick={handleClick}>Register with Metamask</button>
          </div>
        </div>
      </StyledRegisterMetamask>
    );
  }
}

const StyledRegisterMetamask = styled.div`
  min-height: 100vh;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4rem 0;
`;
