import { createContext } from 'react';

interface IAuth {
  isLoggedIn: boolean;
  login: (address: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (nickname: string, address: string) => Promise<void>;
  loadUser: (token: string) => Promise<void>;
  joinWaitList: (email: string) => Promise<void>;
  getWaitlist: () => Promise<
    {
      email: string;
      createdAt: number | Date;
    }[]
  >;
}
const defaultValue = {
  isLoggedIn: false,

  login: async (address: string) => {},
  logout: async () => {},
  register: async (nickname: string, address: string) => {},
  loadUser: async (token: string) => {},
  joinWaitList: async (email: string) => {},
  getWaitlist: async () => [],
};

export const AuthContext = createContext<IAuth>(defaultValue);
