import { useState } from 'react';
import { useContract } from '../../hooks';
import { ITournament, Round } from '../../types';
import { ContractContext } from './contractContext';

export const ContractProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [upcomingRounds, setUpcomingRounds] = useState<Round[]>([]);
  const [pastRounds, setPastRounds] = useState<Round[]>([]);
  const [tournaments, setTournaments] = useState<ITournament[]>([]);
  const [currentRound, setCurrentRound] = useState<Round | null>(null);
  const [metadatas, setMetadatas] = useState<Record<
    number,
    {
      tokenId: number;
      name: string;
      image: string;
      tier: string;
    }
  > | null>(null);
  const [currentTournament, setCurrentTournament] =
    useState<ITournament | null>(null);

  const {
    stake,
    unstake,
    createRound,
    getPlayers,
    getMyStakedNFTs,
    stakeAll,
    unStakeAll,
    hasAdminRole,
    getRound,
    createTournament,
    getTournaments,
    updateRound,
    grantAdminRole,
    setRoundAwards,
    fetchNFTSWithMetadata,
    getOwnedNFTs,
    mintToSelf,
    enrollToRound,
  } = useContract();

  return (
    <ContractContext.Provider
      value={{
        upcomingRounds,
        stake,
        unstake,
        createRound,
        getPlayers,
        setUpcomingRounds,
        setCurrentRound,
        currentRound,
        stakeAll,
        unStakeAll,
        getMyStakedNFTs,
        hasAdminRole,
        pastRounds,
        setPastRounds,
        getRound,
        createTournament,
        updateRound,
        grantAdminRole,
        currentTournament,
        setCurrentTournament,
        tournaments,
        setTournaments,
        getTournaments,
        setRoundAwards,
        fetchNFTSWithMetadata,
        getOwnedNFTs,
        mintToSelf,
        enrollToRound,
        metadatas,
        setMetadatas,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};
