import styled from 'styled-components';
import Accordion from '../Accordion';
import bgCard from '../../assets/faq/bgCard.png';
import card from '../../assets/faq/card.png';

export default function Faqs() {
  return (
    <StyledFaqs id="faq">
      <img className="bgCard" src={bgCard} alt="" />
      <div className="container">
        <h2>Frequently Asked Questions</h2>

        <div className="items">
          <Accordion title="How many total Capped Range NFTs will be minted?">
            8,192 NFTs will be minted - exactly the amount of a 13-round
            single-elimination tournament.
          </Accordion>
          <Accordion
            title="Do I need to stake my Capped Range NFT in order to play in the 
tournament?"
          >
            Yes, you must stake your Capped Range NFT in order to play in our
            tournaments. All unstaked NFTs must be re-staked prior to the
            beginning of the next tournament in order to continue to participate
            in the next scheduled tournament.
          </Accordion>
          <Accordion title="Can I sell my Capped Range NFT while it is staked in the tournament?">
            No, you must unstake your Capped Range NFT in order to transfer or
            sell it on the secondary market.
          </Accordion>
          <Accordion title="What if a match results in a tie hand?">
            If the match results in a tie hand, the match will be re-run until a
            winner is determined.
          </Accordion>
        </div>
        <img className="card" src={card} alt="" />
      </div>
    </StyledFaqs>
  );
}

const StyledFaqs = styled.section`
  @font-face {
    font-family: 'Northead';
    src: url('/fonts/northead.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.ttf') format('truetype');
  }

  position: relative;
  padding-top: 2rem;
  .bgCard {
    width: 450px;
    height: 570px;
    position: absolute;
    top: 19%;
    left: 7.5%;
    @media (max-width: 576px) {
      display: none;
    }
  }
  .card {
    position: absolute;
    top: 18%;
    right: 9%;
    z-index: -1;
    @media (max-width: 576px) {
      display: none;
    }
  }
  .container {
    h2 {
      font-family: 'Northead' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 88.7888px;
      line-height: 61px;
      text-align: center;
      letter-spacing: -0.02em;
    }
    .items {
      font-family: 'Inter' !important;
      margin-top: 5rem;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
`;
