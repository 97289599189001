import { DefaultTheme } from 'styled-components';

export const colors: DefaultTheme = {
  primary: '#FFC728',
  secondary: '#FFFFFF',
  tertiary: '#25262A',
  quaternary: '#38393C',
  quinary: '#929397',
  septinary: '141415',
  background: '#1A1A1C',
};
