import { useEffect } from 'react';
import styled from 'styled-components';
import pokerCardsImg from '../assets/profileInfoIcons/pokerCards.png';
import { displayRemainingTime, roundFormatter } from '../helpers';
import dateformat from 'dateformat';
import { Round } from '../types';
export default function NextRoundTimer({
  nextRound,
  playerIsActive,
  setCounter,
  isCurrent,
  counter,
}: {
  nextRound: Round;
  counter: number;
  playerIsActive: boolean;
  setCounter: (c: number) => void;
  isCurrent?: boolean;
}) {
  useEffect(() => {
    if (nextRound?.startTime && !isCurrent) {
      setCounter(new Date(nextRound.startTime).getTime() - Date.now());
    }
  }, [nextRound, isCurrent, setCounter]);
  return (
    <StyledRoundTimer>
      <div className="left">
        <img src={pokerCardsImg} alt="" />
        {!isCurrent && (
          <p>
            <b>{roundFormatter(nextRound?.roundId || 1)} Round</b> will take
            place on{' '}
            <span>
              {nextRound?.startTime
                ? dateformat(
                    new Date(nextRound.startTime),
                    'mmmm dS, yyyy, HH:MM TT'
                  )
                : 'Not set'}
              .
            </span>
          </p>
        )}

        {isCurrent && (
          <p>
            <b>{roundFormatter(nextRound?.roundId || 1)} Round</b> is already
            taking place.{' '}
            {!playerIsActive ?? `Unfortunately, all your NFT's lost the game`}
          </p>
        )}
      </div>
      {!isCurrent && (
        <div className="right">
          {Object.entries(displayRemainingTime(Math.floor(counter / 1000))).map(
            ([key, value], i) => (
              <div className="time-item" key={i}>
                <span>{value}</span>
                <h6>{key}</h6>
              </div>
            )
          )}
        </div>
      )}
    </StyledRoundTimer>
  );
}

const StyledRoundTimer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
  background: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.quaternary};
  box-shadow: 0px 4px 56px 18px rgba(103, 103, 103, 0.11);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  img {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
    object-position: center;
    margin-right: 1rem;
  }
  .left,
  .right {
    display: flex;
    align-items: center;
  }
  .left {
    p {
      line-height: 1.4;
    }
    b {
      font-size: 1.8rem;
      font-weight: 600;
      color: ${({ theme }) => theme.primary};
    }
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .time-item {
    width: 3rem;
    span {
      border-radius: 0.25rem 0.25rem 0 0;
      border: 1px solid ${({ theme }) => theme.primary};
      overflow: hidden;
      display: block;
      padding: 0.5rem;
      font-size: 1.2rem;
      font-weight: 600;
      background: #27241e;
      text-align: center;
    }
    h6 {
      border-radius: 0 0 0.25rem 0.25rem;
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
      padding: 0.25rem;
      text-align: center;
      font-size: 0.8rem;
    }
  }
`;
