export const recoverError = (error: any) => {
  let msg =
    error?.response?.data?.error ||
    error?.error?.message ||
    error?.reason ||
    error?.data?.message ||
    error?.message;
  if (!msg) {
    error = JSON.parse(JSON.stringify(error)) || error;

    msg =
      error?.response?.data?.error ||
      error?.error?.message ||
      error?.reason ||
      error?.data?.message ||
      error?.message;
  }

  return `Failed ${msg}`;
};
