import styled from 'styled-components';
import leftBg from '../../assets/info/leftBg.png';
import rightBottomCard from '../../assets/info/Back_Broadway.png';
import rightTopCard from '../../assets/info/Face_Broadway.png';
import capped_Range from '../../assets/info/Capped_Range_Hand_Grid_1.png';
import capped_20_Range from '../../assets/info/20__Capped_Range_Hand_Grid_1.png';
import infoLastBg from '../../assets/info/Face_Broadway_2.png';

export default function Info() {
  return (
    <StyledInfo id="info">
      <div className="container ">
        <div className="card">
          <h2>
            What is <span>Capped </span> Range?
          </h2>
          <p>
            Capped Range is an NFT gaming project in which players will use
            their NFT asset to compete in several heads-up single-elimination
            poker tournaments consisting of 13 rounds each. In each round of a
            tournament, a Capped Range player will battle against another Capped
            Range player in a single-hand heads-up match. Each player's specific
            NFT will have a pre-assigned poker hand range assigned to it (its
            rarity).
          </p>
          <p>
            At mint, players can expect to mint 1/10 standard tiers ranging from
            a 100% range (most common), all the way down to a 2.5% range
            (rarest). A player may also mint 1/25 rare tiers.
          </p>
          <p>
            Once both players' hands are assigned, the game will simulate a
            five-card board and a winner will be determined. If a player wins
            the round, their NFT advances to the subsequent round and remains
            active. NFTs that lose in the current round will be eliminated but
            still be playable in all future scheduled tournaments and tradeable
            on the secondary market.
          </p>
          <p>
            Players may unstake their NFTs in between tournaments in order to
            trade them on the secondary market. It is important to note, though,
            that all unstaked NFTs must be restaked prior to the beginning of
            the next tournament in order to participate in that tournament. Any
            NFT that is not restaked by the start of the next tournament will be
            unable to join the current tournament and will have to wait until
            the next scheduled tournament to re-enter.
          </p>
        </div>
        <img className="leftBg" src={leftBg} alt="" width={403} height={685} />
        <img className="rightBottomBg" src={rightBottomCard} alt="" />
        <img className="rightTopBg" src={rightTopCard} alt="" />
      </div>

      <div className="card2Wrapper">
        <div className="card2">
          <h2>
            What is a <span>Poker</span> Hand Range?
          </h2>
          <p>
            In order to understand what a poker hand range is, we must first
            explain about poker hand grids. Poker hand grids are used to
            visualize poker ranges.
          </p>
          <p>
            This is a hand grid. These are all the possible hands you could be
            dealt in No-Limit Holdem. The “o” indicates it is an offsuit hand,
            for instance, 9♧ T♡ (Nine of Clubs & Ten of Hearts) while the “s”
            indicates a suited hand, like A♡ K♡ (Ace of Hearts & King of
            Hearts).
          </p>
        </div>{' '}
        <img className="infoLastBg" src={infoLastBg} alt="" />
        <img className="capped_Range" src={capped_Range} alt="" />{' '}
      </div>
      <div className="card3Wrapper">
        <div>
          <img className="capped_20_Range" src={capped_20_Range} alt="" />
        </div>
        <div className="card3">
          <h2>
            How do Poker <span>hand ranges</span> apply to the Capped Range
            tournaments?
          </h2>
          <p>
            In the Capped Range experience, all players will mint or purchase an
            NFT that contains a preassigned range.
          </p>
          <p>
            In this particular example, we look at a player whose NFT is
            assigned a 20% range. What this means is that the top 20% of all
            possible hands are available to the player and visually represented
            in this particular hand grid.
          </p>
          <p>
            The highlighted hands in this grid are all the available hands that
            this player may draw from as they play in the tournaments. This
            player's worst possible suited hand would be A4, worst possible
            off-suit hand would be A8, and worst possible pair would be 55. This
            player's best possible hand would be AA.
          </p>
          <p>
            Additionally, a player with this hand range would never be dealt
            poor hands such as 72 or J4.
          </p>
          <p>
            This, however, still does not guarantee a win in any particular
            tournament round as each round will be played as a one-hand heads-up
            match where the winning hand is subject to the randomly generated
            runout of the board with no particular hand range in mind.
          </p>
          <p>
            Let's now try to visualize an example of how ranges will be applied
            to the actual Capped Range tournaments:
          </p>
        </div>
      </div>
    </StyledInfo>
  );
}

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  @media (max-width: 576px) {
    margin-top: -12rem;
    background-size: 1360px 100%;
  }

  @font-face {
    font-family: 'Northead';
    src: url(../../fonts/northead.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url(../../fonts/Inter-Light.ttf) format('truetype');
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 1200px) {
      margin: 0 5rem;
    }

    @media (max-width: 768px) {
      padding: 0;
      margin: 10rem 2rem;
    }
    @media (max-width: 576px) {
      padding: 0;
      margin: 0rem 1rem;
      margin-bottom: 3rem;
    }
    .leftBg {
      object-fit: contain;
      position: absolute;
      top: 16rem;
      left: -6rem;
      z-index: 7;
      @media (max-width: 1200px) {
        left: -6rem;
      }
      @media (max-width: 768px) {
        left: -7rem;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }

    .rightTopBg {
      object-fit: contain;
      position: absolute;
      top: -2rem;
      right: -3rem;
      z-index: 3;

      /* max-width: 350px; */
      width: 300px;
      height: 330px;
      @media (max-width: 1200px) {
        right: 0rem;
        width: 30%;
        height: 35%;
      }
      @media (max-width: 768px) {
        width: 25%;
        height: 30%;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
    .rightBottomBg {
      object-fit: contain;
      /* width: 270px;
      height: 319px; */
      width: 300px;
      height: 330px;
      position: absolute;
      bottom: -7rem;
      right: -6rem;
      z-index: -2;
      @media (max-width: 1200px) {
        right: -5rem;
        bottom: -6.5rem;
        width: 25%;
        height: 40%;
      }
      @media (max-width: 768px) {
        right: -2rem;
        bottom: -4.5rem;
        width: 20%;
        height: 25%;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
    .card {
      width: 1010px;
      height: 733.89px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      backdrop-filter: blur(44.6415px);
      background-color: rgba(51, 51, 51, 0.2);
      background-blend-mode: overlay, normal;
      border: 2px solid #383838;
      border-radius: 3px;
      padding: 1rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      @media (max-width: 1200px) {
        width: 100%;
        padding: 0;
      }
      @media (max-width: 992px) {
        height: 100%;
        padding: 1rem 0;
      }
      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        padding: 1rem 0;
      }
      @media (max-width: 576px) {
        width: 100%;
        height: 100%;
        padding: 1rem 0;
      }
    }

    h2 {
      font-family: 'Northead' !important;
      margin-top: 60px;
      font-size: 6rem;
      font-weight: 400;
      line-height: 61px;
      text-align: center;
      text-transform: uppercase;
      span {
        color: #ffc728;
        font-family: 'Northead' !important;
      }
      @media (max-width: 1200px) {
        width: 100%;
        font-size: 4.8rem;
        padding-right: 70px;
      }
      @media (max-width: 992px) {
        width: 100%;
        font-size: 4rem;
        padding: 0 1rem;
        line-height: normal;
      }
      @media (max-width: 768px) {
        padding: 0 1rem;
      }
      @media (max-width: 576px) {
        margin-top: 20px;
      }
    }
    /* First paragraph tag */
    P:first-of-type {
      width: 800px;
      font-family: 'Inter' !important;
      @media (max-width: 1200px) {
        width: 80%;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
      /* @media (max-width: 992px) {
     
       
      } */
    }

    p {
      font-family: 'Inter' !important;
      margin-top: 30px;
      font-weight: 300;
      font-size: 17px;
      line-height: 27px;
      padding: 0 30px;
      text-align: justify;
      @media (max-width: 992px) {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
      }
      @media (max-width: 576px) {
        width: 100%;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .card2Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0rem 1rem;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      margin-bottom: 3rem;
    }

    .capped_Range {
      z-index: 4;
      margin-top: 6.5rem;
      margin-left: -9rem;
      @media (max-width: 992px) {
        width: 45%;
      }
      @media (max-width: 768px) {
        width: 58%;
        margin: 0;
        margin-bottom: -8rem;
      }
      @media (max-width: 576px) {
        width: 100%;
        padding: 0 1rem;
      }
    }
    .infoLastBg {
      width: 300px;
      height: 330px;
      object-fit: contain;
      position: absolute;
      top: -4rem;
      left: -2.5rem;
      z-index: 3;

      @media (max-width: 1280px) {
        /* width: 40%;
        height: 65%; */
        top: -4rem;
      }
      @media (max-width: 1200px) {
        width: 40%;
        height: 65%;
      }
      @media (max-width: 1024px) {
        width: 30%;
        height: 55%;
        top: -1rem;
        left: -1rem;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    .card2 {
      z-index: 4;
      margin-top: 100px;
      width: 895px;
      height: 491px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background-color: rgba(51, 51, 51, 0.2);
      background-blend-mode: overlay, normal;
      border: 2px solid #383838;
      backdrop-filter: blur(33.193px);
      border-radius: 3px;
      padding: 1rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(33.193px);

      @media (max-width: 1200px) {
        width: 100%;
        padding: 0;
      }
      @media (max-width: 992px) {
        height: 100%;
        padding: 1rem;
      }
      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        padding: 1rem 0;
      }

      h2 {
        margin-top: 60px;
        font-size: 5.8rem;
        font-weight: 400;
        font-family: 'Northead' !important;
        line-height: 61px;
        text-align: justify;
        text-transform: uppercase;
        letter-spacing: -2px;
        padding: 0 30px;
        @media (max-width: 1200px) {
          width: 100%;
          font-size: 4.5rem;
        }
        @media (max-width: 992px) {
          width: 80%;
          font-size: 3.5rem;
        }
        @media (max-width: 768px) {
          margin-top: 20px;
          text-align: center;
          width: 100%;
          line-height: normal;
          font-size: 4rem;
          padding: 0 1rem;
        }
        span {
          font-family: 'Northead' !important;
          color: #ffc728;
        }
      }

      p {
        width: 634px;
        font-family: 'Inter' !important;
        margin-top: 30px;
        font-weight: 300;
        font-size: 17px;
        line-height: 27px;
        padding: 0 30px;
        text-align: justify;
        @media (max-width: 1200px) {
          width: 80%;
          font-size: 14px;
        }
        @media (max-width: 768px) {
          width: 100%;
          font-size: 14px;
          margin-top: 20px;
          line-height: 20px;
        }
        @media (max-width: 576px) {
          text-align: center;
        }
      }
    }
  }

  .card3Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 1rem;
    margin-bottom: 5rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .capped_20_Range {
      margin-right: -5rem;
      margin-bottom: 13rem;
      z-index: 1;

      @media (max-width: 992px) {
        width: 135%;
        margin-bottom: 7rem;
      }
      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        margin-bottom: -9rem;
      }

      @media (max-width: 576px) {
        width: 100%;
        padding: 0 1rem;
      }
    }
    .card3 {
      z-index: -1;
      margin-top: 100px;
      width: 894px;
      height: 905px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background-color: rgba(51, 51, 51, 0.2);
      background-blend-mode: overlay, normal;
      border: 2px solid #383838;
      backdrop-filter: blur(33.193px);
      border-radius: 3px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(33.193px);
      @media (max-width: 1200px) {
        width: 100%;
        padding: 0;
      }
      @media (max-width: 992px) {
        height: 100%;
        padding: 2rem 0;
      }
      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        padding: 1rem 0;
      }
    }
    h2 {
      margin-top: 60px;
      font-size: 5rem;
      font-weight: 400;
      font-family: Northead !important;
      line-height: 50px;
      text-transform: uppercase;
      letter-spacing: -2px;
      padding-left: 100px;
      margin-top: 5rem;
      @media (max-width: 1200px) {
        width: 100%;
        font-size: 4.5rem;
      }
      @media (max-width: 992px) {
        margin-top: 3rem;
        line-height: normal;
        font-size: 3.5rem;
        text-align: center;
      }
      @media (max-width: 768px) {
        margin-top: 40px;
        padding: 0 2rem;
        text-align: center;
        width: 100%;
      }
      span {
        font-family: Northead !important;
        color: #ffc728;
      }
    }

    p {
      margin-top: 30px;
      padding-right: 50px;
      padding-left: 100px;
      font-family: 'Inter' !important;
      font-weight: 300;
      font-size: 17px;
      line-height: 27px;
      text-align: justify;
      @media (max-width: 1200px) {
        width: 100%;
        font-size: 14px;
      }
      @media (max-width: 992px) {
        line-height: normal;
      }
      @media (max-width: 768px) {
        padding: 0 2rem;
        font-size: 14px;
      }
      @media (max-width: 576px) {
        text-align: center;
      }
    }
  }
`;
