import styled from 'styled-components';
import eth from '../../assets/ethereiun.png';
import benefitCard1 from '../../assets/benefits/Face_AKsuited_1.png';
import benefitCard2 from '../../assets/benefits/Face_PocketSixes_1.png';
import dollarIco from '../../assets/benefits/dollar.png';
import packageIco from '../../assets/benefits/package.png';
import prizeIco from '../../assets/benefits/prize.png';
import mainCard from '../../assets/benefits/mainCard.png';

export default function Benefits() {
  return (
    <StyledBenefits>
      <section className="benefitsContainer">
        <div className="benefitCard">
          <img src={mainCard} alt="" />
        </div>
        <div className="cardWrapper">
          <img className="benefitCard1" src={benefitCard1} alt="" />
          <img className="benefitCard2" src={benefitCard2} alt="" />
          <div className="card">
            <div className="totalSupply">
              <span className="eightMid">8,192</span>
              <span className="font totalMid">Total Supply</span>
            </div>
            <div>
              <span className="thirtyFive">35</span>
              <span className="font uniqueMid">Unique Ranges</span>
            </div>
            <div className="ethWrap">
              <span className="ethImage">
                <img
                  className="specialTwo"
                  src={eth}
                  alt=""
                  width={28}
                  height={45}
                />
                <span className="tagOne"> 0.05</span>
              </span>
              <span className="font mintMid">Mint Price</span>
            </div>
            <div className="ethWrap">
              <span>
                <img
                  className="specialThree"
                  src={eth}
                  alt=""
                  width={28}
                  height={45}
                />
                <span className="tagThree">200+</span>
              </span>
              <span className="font nftMid">in Prizes</span>
            </div>
          </div>
        </div>
      </section>

      <section id="benefits" className="container">
        <div>
          <div className="heading">
            <h2>
              Capped Range <span>Benefits</span>{' '}
            </h2>
            <p>
              Your Capped Range NFT is more than just your entry into our
              tournament. Holding your Capped Range NFT provides you with the
              following short and long-term benefits:
            </p>
          </div>
          <div className="tiles_container">
            <div className="tiles">
              <div className="tile__item box-1">
                <img src={dollarIco} alt="" width={87} height={87} />
                <h3>Freeroll Tournaments</h3>
                <p>
                  Test your poker skills vs other Capped Range NFT holders in
                  our freeroll tournaments! All Capped Range NFT holders will be
                  eligible for entry in all freeroll tournaments that we host
                  with real $ prizes!
                </p>
              </div>
              <div className="tile__item box-2">
                <img src={packageIco} alt="" width={87} height={87} />
                <h3>Live poker tournament entries/packages</h3>
                <p>
                  Do you play live poker tournaments such as the WSOP or WPT?
                  Holding a Capped Range NFT gives you the chance to win
                  tournament entries and/or packages into real life events in
                  tournament series' including, but not limited to, the World
                  Series of Poker and World Poker Tour!
                </p>
              </div>
            </div>
            <div className="tile__item_1 box-3">
              <img src={prizeIco} alt="" width={87} height={87} />
              <h3>Access to collabs, partnerships, and CR poker room</h3>
              <p>
                Did you enjoy the experience we created? As a holder, you will
                have first access to all future products as Capped Range
                continues to build out new experiences and partnerships.
              </p>
            </div>
          </div>
        </div>
      </section>
    </StyledBenefits>
  );
}

const StyledBenefits = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @font-face {
    font-family: 'Northead';
    src: url('/fonts/northead.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.otf') format('truetype');
  }
  margin-bottom: 10rem;

  .benefitsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    z-index: 8;
    @media (max-width: 1200px) {
      width: 100%;
      padding: 0 1rem;
    }
    @media (max-width: 576px) {
      margin-top: -10rem;
    }
    .benefitCard {
      display: none;
      @media (min-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .cardWrapper {
      display: none;

      @media (max-width: 767px) {
        display: block;
        margin-top: 5rem;
        width: 100%;
        .benefitCard1 {
          position: absolute;
          z-index: 1;
          width: 200px;
          height: 250px;
          left: 10%;
          top: -20%;
        }
        .benefitCard2 {
          position: absolute;
          z-index: -1;
          left: 45%;
          top: -20%;
          width: 180px;
          height: 230px;
          left: 45%;
          top: -8%;
        }
        .card {
          background-color: rgba(51, 51, 51, 0.2);

          background-blend-mode: overlay, normal;
          border: 2px solid #383838;
          backdrop-filter: blur(33.193px);
          border-radius: 3px;
          padding: 1rem;

          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(33.193px);
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          flex-direction: column;
          gap: 1rem;
          padding: 3rem 0;
          padding-left: 6.5rem;
          .ethWrap {
            img {
              margin-top: 0.5rem;
            }
          }
          .font {
            font-family: 'Inter' !important;
          }
          .nftMid {
            margin-left: 3rem;
            /* text-align: center; */
          }
          .publicMid {
            margin-left: 1.1rem;
          }
          .mintMid {
            margin-left: 2.5rem;
          }
          .uniqueMid {
            margin-left: 1.3rem;
          }
          .totalMid {
            margin-left: 1.5rem;
          }
          .eightMid {
            margin-left: 1rem;
          }
          .thirtyFive {
            margin-left: 3rem;
          }
          .totalSupply {
            margin-left: 0.5rem;
          }

          span {
            display: flex;
            justify-content: start;
            gap: 2rem;
          }
          span:first-child {
            font-family: 'Northead' !important;
            font-size: 100px;
            line-height: 83px;
            letter-spacing: -0.01em;
            text-transform: uppercase;
            color: #ffc728;
            display: flex;
            align-items: center;
            text-align: center;
            gap: 0.5rem;
          }
        }
      }
    }
  }

  .mintWrapper {
    padding-top: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 95%;

    h2 {
      font-family: 'Northead' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 5rem;
      line-height: 61px;
      letter-spacing: -0.02em;
      color: #ffffff;
      text-align: center;
      span {
        color: #ffc728;
        font-family: 'Northead' !important;
      }
    }

    .mintFlex {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      /* width: 100%; */
      margin-top: 40px;
      @media (max-width: 768px) {
        gap: 0.5rem;
      }
      .item-1,
      .item-2,
      .item-3,
      .item-4 {
        display: flex;
        width: 576px;
        height: 77px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .box {
          width: 661px;
          height: 112px;
          padding: 1rem;
          background-color: rgba(51, 51, 51, 0.2);
          background-blend-mode: overlay, normal;
          border: 2px solid #383838;
          backdrop-filter: blur(33.193px);
          border-radius: 3px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          @media (max-width: 576px) {
            width: 100%;
            height: 100%;
          }
          p {
            width: 661px;
            font-family: 'Northead' !important;
            font-size: 2rem;
            line-height: 38px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            @media (max-width: 576px) {
              width: 100%;
              font-family: 'Inter' !important;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.02em;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .container {
    padding-top: 13rem;
    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
    }
    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      h2 {
        font-family: 'Northead' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 5rem;
        line-height: 61px;
        letter-spacing: -0.02em;
        color: #ffffff;
        text-align: center;
        span {
          color: #ffc728;
          font-family: 'Northead' !important;
        }
        @media (max-width: 768px) {
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-family: 'Inter' !important;
        width: 729px;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        @media (max-width: 768px) {
          width: 100%;
          height: 100%;
        }
      }
    }
    .tiles_container {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      gap: 2rem;
      .tiles {
        display: grid;
        align-self: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        @media (max-width: 1024px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .tile__item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 2rem;
          height: 462px;
          width: 569px;
          background-color: rgba(51, 51, 51, 0.2);
          background-blend-mode: overlay, normal;
          border: 2px solid #383838;
          backdrop-filter: blur(33.193px);
          border-radius: 3px;
          padding: 3rem 2rem;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(33.193px);

          @media (max-width: 768px) {
            width: 100%;
            height: 100%;
          }
          @media (max-width: 576px) {
            justify-content: center;
            align-items: center;
          }
          h3 {
            font-family: 'Northead' !important;
            font-weight: 400;
            height: 86px;
            font-size: 69.8774px;
            line-height: 43px;
            /* or 62% */

            letter-spacing: -0.02em;
            @media (max-width: 1024px) {
              width: 100%;
              height: 100%;
            }
            @media (max-width: 576px) {
              text-align: center;
            }
          }
          p {
            font-family: 'Inter' !important;
            width: 476px;
            font-size: 17px;
            line-height: 27px;
            font-weight: 300;
            height: 132px;
            @media (max-width: 1024px) {
              width: 100%;
              height: 100%;
            }
            @media (max-width: 576px) {
              text-align: center;
            }
          }
          @media (max-width: 1024px) {
            padding: 0.5rem 1rem;
            width: 100%;
          }
        }
      }
      .box-3 {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 379px;
        background-color: rgba(51, 51, 51, 0.2);
        background-blend-mode: overlay, normal;
        border: 2px solid #383838;
        backdrop-filter: blur(33.193px);
        border-radius: 3px;
        padding: 2rem 2rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(33.193px);
        @media (max-width: 768px) {
          width: 100%;
          height: 100%;
          padding: 2rem 1rem;
        }
        @media (max-width: 576px) {
          justify-content: center;
          align-items: center;
        }
        h3 {
          font-family: 'Northead' !important;
          font-weight: 400;
          font-size: 69.8774px;
          line-height: 43px;
          letter-spacing: -0.02em;
          @media (max-width: 576px) {
            width: 100%;
            font-size: 3.5rem;
            text-align: center;
          }
        }
        p {
          font-family: 'Inter' !important;
          width: 867px;
          font-weight: 300;
          font-size: 17px;
          line-height: 27px;
          @media (max-width: 1024px) {
            width: 100%;
          }
          @media (max-width: 576px) {
            text-align: center;
          }
        }
        @media (max-width: 1024px) {
          width: 100%;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
`;
