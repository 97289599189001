import styled from 'styled-components';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ContractContext, GlobalContext, PokerContext } from '../../context';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import dateFormat from 'dateformat';
import { FaFileExport } from 'react-icons/fa';
import { usePapaParse } from 'react-papaparse';
import { flatten } from 'flat';
import { AuthContext } from '../../context/auth';

export default function Admin() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { jsonToCSV } = usePapaParse();

  const { startRound, getRounds } = useContext(PokerContext);
  const { isLoading, globalStateChanged, setIsLoading } =
    useContext(GlobalContext);
  const { getWaitlist } = useContext(AuthContext);
  const {
    upcomingRounds,
    currentRound,
    pastRounds,
    setCurrentRound,
    setUpcomingRounds,
    setPastRounds,
    currentTournament,
    getTournaments,
    setTournaments,
    setCurrentTournament,
  } = useContext(ContractContext);

  let currentPath = pathname.split('/').at(-1);

  const handleStartRound = async () => {
    let _currentRound = currentRound || prevRound;
    if (_currentRound && currentTournament) {
      try {
        setIsLoading(true);
        await startRound(_currentRound.roundId, currentTournament.id);
        setIsLoading(false);
        navigate('/admin/results');
      } catch (e: any) {
        setIsLoading(false);
      }
    } else {
      toast.error('No current round');
    }
  };

  useEffect(() => {
    toast('Loading tournaments...');
    getTournaments()
      .then((tournaments) => {
        if (tournaments.length > 0) {
          // sort upcoming rounds by start time
          tournaments = tournaments.sort((a, b) => a.id - b.id);

          setTournaments(tournaments);

          // set current tournament
          let tournament = tournaments[tournaments.length - 1];

          getRounds(tournament)
            .then((rounds) => {
              setCurrentTournament({
                ...tournament,
                roundCount: Math.max(...rounds.map((r) => r.roundId).concat(0)),
              });

              if (rounds.length > 0) {
                // get upcoming rounds
                let upcomingRounds = rounds.filter(
                  (round) => round.startTime > Date.now()
                );

                // sort upcoming rounds by start time
                upcomingRounds = upcomingRounds.sort(
                  (a, b) => a.startTime - b.startTime
                );

                setUpcomingRounds(upcomingRounds);

                // get current rounds
                let ongoingRounds = rounds.filter(
                  (round) =>
                    Date.now() > round.startTime && Date.now() < round.endTime
                );

                setCurrentRound(
                  ongoingRounds.length > 0 ? ongoingRounds[0] : null
                );

                ///  get past rounds
                let pastRounds = rounds.filter(
                  (round) => Date.now() > round.endTime && round.roundId > 0
                );

                setPastRounds(pastRounds);
              }
            })
            .catch(() => {
              setCurrentRound(null);
              setUpcomingRounds([]);
              setPastRounds([]);
            });
        }
      })
      .catch((_err) => {
        toast('Error fetching tournaments');
      });
    // eslint-disable-next-line
  }, [globalStateChanged]);

  const handleExportToCSV = async () => {
    // prompt confirmation
    if (window.confirm('Are you sure you want to export the waitlist?')) {
      let users = await getWaitlist();

      let flattened = users.map(
        (user: { email: any; createdAt: string | number | Date | undefined }) =>
          flatten({
            email: user.email,
            createdAt: dateFormat(
              user.createdAt || new Date(),
              'dd/mm/yyyy HH:MM'
            ),
          })
      );
      const csv = jsonToCSV(flattened);

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Waitlist.csv';
      a.click();
    }
  };

  let prevRound = pastRounds?.sort((a, b) => b.roundId - a.roundId)[0];

  return (
    <StyledAdmin className="container">
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <h1>Admin Panel</h1>
      <div className="actions">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
            padding: '1rem',
          }}
        >
          <p>
            Ongoing Round:{' '}
            {currentRound
              ? `Round ${currentRound.roundId} EndTime: ${dateFormat(
                  new Date(currentRound.endTime),
                  'dd-mm-yyyy h:MM TT'
                )}.`
              : 'None'}
          </p>
          <p>
            Upcoming Rounds:{' '}
            {upcomingRounds.length > 0
              ? upcomingRounds
                  .map(
                    (round) =>
                      `Round ${round.roundId} on ${dateFormat(
                        new Date(round.startTime),
                        'dd-mm-yyyy h:MM TT'
                      )}`
                  )
                  .join(', ')
              : 'None'}
          </p>
        </div>
        <div className="actions__buttons">
          <Link
            to="create-tournament"
            className={`button ${
              currentPath === 'create-tournament' ? 'active' : undefined
            }`}
          >
            Create Tournament
          </Link>

          <Link
            to="create"
            className={`button ${
              currentPath === 'create' ? 'active' : undefined
            }`}
          >
            Create Round
          </Link>

          <Link
            to="rounds"
            className={`button ${
              currentPath === 'rounds' ? 'active' : undefined
            }`}
          >
            Rounds
          </Link>
          <button
            className="button"
            onClick={handleStartRound}
            style={{
              opacity: isLoading ? 0.5 : 1,
              pointerEvents: isLoading ? 'none' : 'auto',
            }}
            disabled={
              currentRound?.roundId || prevRound?.roundId
                ? false
                : true || isLoading
            }
          >
            Start Round {currentRound?.roundId || prevRound?.roundId}
          </button>

          <Link
            to="results"
            className={`button ${
              currentPath === 'results' ? 'active' : undefined
            }`}
          >
            Results
          </Link>
          <Link
            to="drop"
            className={`button ${
              currentPath === 'drop' ? 'active' : undefined
            }`}
          >
            Airdrop
          </Link>
          <Link
            to="mint"
            className={`button ${
              currentPath === 'mint' ? 'active' : undefined
            }`}
          >
            Mint
          </Link>
          <Link to="#" onClick={handleExportToCSV} className="button button2">
            WaitList
            <FaFileExport
              style={{
                marginLeft: '0.5rem',
                fontSize: '1.1rem',
              }}
              title="Export players in waitlist to CSV"
            />
          </Link>
        </div>
      </div>
      <div className="outlet">
        <Outlet />
      </div>
    </StyledAdmin>
  );
}

const StyledAdmin = styled.div`
  padding: 2rem 1rem !important;
  button {
    font-size: 1rem;
    cursor: pointer;
  }
  button:disabled {
    cursor: not-allowed;
    background-color: #ccc;
  }
  .button2:hover {
    border: 3px solid ${({ theme }) => theme.background};
    box-shadow: 0px 0px 6px ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.background};
  }
  .actions {
    position: relative;
    margin-top: 1rem;
    overflow-x: auto;
    width: 100%;
    &__buttons {
      display: flex;
      align-items: center;
      gap: 1rem;
      button,
      a {
        white-space: nowrap;
      }
      a {
        background: transparent;
        color: ${({ theme }) => theme.primary};
        border: 1px solid ${({ theme }) => theme.primary};
        &:hover,
        &:focus {
          box-shadow: 0px 0px 6px ${({ theme }) => theme.primary};
        }
      }
      .active {
        border: 3px solid ${({ theme }) => theme.background};
        box-shadow: 0px 0px 6px ${({ theme }) => theme.primary};
        background: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.background};
      }
    }
    h4 {
      position: sticky;
      left: 0;
      font-size: 1.2rem;
      font-weight: 300;
      margin-bottom: 1rem;
    }
  }
  .outlet {
    margin-top: 2rem;
  }
`;
