import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';

interface SelectProps {
  options: Array<{
    value: string;
    label: string;
  }>;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function Select({ options, ...props }: SelectProps) {
  return (
    <SelectWrapper>
      <FaChevronDown />
      <StyledSelect {...props}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    font-size: 0.8rem;
    transform: translateY(-50%);
    transform-origin: center;
    transition: all 0.2s ease-in-out;
  }
  &:focus-within {
    svg {
      transform: translateY(-50%) rotate(180deg);
    }
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  display: inline-block;
  background: #25262a;
  border: 1px solid #4c4f58;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #fff;
  &::placeholder {
    /* color: ${({ theme }) => theme.secondary}80; */
    color: auto;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.2s ease-in-out;
  /* &:focus {
    background-color: ${({ theme }) => theme.background};
    border: 2px solid ${({ theme }) => theme.primary};
    outline: none;
  } */
`;
