import { createContext } from 'react';
import { IResult, IRound, ITournament, Round } from '../../types';

type IPokerContext = {
  currentTable: IRound | null;
  leaveTable: () => void;
  replayMatch: (matchId: string) => void;
  startRound: (roundId: number, tournamentId: number) => Promise<void>;
  getRounds: (tournament: ITournament) => Promise<Round[]>;
  getResults: (tournamentId?: number, roundId?: number) => Promise<IResult[]>;
  setCurrentTable: (round: IRound | null) => void;
  createRound: (params: {
    roundId: number;
    tournamentId: number;
    tournamentAddress: string;
    startTime: number;
    endTime: number;
    maxPlayers: number;
  }) => Promise<void>;
  getPlayers: (_tournamentId: number, _roundId: number) => Promise<any[]>;
  cancelRound: (_roundId: number, _tournamentId: number) => Promise<void>;
  updateRound: (params: {
    roundId: number;
    tournamentId: number;
    startTime: number | undefined;
    endTime: number | undefined;
    maxPlayers: number | undefined;
  }) => Promise<void>;
};

const defaultValue: IPokerContext = {
  currentTable: null,
  leaveTable: () => {},
  replayMatch: (matchId: string) => {},
  startRound: async (roundId: number, tournamentId: number) => {},
  getRounds: (tournament: ITournament) => Promise.resolve([]),
  getResults: (tournamentId?: number) => Promise.resolve([]),
  setCurrentTable: (round: IRound | null) => {},
  createRound: async (params: {
    roundId: number;
    tournamentId: number;
    tournamentAddress: string;
    startTime: number;
    endTime: number;
    maxPlayers: number;
  }) => {},
  getPlayers: (_tournamentId: number, _roundId: number) => Promise.resolve([]),
  cancelRound: async (_roundId: number, _tournamentId: number) => {},
  updateRound: async (params: {
    roundId: number;
    tournamentId: number;
    startTime: number | undefined;
    endTime: number | undefined;
    maxPlayers: number | undefined;
  }) => {},
};
export const PokerContext = createContext<IPokerContext>(defaultValue);
